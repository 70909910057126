import { __decorate, __metadata } from "tslib";
import PledgeUpdateAuditItem from '@/shared/components/pledge-form/audit-log/pledge-update-audit-item.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import Card from '@/shared/ui/card/card.vue';
import { formatDate } from '@/shared/util';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import DateRangePicker from '@/shared/ui/forms/date-picker/date-range-picker.vue';
import UserLookup from '@/shared/components/user-lookup';
let PledgeAuditLog = class PledgeAuditLog extends Vue {
    log;
    filters = {
        userId: null,
        type: null,
        daterange: {
            end: null,
            start: null
        }
    };
    formatDate = formatDate;
    get filteredLog() {
        return this.log.filter(item => {
            if (this.filters.type && item.type !== this.filters.type)
                return false;
            if (this.filters.userId && item.user && this.filters.userId !== item.user.id)
                return false;
            if (this.filters.daterange.start && item.date.valueOf() < this.filters.daterange.start.valueOf())
                return false;
            if (this.filters.daterange.end && item.date.valueOf() > this.filters.daterange.end.valueOf())
                return false;
            return true;
        });
    }
    clearFilters() {
        this.filters = {
            userId: null,
            type: null,
            daterange: {
                end: null,
                start: null
            }
        };
    }
    onUserChanged(user) {
        this.filters.userId = user ? user.id : null;
    }
    userName(item) {
        return item.user ? `${item.user.firstName} ${item.user.lastName}` : '<System>';
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeAuditLog.prototype, "log", void 0);
PledgeAuditLog = __decorate([
    Component({
        components: {
            PledgeUpdateAuditItem,
            Box,
            UserLookup,
            UISelect,
            Card,
            layoutText,
            DateRangePicker
        }
    })
], PledgeAuditLog);
export default PledgeAuditLog;
