<template>
	<th>
		<slot></slot>
	</th>
</template>

<script>
export default {
	name: 'TableHeader',
	props: ['label']
}
</script>

<style scoped>
th {
	color: #333;
	margin-bottom: 20px;
	font-size: var(--main-font-size);
	font-weight: 400;
	padding: 15px 15px;
	text-align: left;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebedee;
}
.condensed th {
	padding: 8px 15px 8px 5px;
}
.clear-header th {
	background: none;
	font-weight: 600;
	color: #333;
	border-bottom-width: 2px;
}
</style>
