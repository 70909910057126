import { SortOrder } from '@/shared/schema/globalTypes';
import LOAD_BATCHES from './batches-lookup.graphql';
import query from '@/shared/graphql/query';
import { compose, acceptProps, withData } from 'vue-compose';
import BatchLookup from './batch-lookup.vue';
import { formatDate } from '@/shared/util';
const getBatches = query(LOAD_BATCHES, {
    options(props) {
        const filters = {};
        if (props.showClosed === false)
            filters.showClosed = props.showClosed;
        if (props.types) {
            filters.types = props.types;
        }
        if (props.queryStr) {
            filters.search = props.queryStr;
        }
        if (props.search) {
            filters.search = props.search;
        }
        return {
            fetchPolicy: 'network-only',
            variables: {
                filters,
                sort: [{ name: 'audit.createdAt', order: SortOrder.DESC }]
            }
        };
    },
    props(props) {
        const batches = !props.loading && props.data && props.data.batches
            ? props.data.batches.records.map(x => ({
                id: x.id,
                label: x.name,
                ...(x.date ? { date: formatDate(x.date) } : {})
            }))
            : props.value && typeof props.value == 'object'
                ? [props.value]
                : [];
        if (props.value && typeof props.value == 'object') {
            const value = props.value;
            if (!batches.find(x => x.id == value.id)) {
                batches.push(value);
            }
        }
        return {
            loading: props.loading,
            batches
        };
    }
});
export default compose(withData({
    search: {
        initialValue: null
    }
}), acceptProps(['types', 'search', 'showClosed', 'queryStr']), getBatches)(BatchLookup);
