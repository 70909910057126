import Icon from '@/shared/components/icon/icon--solid.vue';
import Box from '@/shared/layout/box/layout-box.vue';
export default {
    components: {
        Icon,
        Box
    },
    props: {
        title: String
    },
    data() {
        return {
            openSection: false
        };
    }
};
