import { MailMergeTemplateType } from '@/shared/schema/globalTypes';
import { useMutation, useQuery } from '@vue/apollo-composable';
import GET_RECEIPT_TEMPLATE from './get-receipt-template.graphql';
import GET_TEMPLATE_NAMES from '@/shared/graphql/get-template-names.graphql';
import SAVE_RECEIPT_TEMPLATE from './save-receipt-template.graphql';
import { computed } from 'vue';
export function useGetTemplateNames() {
    const { result, loading, onResult } = useQuery(GET_TEMPLATE_NAMES);
    const names = computed(() => result.value?.getMailMergeTemplateNames ?? []);
    return {
        names,
        loading,
        onLoaded: onResult
    };
}
export function useGetTemplate(props) {
    const { result, loading, onResult } = useQuery(GET_RECEIPT_TEMPLATE, {
        campaignId: props.campaignId,
        pledgeId: props.pledgeId,
        department: props.department
    });
    const template = computed(() => result.value?.getMailMergeTemplate ?? null);
    return {
        template,
        loading,
        onLoaded: onResult
    };
}
export function useSaveTemplate(props) {
    const { mutate } = useMutation(SAVE_RECEIPT_TEMPLATE, {
        refetchQueries: ['GetReceiptTemplate']
    });
    function saveTemplate(data) {
        return mutate({
            template: {
                type: MailMergeTemplateType.Receipt,
                templateName: data.templateName,
                campaignId: props.campaignId,
                pledgeId: props.pledgeId,
                department: props.department,
                body: data.body,
                firstLine: data.firstLine,
                footerLine: data.footerLine,
                fundraiserId: data.fundraiserId,
                secondFundraiserId: data.secondFundraiserId,
                thirdFundraiserId: data.thirdFundraiserId,
                logoUrl: data.logoUrl,
                salutationType: data.salutationType,
                emailText: data.emailText,
                removeSignature: data.removeSignature
            }
        });
    }
    return { saveTemplate };
}
