import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import Icon from '@/shared/components/icon/icon--solid.vue';
let UserSelector = class UserSelector extends Vue {
    users;
    emulateUser;
    emulate(id) {
        this.emulateUser(id);
        this.$emit('close');
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], UserSelector.prototype, "users", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], UserSelector.prototype, "emulateUser", void 0);
UserSelector = __decorate([
    Component({
        components: {
            Box,
            Icon
        }
    })
], UserSelector);
export default UserSelector;
