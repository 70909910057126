import { __decorate, __metadata } from "tslib";
import Layout from './layout.vue';
import { compose } from 'vue-compose';
import Vue from 'vue';
import { withCurrentUser, userProvider } from '@/shared/authentication';
import RMRequestResponder from './management-request';
import AccountingPledgeResponder from './pledge-accounting-request';
import { Prop, Component } from 'vue-property-decorator';
function requireLogin(component) {
    return Vue.extend({
        inject: ['$authStore'],
        computed: {
            state() {
                return this.$authStore.state;
            }
        },
        render(h) {
            if (this.$authStore.state === 'authenticating') {
                return h('div', { style: { textAlign: 'center', paddingTop: '20px' } }, 'Authenticating...');
            }
            if (this.$authStore.state === 'authenticated') {
                return h(component);
            }
            if (this.$authStore.state === 'managerRequest') {
                return h(RMRequestResponder);
            }
            if (this.$authStore.state === 'accountingPledgeRequest') {
                return h(AccountingPledgeResponder);
            }
            return h('div', { style: { textAlign: 'center', paddingTop: '20px' } }, 'logging in...');
        }
    });
}
const withUserLayout = withCurrentUser(Layout);
let Index = class Index extends Vue {
    loading;
    render(h) {
        return h(withUserLayout, {
            props: {
                loading: this.loading
            }
        });
    }
};
__decorate([
    Prop(),
    __metadata("design:type", Boolean)
], Index.prototype, "loading", void 0);
Index = __decorate([
    Component({})
], Index);
export default compose(requireLogin, userProvider)(Index);
