<template>
	<Box v-bind="$attrs" display="inline" :style="{ color: color }" v-on="$listeners">
		<FontAwesomeIcon :icon="['far', name]" :fixed-width="fixedWidth" :size="size" :spin="spin"></FontAwesomeIcon>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box-simple.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faAngleDown,
	faClone,
	faAngleUp,
	faBarcode,
	faAngleLeft,
	faBars,
	faExclamationCircle,
	faCalendar,
	faCaretDown,
	faCaretUp,
	faCaretLeft,
	faCaretRight,
	faChartPie,
	faEye,
	faInfoSquare,
	faSpinnerThird,
	faCheck,
	faCircle,
	faClock,
	faCog,
	faFilter,
	faQuestionCircle,
	faDownload,
	faEdit,
	faStepBackward,
	faStepForward,
	faEllipsisH,
	faExternalLink,
	faFilePdf,
	faMars,
	faPencil,
	faPhone,
	faMinus,
	faPlus,
	faPlusCircle,
	faTimesCircle,
	faSearch,
	faHome,
	faEnvelope,
	faShield,
	faSync,
	faBolt,
	faSpinner,
	faStar,
	faTh,
	faTimes,
	faTrash,
	faList,
	faVenus,
	faCommentAlt,
	faImage,
	faChevronLeft,
	faTable,
	faCopy,
	faSearchPlus,
	faMapMarkerAlt,
	faUser,
	faEllipsisV,
	faPlay,
	faBuilding,
	faDollarSign,
	faUserChart,
	faAlarmExclamation,
	faChartBar,
	faCheckCircle,
	faListAlt,
	faArrowsV,
	faArrowsH,
	faLongArrowDown,
	faLongArrowUp,
	faSort
} from '@fortawesome/pro-regular-svg-icons'

import { faFacebookSquare, faTwitterSquare, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons'

library.add(
	faUser,
	faPlay,
	faInfoSquare,
	faBarcode,
	faCaretUp,
	faStepBackward,
	faStepForward,
	faEye,
	faCog,
	faFilter,
	faList,
	faSpinnerThird,
	faTable,
	faEdit,
	faSync,
	faEllipsisH,
	faEllipsisV,
	faChartPie,
	faExclamationCircle,
	faQuestionCircle,
	faBolt,
	faFilePdf,
	faDownload,
	faSearch,
	faCircle,
	faPencil,
	faCalendar,
	faExternalLink,
	faTrash,
	faMinus,
	faPlus,
	faCopy,
	faBars,
	faShield,
	faTimesCircle,
	faStar,
	faTimes,
	faMars,
	faVenus,
	faClock,
	faClone,
	faPlusCircle,
	faAngleDown,
	faAngleUp,
	faAngleLeft,
	faCaretDown,
	faCheck,
	faSpinner,
	faPhone,
	faCommentAlt,
	faImage,
	faHome,
	faEnvelope,
	faChevronLeft,
	faSearchPlus,
	faMapMarkerAlt,
	faCaretLeft,
	faCaretRight,
	faFacebookSquare,
	faTwitterSquare,
	faLinkedin,
	faBuilding,
	faDollarSign,
	faUserChart,
	faAlarmExclamation,
	faTh,
	faListAlt,
	faCheckCircle,
	faChartBar,
	faArrowsV,
	faArrowsH,
	faLongArrowDown,
	faLongArrowUp,
	faSort
)

export default {
	name: 'Icon',
	props: {
		name: {
			type: String
		},
		size: {
			type: String
		},
		fixedWidth: {
			type: Boolean,
			default: false
		},
		color: {
			type: String
		},
		spin: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Box,
		FontAwesomeIcon
	}
}
</script>

<style lang="scss" scoped></style>
