import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { defineComponent, watch, reactive } from 'vue';
export default defineComponent({
    components: {
        Box,
        layoutText
    },
    props: {
        value: { type: Boolean, required: true, default: false },
        width: { type: String, required: false, default: '40' },
        labelText: { type: String, required: false, default: null }
    },
    setup(props, { listeners, emit }) {
        const state = reactive({
            innerValue: props.value || false
        });
        function toggle($event) {
            emit('input', $event.target.checked);
            emit('blur');
            state.innerValue = !state.innerValue;
        }
        watch(() => props.value, () => {
            state.innerValue = props.value;
        });
        return {
            state,
            toggle
        };
    }
});
