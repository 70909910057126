import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AppLayout from './pages';
import { HistoryRouter } from 'vue-component-router';
let App = class App extends Vue {
};
App = __decorate([
    Component({
        components: {
            AppLayout,
            HistoryRouter
        }
    })
], App);
export default App;
