import { __decorate, __metadata } from "tslib";
import get from 'lodash/get';
import merge from 'lodash/merge';
import { Component, Prop, Vue } from 'vue-property-decorator';
function injectProps(component, props) {
    if (!component.componentOptions) {
        return component;
    }
    const options = component.componentOptions;
    const propsData = (options.propsData = options.propsData || {});
    Object.assign(propsData, props);
    return component;
}
let uid = 1;
let RadioGroup = class RadioGroup extends Vue {
    value;
    uid = uid++;
    render(h) {
        const vNodes = this.$slots.default || [];
        const _injectProps = (vNode) => {
            const nextVNode = vNode.tag && vNode.tag.match(/RadioGroupItem/)
                ? injectProps(vNode, {
                    set: () => {
                        const value = get(vNode, 'componentOptions.propsData.value');
                        this.$emit('input', value);
                        this.$emit('blur', value);
                    },
                    checked: this.value === get(vNode, 'componentOptions.propsData.value'),
                    name: 'input_' + this.uid
                })
                : vNode;
            if (nextVNode.children) {
                nextVNode.children = nextVNode.children.map(_injectProps);
            }
            return nextVNode;
        };
        const injectedVNodes = vNodes.map(_injectProps);
        const focusout = () => setTimeout(() => !this.$el.contains(document.activeElement) && this.$emit('blur'), 200);
        if (injectedVNodes.length === 1) {
            const rootNode = injectedVNodes[0];
            merge(rootNode, {
                data: {
                    on: {
                        focusout
                    },
                    nativeOn: {
                        focusout
                    }
                }
            });
            return rootNode;
        }
        return h('div', {
            on: {
                focusout
            }
        }, injectedVNodes);
    }
};
__decorate([
    Prop({ type: [String, Number, Boolean] }),
    __metadata("design:type", Object)
], RadioGroup.prototype, "value", void 0);
RadioGroup = __decorate([
    Component({
        name: 'RadioGroup'
    })
], RadioGroup);
export { RadioGroup };
export const RadioButton = Vue.extend({
    functional: true,
    props: {
        checked: {
            required: true
        },
        name: {
            required: true
        },
        set: {
            required: true
        }
    },
    render: (h, { props: { checked, name, set }, data: { attrs } }) => h('input', {
        domProps: {
            checked,
            name,
            type: 'radio',
            ...attrs
        },
        on: {
            click: set
        }
    })
});
let RadioGroupItem = class RadioGroupItem extends Vue {
    set;
    checked;
    value;
    label;
    name;
    render(h) {
        const scopedSlot = this.$scopedSlots.default;
        const $input = h('input', {
            attrs: { type: 'radio', name: this.name },
            domProps: { checked: this.checked },
            on: {
                click: this.set
            }
        });
        if (!scopedSlot) {
            return h('label', [$input, this.label]);
        }
        const props = {
            checked: this.checked,
            name: this.name,
            set: this.set
        };
        const listeners = {
            click: this.set
        };
        const $vnode = scopedSlot({ props, listeners, label: this.label });
        if (Array.isArray($vnode) && $vnode.length === 1) {
            return $vnode;
        }
        else {
            return h('div', $vnode);
        }
    }
};
__decorate([
    Prop(),
    __metadata("design:type", Function)
], RadioGroupItem.prototype, "set", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Boolean)
], RadioGroupItem.prototype, "checked", void 0);
__decorate([
    Prop({ type: [String, Boolean, Number] }),
    __metadata("design:type", Object)
], RadioGroupItem.prototype, "value", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], RadioGroupItem.prototype, "label", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], RadioGroupItem.prototype, "name", void 0);
RadioGroupItem = __decorate([
    Component({ name: 'RadioGroupItem' })
], RadioGroupItem);
export { RadioGroupItem };
