import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
let Loading = class Loading extends Vue {
    inline;
    overlay;
};
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], Loading.prototype, "inline", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], Loading.prototype, "overlay", void 0);
Loading = __decorate([
    Component({})
], Loading);
export default Loading;
