<template>
	<Box v-bind="$attrs" class="nav-item">
		<slot class="b"></slot>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box.vue'

export default {
	name: 'NavItem',
	props: {
		paddingX: {
			type: Number,
			default: 5
		},
		paddingY: {
			type: Number,
			default: 2
		}
	},
	components: {
		Box
	},
	computed: {}
}
</script>

<style lang="scss" scoped>
.nav-item {
	border-bottom: 2px solid transparent;
	font-weight: 400;
	margin-bottom: -1px;
	font-size: 14px;
	cursor: pointer;
	color: var(--main-text-color);
	user-select: none;

	&.active {
		border-bottom-color: var(--main-blue);
		color: var(--main-blue);
	}

	> /deep/ * {
		padding: 8px 20px;
		display: block;
	}
}
</style>
