<template>
	<Box v-bind="$attrs" display="inline" :style="{ color: color }" v-on="$listeners">
		<FontAwesomeIcon :icon="['fas', name]" :fixed-width="fixedWidth" :size="size" :spin="spin"></FontAwesomeIcon>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box-simple.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faStar,
	faBars,
	faCircle,
	faCaretDown,
	faCaretUp,
	faAngleDown,
	faAngleUp,
	faArrowToRight,
	faArrowToLeft,
	faArrowRight,
	faInfoSquare,
	faDownload,
	faArrowLeft,
	faTimes,
	faCheckCircle,
	faTh
} from '@fortawesome/pro-solid-svg-icons'

library.add(
	faTimes,
	faStar,
	faBars,
	faTh,
	faInfoSquare,
	faCircle,
	faCheckCircle,
	faCaretDown,
	faCaretUp,
	faAngleDown,
	faAngleUp,
	faArrowToRight,
	faArrowToLeft,
	faArrowRight,
	faArrowLeft,
	faDownload
)

export default {
	name: 'Icon',
	props: {
		name: {
			type: String
		},
		size: {
			type: String
		},
		fixedWidth: {
			type: Boolean,
			default: false
		},
		color: {
			type: String
		},
		spin: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Box,
		FontAwesomeIcon
	}
}
</script>

<style lang="scss" scoped></style>
