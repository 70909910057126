import Box from '@/shared/layout/box/layout-box.vue';
import { defineComponent, reactive, onMounted } from 'vue';
import Loading from '@/shared/components/loading/Loading.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import Card from '@/shared/ui/card/card.vue';
import { CurrencyType } from '@/shared/schema/globalTypes';
export default defineComponent({
    components: {
        Box,
        Loading,
        layoutText,
        Card
    },
    props: {
        testMode: {
            type: Boolean,
            default: true
        },
        gateway: {
            type: String,
            default: 'SensePassCardknox'
        },
        amount: Number,
        currency: {
            type: String,
            default: CurrencyType.USD
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: true
        });
        onMounted(() => {
            const scriptURL = process.env.NODE_ENV === 'production'
                ? 'https://cc-widget.ouapis.org/credit-card.js'
                : 'https://cc-widget.oudev.org/credit-card.js';
            let scripts = Array.from(document.getElementsByTagName('script'));
            const loaded = scripts.find((s) => s.src == scriptURL);
            if (loaded) {
                document.head.removeChild(loaded);
            }
            let ccWidget = document.createElement('script');
            ccWidget.setAttribute('src', scriptURL);
            document.head.appendChild(ccWidget);
            ccWidget.onload = () => {
                state.loading = false;
            };
            window.ccOnStatus = async (state) => {
                if (state.id == 'valid') {
                    try {
                        //@ts-ignore
                        const card = await window.ccAPI.getToken(props.amount);
                        emit('input', {
                            type: 'success',
                            token: card.token,
                            expMonth: card.expMonth,
                            expYear: card.expYear,
                            card: card.card,
                            cardType: card.cardType
                        });
                    }
                    catch (error) {
                        emit('input', {
                            type: 'error',
                            error: error instanceof Error ? error.toString() : ''
                        });
                    }
                }
            };
        });
        return {
            state
        };
    }
});
