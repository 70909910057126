import { compose, withData } from 'vue-compose';
import { flatten } from 'lodash';
import ReceiptSummary from './receipt-summary.vue';
import GET_RECEIPT_SUMMARY from './get-receipt-summary.graphql';
import query from '@/shared/graphql/query';
import { dateScalarToDate } from '@velocity/shared/dist/utils';
const pledgesToProps = (pledges) => {
    const nestedPayments = pledges.map(pledge => pledge.applications.map(application => ({
        id: application.payment.id,
        pledgeId: pledge.id,
        donorId: pledge.donor.id,
        appliedAmount: application.amount,
        type: application.payment.type,
        date: dateScalarToDate(application.payment.date),
        department: pledge.department,
        campaign: pledge.campaign ? pledge.campaign.name : '',
        subCampaign: pledge.subCampaign ? pledge.subCampaign.name : '',
        notes: pledge.notes ? pledge.notes : '',
        fundraisers: pledge.fundraisers.map(x => `${x.fundraiser.firstName} ${x.fundraiser.lastName}`).join(', '),
        batch: application.payment.batch
    })));
    return flatten(nestedPayments);
};
const getReceiptSummaryEnhancer = query(GET_RECEIPT_SUMMARY, {
    options(props) {
        return {
            variables: {
                filters: props.filters
            }
        };
    },
    props(props) {
        if (props.loading || !props.data || !props.data.receiptSummary) {
            return {
                loading: true,
                total: 0,
                payments: []
            };
        }
        const payments = pledgesToProps(props.data.receiptSummary.records);
        return {
            loading: false,
            total: payments.length,
            payments
        };
    }
});
export default compose(withData({
    filters: { initialValue: {} }
}), getReceiptSummaryEnhancer)(ReceiptSummary);
