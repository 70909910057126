<template>
	<table>
		<slot></slot>
	</table>
</template>

<script>
export default {
	name: 'UITable',
	props: ['label']
}
</script>

<style scoped>
table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}
.bordered {
	border: 1px solid #efefef;
}
.small {
	font-size: 0.8em;
}
</style>
