<template functional>
	<span
		:class="[...$options.classes(props), data.staticClass, data.class]"
		:style="[...$options.styles(props), data.staticStyle, data.style]"
		v-on="listeners"
		v-bind="data.attrs"
	>
		<slot></slot>
	</span>
</template>
<script>
import boxWhitespace from '../css/boxWhitespace.module.css'
import typography from '../css/typography.module.css'
import textStyle from '../css/text.module.css'
import Box from '@/shared/layout/box/layout-box.vue'
import layout from '../css/layout.module.css'

export default {
	components: {
		Box
	},
	props: {
		align: {
			type: String,
			default: 'left'
		},
		fontSize: {
			type: [Number, String],
			default: 2
		},
		fontWeight: {
			type: String,
			default: 'normal'
		},
		color: {
			type: String
		},
		fontUppercase: {
			type: Boolean,
			default: false
		},
		fontCapitalize: {
			type: Boolean,
			default: false
		},
		tag: {
			type: String,
			default: 'span'
		},
		marginLeft: {
			type: [String, Number]
		},
		marginRight: {
			type: [String, Number]
		}
	},
	name: 'LayoutText',
	classes(props) {
		const classes = []
		classes.push(layout['block'])
		if (props.align) {
			let capAlign = props.align.charAt(0).toUpperCase() + props.align.slice(1)
			classes.push(typography[`align${capAlign}`])
		}
		if (props.fontSize) {
			classes.push(textStyle[`fontSize${props.fontSize}`])
		}
		if (props.fontWeight) {
			let capWeight = props.fontWeight.charAt(0).toUpperCase() + props.fontWeight.slice(1)
			classes.push(typography[`fontWeight${capWeight}`])
		}
		if (props.fontUppercase) {
			classes.push(typography[`fontUppercase`])
		}
		if (props.fontCapitalize) {
			classes.push(typography[`fontCapitalize`])
		}
		return classes
	},
	styles(props) {
		const styles = []

		if (props.color) {
			styles.push({
				color: props.color
			})
		}

		if (props.marginLeft) {
			styles.push({
				marginLeft: props.marginLeft
			})
		}
		if (props.marginRight) {
			styles.push({
				marginRight: props.marginRight
			})
		}

		return styles
	}
}
</script>
