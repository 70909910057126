import { DepartmentGroups, departmentGroupsMap } from '@velocity/shared/src/departments';
import { commissionSubCommitteesMap } from '@velocity/shared/src/committees';
import { isRef, watch } from 'vue';
import accounting from 'accounting';
import { formatToTimeZone } from 'date-fns-timezone/dist/formatToTimeZone';
import { PhoneNumberFormat as PNF, PhoneNumberUtil } from 'google-libphonenumber';
import trim from 'lodash/trim';
import * as mathjs from 'mathjs';
import { CurrencyType } from './schema/globalTypes';
export function isNumber(value) {
    const tryAsNumber = Number(value);
    if (Number.isNaN(tryAsNumber))
        return false;
    return true;
}
export function formatMoney(num, currency = CurrencyType.USD) {
    if (num === null || num === undefined)
        return 'NaN';
    switch (currency) {
        case CurrencyType.CAD:
            return accounting.formatMoney(num, { symbol: 'CAD', format: '%v %s' });
        case CurrencyType.NIS:
            return accounting.formatMoney(num, '₪');
    }
    return accounting.formatMoney(num);
}
export function formatDate(date, timezone) {
    if (typeof date == 'object' && 'day' in date) {
        date = new Date(date.year, date.month - 1, date.day);
    }
    if (typeof date == 'string') {
        date = new Date(date);
    }
    return formatToTimeZone(date, 'MMM D, YYYY', { timeZone: timezone || 'UTC' });
}
export function formatTime(date, timezone) {
    if (typeof date == 'object' && 'day' in date) {
        date = new Date(date.year, date.month - 1, date.day);
    }
    return formatToTimeZone(date, 'MMM D, YYYY hh:mm:ss', { timeZone: timezone || 'UTC' });
}
export function formatPhone(number, ext) {
    if (!number)
        return '';
    const util = PhoneNumberUtil.getInstance();
    let formattedPhone;
    try {
        formattedPhone = util.format(util.parse(number, number[0] !== '+' ? 'US' : undefined), PNF.NATIONAL);
    }
    catch (e) {
        formattedPhone = number;
    }
    if (ext)
        formattedPhone = `${formattedPhone} x${ext}`;
    return formattedPhone;
}
export function formatPhoneInt(number, ext) {
    if (!number)
        return '';
    const util = PhoneNumberUtil.getInstance();
    let formattedPhone;
    try {
        formattedPhone = util.format(util.parse(number, number[0] !== '+' ? 'US' : undefined), PNF.E164);
    }
    catch (e) {
        formattedPhone = number;
    }
    if (ext)
        formattedPhone = `${formattedPhone} x${ext}`;
    return formattedPhone;
}
function isFunctional(arg) {
    return arg.functional !== undefined;
}
export const formatAddress = (address) => trim(`${trim(`${address.street || ''} ${address.street2 || ''}`)}
${address.city || ''} ${address.state ? `, ${address.state || ''}` : ''} ${address.zip || ''} ${address.careOf ? `, ${address.careOf || ''}` : ''}`);
export function getOptions(component) {
    if (typeof component === 'function') {
        return component.options;
    }
    else {
        if (isFunctional(component)) {
            throw Error('Functional Componens Not Supported');
        }
        return component;
    }
}
export function normalizeProps(props = {}) {
    if (Array.isArray(props)) {
        return props.reduce((normalizedProps, prop) => ({ ...normalizedProps, [prop]: {} }), {});
    }
    return { ...props };
}
export function rootFieldFromDocumentNode(query) {
    const definitionNode = query.definitions.filter(d => d.kind === 'OperationDefinition')[0];
    const selectionNode = definitionNode.selectionSet.selections[0];
    return selectionNode.alias ? selectionNode.alias.value : selectionNode.name.value;
}
export function getField(object, field) {
    const path = field.split('.');
    let res = object;
    while (path.length) {
        let key = path.shift();
        res = res[key];
    }
    return res;
}
export function dateToMonthDayYear(date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    return { year, month, day };
}
export function monthDayYearToDate(mdy) {
    return new Date(Date.UTC(mdy.year, mdy.month - 1, mdy.day));
}
/** used internally to convert nested possible numbers to array of numbers */
const getNumbers = (...possibleNumbers) => {
    const allNumbers = [];
    possibleNumbers.forEach((currentNumber) => {
        if (!currentNumber)
            return;
        if (typeof currentNumber === 'number') {
            allNumbers.push(currentNumber);
        }
        else {
            currentNumber.forEach(innerNumber => {
                if (!innerNumber)
                    return;
                allNumbers.push(innerNumber);
            });
        }
    });
    return allNumbers;
};
/** Values can be:
 * - regular args:  addWithRounding(1.1, 1.3, 2)
 * - arrays: addWithRounding([1.1, 1.3, 2])
 * - null values (they will be omited): addWithRounding(null, 3.4, 1, null)
 *
 * Result will be 4.4 for all of those examples
 */
export function addWithRounding(...possibleNumbers) {
    const allNumbers = getNumbers(...possibleNumbers);
    const bigNumbers = allNumbers.map(n => mathjs.bignumber(n));
    const unroundedSum = bigNumbers.reduce((total, current) => {
        return mathjs.add(total, current); // mathjs.add will return big number, if both arguments are big numbers. Provided mathjs.add overload is not specific enough
    }, mathjs.bignumber(0));
    return mathjs.number(unroundedSum); // number should always be returned, when big number is provided.Provided standard overload is not specific enough
}
export function isDepartmentGroup(deptOrGroup) {
    return Object.values(DepartmentGroups).includes(deptOrGroup);
}
export function resolveDepartments(departments) {
    if (!Array.isArray(departments))
        departments = [departments];
    return departments.flatMap(x => isDepartmentGroup(x) ? resolveDepartments(departmentGroupsMap[x]) : x);
}
export function when(source) {
    const done = isRef(source) ? source.value : source();
    if (done)
        return;
    return new Promise(resolve => {
        const stop = watch(source, value => {
            if (!value)
                return;
            stop();
            resolve();
        });
    });
}
export function resolveDepartmentGroup(department) {
    return DepartmentGroups.find(group => departmentGroupsMap[group]?.includes(department));
}
export function getSubCommittees(commission) {
    return commissionSubCommitteesMap[commission] || [];
}
