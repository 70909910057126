<template>
	<Box class="form-section" :marginBottom="6" v-bind="$attrs">
		<Box tag="header" v-if="hasHeaderSlot" :marginBottom="4">
			<slot name="header"></slot>
			<layoutText :fontSize="1" v-if="hasNoteSlot" class="note">
				<slot name="note"></slot>
			</layoutText>
		</Box>
		<slot name="main"></slot>
	</Box>
</template>

<script>
import Box from '@/shared/layout/box/layout-box.vue'
import layoutText from '@/shared/layout/text/layout-text.vue'

export default {
	components: {
		Box,
		layoutText
	},
	computed: {
		hasHeaderSlot() {
			return !!this.$slots.header
		},
		hasNoteSlot() {
			return !!this.$slots.note
		}
	},
	name: 'FormSection'
}
</script>

<style scoped lang="scss">
.form-section > header {
	font-weight: bold;
	font-size: 16px;
}
.form-section.bordered {
	border-bottom: 1px solid #efefef;
	padding-bottom: 15px;
}
.note {
	color: #ccc;
	display: block;
	margin: 5px 0;
}
.form-section {
	padding-bottom: 10px;
	border-bottom: 1px dashed #ddd;

	&:last-child {
		border: none;
		padding: 0;
		margin: 0 !important;
	}
}
</style>
