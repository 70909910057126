var _a;
import { __decorate, __metadata } from "tslib";
import LayoutText from '@/shared/layout/text/layout-text.vue';
import { PledgeUpdatedAuditLogItem } from '@/shared/schema/types';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { formatMoney } from '@/shared/util';
let PledgeUpdateAuditItem = class PledgeUpdateAuditItem extends Vue {
    item;
    formatMoney = formatMoney;
};
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", typeof (_a = typeof PledgeUpdatedAuditLogItem !== "undefined" && PledgeUpdatedAuditLogItem) === "function" ? _a : Object)
], PledgeUpdateAuditItem.prototype, "item", void 0);
PledgeUpdateAuditItem = __decorate([
    Component({
        components: {
            LayoutText
        }
    })
], PledgeUpdateAuditItem);
export default PledgeUpdateAuditItem;
