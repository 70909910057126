import UIDatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
import startCase from 'lodash/startCase';
import { Department, PledgeCategory, SendVia } from '@/shared/schema/globalTypes';
import FancySelect from '@/shared/ui/forms/fancy-select';
import { enumSelector } from '@/shared/enumSelector';
import UICheckBox from '@/shared/ui/forms/checkbox/checkbox.vue';
import DepartmentSelector from '@/shared/components/department-selector';
import UITextArea from '@/shared/ui/forms/textarea/ui-textarea.vue';
import UserLookup from '@/shared/components/user-lookup';
import NumberInput from '@/shared/ui/forms/number-input.vue';
import uiInput from '@/shared/ui/forms/input/ui-input.vue';
import GrantLookup from '@/shared/components/grant-lookup';
import DonorBox from './donor-box.vue';
import { UserType } from '@/shared/schema/globalTypes';
import BillingPlan from './billingplan/billing.vue';
const departments = Object.entries(Department).map(([key]) => ({
    id: key,
    label: startCase(key)
}));
export const formDescription = (showTurnaround, userId, userType, partialBillingPlan, donorId) => ({
    donor: [
        {
            inlineLabel: false,
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'donor',
                    component: [
                        DonorBox,
                        form => ({
                            props: {
                                disabled: !!form.donor && form.donor.id != donorId
                            }
                        })
                    ]
                }
            ]
        }
    ],
    pledgeData: [
        {
            inlineLabel: true,
            label: 'Pledge Date',
            rows: [
                {
                    type: 'single',
                    field: 'date',
                    component: UIDatePicker
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Accounting FY',
            class: 'tiny',
            rows: [
                {
                    type: 'single',
                    field: 'accountingFY',
                    component: [NumberInput, () => ({ props: { enableComma: false } })]
                }
            ],
            condition: () => userType !== UserType.Fundraiser
        },
        {
            inlineLabel: true,
            label: 'Fiscal Year',
            class: 'tiny',
            rows: [
                {
                    type: 'single',
                    field: 'fiscalYear',
                    component: [NumberInput, () => ({ props: { enableComma: false } })]
                }
            ],
            condition: () => userType !== UserType.Fundraiser
        },
        {
            inlineLabel: true,
            class: 'tiny',
            label: 'Calendar Year',
            rows: [
                {
                    type: 'single',
                    field: 'calendarYear',
                    component: [NumberInput, () => ({ props: { enableComma: false } })]
                }
            ],
            condition: () => userType !== UserType.Fundraiser
        },
        {
            inlineLabel: true,
            label: 'Amount',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'amount',
                    proportion: 4,
                    component: 'pledge-value'
                }
            ]
        },
        {
            inlineLabel: true,
            class: 'center',
            help: {
                size: '1x',
                color: '#385da3',
                text: 'Gift: Donation Contribution<br/>Grant: Contribution for which one made a formal application<br/>Tribute: Contribution made in recognition of a person or event'
            },
            label: 'Pledge Type',
            rows: [
                {
                    type: 'single',
                    field: 'type',
                    component: 'pledgeCategories',
                    proportion: 0
                },
                {
                    type: 'single',
                    field: 'tributeText',
                    component: [
                        uiInput,
                        {
                            attrs: {
                                placeholder: 'Enter tribute text here'
                            }
                        }
                    ],
                    condition: form => form.type === PledgeCategory.Tribute
                },
                {
                    type: 'single',
                    field: 'grant',
                    component: [
                        GrantLookup,
                        values => ({
                            props: {
                                donorId: values.donor ? values.donor.id : null
                            }
                        })
                    ],
                    condition: form => form.type === PledgeCategory.Grant
                }
            ]
        }
    ],
    conditions: [
        {
            inlineLabel: false,
            rows: [
                {
                    type: 'single',
                    field: 'conditionalPledge',
                    component: [
                        UICheckBox,
                        () => ({
                            attrs: {
                                labelText: 'Conditional Pledge'
                            }
                        })
                    ]
                }
            ]
        },
        {
            inlineLabel: false,
            rows: [
                {
                    type: 'single',
                    field: 'accountingPledge',
                    component: [
                        UICheckBox,
                        () => ({
                            attrs: {
                                labelText: 'Accounting Pledge'
                            }
                        })
                    ],
                    condition: () => userType !== UserType.Fundraiser
                }
            ]
        },
        {
            inlineLabel: false,
            rows: [
                {
                    type: 'single',
                    field: 'uncollectible',
                    component: [
                        UICheckBox,
                        () => ({
                            attrs: {
                                labelText: 'Uncollectible'
                            }
                        })
                    ]
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Reason',
            rows: [
                {
                    type: 'single',
                    field: 'uncollectibleReason',
                    component: uiInput
                }
            ],
            condition: form => form.uncollectible === true
        }
    ],
    designation: [
        {
            inlineLabel: true,
            label: 'Department',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'department',
                    component: [DepartmentSelector, () => ({ props: { defaultToUserDepartment: true } })]
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Campaign',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'campaign',
                    component: 'campaignLookup'
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Subcampaign',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'subcampaign',
                    component: 'subCampaignLookup'
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Notes',
            rows: [
                {
                    type: 'single',
                    field: 'description',
                    component: UITextArea
                }
            ]
        }
    ].concat(showTurnaround
        ? {
            inlineLabel: true,
            label: 'Turnaround',
            class: 'smaller',
            rows: [
                {
                    type: 'checkbox',
                    label: '',
                    field: 'hasTurnaround'
                }
            ]
        }
        : []),
    turnaround: [
        {
            inlineLabel: true,
            label: 'Department',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'taDepartment',
                    component: [
                        FancySelect,
                        {
                            props: {
                                options: departments
                            }
                        }
                    ]
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Campaign',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'taCampaign',
                    component: 'campaignLookup'
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Subcampaign',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'taSubcampaign',
                    component: 'subCampaignLookup'
                }
            ]
        }
    ],
    goal: [
        {
            inlineLabel: false,
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'goal',
                    component: 'pledgeGoal'
                }
            ]
        }
    ],
    fundraisers: [
        {
            inlineLabel: false,
            class: 'smaller inlineLabel percentage',
            rows: {
                type: 'repeatable',
                field: 'fundraisers',
                addLabel: '+ add Fundraiser',
                default: {
                    fundraiser: { id: userId, label: '' },
                    percent: 100
                },
                row: [
                    {
                        type: 'single',
                        field: 'fundraiser',
                        component: [
                            UserLookup,
                            {
                                props: {
                                    disableInactive: true
                                }
                            }
                        ],
                        proportion: 3
                    },
                    {
                        type: 'single',
                        field: 'percent',
                        component: NumberInput
                    }
                ]
            }
        }
    ],
    backupFiles: [
        {
            inlineLabel: false,
            class: 'inlineLabel',
            rows: {
                type: 'repeatable',
                field: 'backupFiles',
                addLabel: '+ add Backup File',
                default: {
                    uploadedFile: null,
                    description: null
                },
                row: [
                    {
                        type: 'single',
                        field: 'description',
                        component: 'downloadFile',
                        condition: (_form, value) => value.uploadedFile !== null
                    },
                    {
                        type: 'single',
                        field: 'uploadedFile',
                        component: 'uploadFile',
                        condition: (_form, value) => value.uploadedFile === null
                    }
                ]
            }
        }
    ],
    billing: [
        {
            inlineLabel: false,
            rows: [
                {
                    type: 'single',
                    component: [
                        BillingPlan,
                        values => ({
                            props: {
                                total: values.amount.amount,
                                totalPaid: partialBillingPlan ? values.totalPaid : 0,
                                donor: values.donor
                            }
                        })
                    ],
                    field: 'billingPlan',
                    condition: values => !!values.amount.amount
                }
            ]
        }
    ],
    invoice: [
        {
            inlineLabel: true,
            label: 'Send donor invoice',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'shouldDSPrintAnInvoice',
                    component: UICheckBox
                }
            ]
        }
    ],
    invoiceDetails: [
        {
            inlineLabel: true,
            label: 'Send via',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'sendInvoiceVia',
                    component: enumSelector(SendVia)
                }
            ]
        }
    ]
});
