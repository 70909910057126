import { PhoneNumberUtil } from 'google-libphonenumber';
import axios from 'axios';
import Countries from 'countries-list';
export function fiscalYear(date) {
    const year = date.getFullYear();
    if (date.getMonth() >= 6) {
        return year + 1;
    }
    return year;
}
export function isValidPhone(phone) {
    const util = PhoneNumberUtil.getInstance();
    if (phone === null)
        return false;
    try {
        const number = util.parse(phone);
        return util.isValidNumber(number);
    }
    catch (e) {
        return false;
    }
}
export function dateScalarToDate(scalar) {
    if (scalar === null)
        return null;
    if (scalar === undefined)
        return undefined;
    if (scalar instanceof Date)
        return scalar;
    if (typeof scalar === 'object') {
        return new Date(Date.UTC(scalar.year, scalar.month - 1, scalar.day));
    }
    if (typeof scalar === 'string') {
        if (String(+scalar) == scalar)
            return new Date(+scalar);
        return new Date(scalar);
    }
    else {
        return new Date(scalar);
    }
}
const getCountryCode = (country) => {
    if (!country)
        return 'US';
    if (Object.keys(Countries.countries)
        .map(x => x.toLowerCase())
        .includes(country.trim().toLowerCase()))
        return country;
    if (country.toLowerCase().trim() === 'united states of america')
        return 'US';
    if (country.toLocaleLowerCase().trim() == 'united states ofamerica')
        return 'US';
    if (country == 'USA420')
        return 'US';
    if (country.toUpperCase().trim() === 'USA')
        return 'US';
    if (country.toUpperCase().trim() === 'U.S.A.')
        return 'US';
    if (country.toUpperCase().trim() == 'U.S')
        return 'US';
    if (country.toUpperCase().trim() == 'U.S.')
        return 'US';
    if (country.toUpperCase().trim() == 'UK')
        return 'GB';
    if (country.toLowerCase().trim() == 'israe')
        return 'IL';
    if (country.toLowerCase().trim() == 'us minor outlying islands')
        return 'US';
    if (country.trim() == 'ישראל')
        return 'IL';
    const countriesList = Object.entries(Countries.countries).map(([code, { name }]) => ({ code, name }));
    const providedCountry = countriesList.find(currentCountry => currentCountry.name.toLowerCase().trim() === country.toLowerCase().trim());
    if (!providedCountry)
        throw `Country "${country}" is invalid. Cannot add this address`;
    return providedCountry.code;
};
export async function normalizeAddress(address) {
    const { street, street2, city, state, country, zip } = address;
    const normalizedCountry = getCountryCode(country);
    const unverified = {
        verified: false,
        address: { ...address, country: normalizedCountry },
    };
    const response = await axios
        .get('https://address-api.schoolsapi.oustatic.com', {
        params: {
            country: normalizedCountry,
            'street[street]': street,
            'street[street2]': street2 || '',
            'street[city]': city,
            'street[zipCode]': zip || '',
            'street[state]': state || '',
        },
    })
        .then(x => x.data);
    if (!('success' in response) && !!response?.result?.city) {
        return {
            verified: true,
            address: {
                ...address,
                city: response.result.city,
                street: response.result.street,
                street2: response.result.street2,
                state: response.result.state,
                zip: response.result.zipCode,
                country: normalizedCountry,
                metadata: {
                    recordType: response.raw?.[0].metadata.record_type,
                },
                analysis: response.raw?.[0].analysis,
            },
        };
    }
    else {
        return unverified;
    }
}
