import Box from '@/shared/layout/box/layout-box.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import uiLabel from '@/shared/ui/forms/label/ui-label.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import { defineComponent, toRef, ref, reactive, computed, watch } from 'vue';
import CCwidget from '@/shared/ui/forms/cc-widget';
import { useMutation } from '@vue/apollo-composable';
import SUBMIT_CARD_ON_FILE from './submit-card-on-file.graphql';
import LockUI from '@/shared/components/lock-ui/lock-ui.vue';
import Modal from '@/shared/components/modal/modal.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import Icon from '@/shared/components/icon/icon.vue';
function useReducer(reducer, initialState) {
    const state = ref(initialState);
    function dispatch(action) {
        //@ts-ignore
        state.value = reducer(state.value, action);
    }
    return {
        state,
        dispatch
    };
}
const reducer = (state, action) => {
    if (action.type == 'reset') {
        return {
            state: 'idle'
        };
    }
    switch (state.state) {
        case 'idle': {
            switch (action.type) {
                case 'setAddingNew':
                    return {
                        ...state,
                        state: 'showingAddNew',
                        errors: {}
                    };
                default:
                    return state;
            }
            break;
        }
        case 'showingAddNew': {
            switch (action.type) {
                case 'validate': {
                    return {
                        ...state,
                        state: 'validating',
                        form: action.form
                    };
                }
                default:
                    return state;
            }
            break;
        }
        case 'validating': {
            switch (action.type) {
                case 'setErrors': {
                    return {
                        ...state,
                        state: 'showingAddNew',
                        errors: action.errors
                    };
                }
                case 'submit': {
                    return {
                        ...state,
                        state: 'submitting',
                        card: action.card
                    };
                }
                default:
                    return state;
            }
        }
        case 'submitting': {
            return state;
        }
    }
};
async function resolveCardState(card) {
    if (!card.value)
        return null;
    if (card.value.type == 'success')
        return card.value;
    else if (card.value.type == 'error')
        return card.value.error;
    else {
        return new Promise(res => {
            watch(card, _ => {
                res(resolveCardState(card));
            });
        });
    }
}
async function validateForm(form) {
    const errors = {};
    const cardRef = toRef(form, 'card');
    const cardState = await resolveCardState(cardRef);
    if (form.firstName &&
        form.lastName &&
        form.street &&
        form.city &&
        form.state &&
        form.zipCode &&
        form.card &&
        form.gateway &&
        cardState &&
        !(typeof cardState == 'string')) {
        return {
            success: true,
            form: {
                firstName: form.firstName,
                lastName: form.lastName,
                street: form.street,
                street2: form.street2,
                city: form.city,
                state: form.state,
                zipCode: form.zipCode,
                card: cardState,
                gateway: form.gateway
            }
        };
    }
    if (!form.firstName)
        errors.firstName = 'First Name is required';
    if (!form.lastName)
        errors.lastName = 'Last Name is required';
    if (!form.street)
        errors.street = 'Street is required';
    if (!form.city)
        errors.city = 'City is required';
    if (!form.state)
        errors.state = 'State is required';
    if (!form.zipCode)
        errors.zipCode = 'Zip Code is required';
    if (!form.gateway)
        errors.gateway = 'Bank is required';
    if (!cardState)
        errors.card = 'Card Info is required';
    else if (typeof cardState == 'string')
        errors.card = cardState;
    return { success: false, errors };
}
export default defineComponent({
    components: {
        Box,
        uiLabel,
        UIButton,
        UIInput,
        UISelect,
        layoutFormRow,
        LockUI,
        Modal,
        layoutText,
        Icon,
        CCwidget
    },
    props: {
        value: Object,
        donor: {
            type: Object,
            required: true
        },
        amount: Number,
        currency: String,
        testMode: Boolean
    },
    setup(props, { emit }) {
        const addNew = ref(false);
        const cardsOnFileCopy = ref(props.donor.cardsOnFile);
        function initialState() {
            return {
                firstName: props.donor.primaryPerson?.firstName || null,
                lastName: props.donor.primaryPerson?.lastName || null,
                street: props.donor.address?.street || null,
                street2: props.donor.address?.street2 || null,
                city: props.donor.address?.city || null,
                state: props.donor.address?.state || null,
                zipCode: props.donor.address?.zip || null,
                card: null,
                gateway: 'SensePassCardknox'
            };
        }
        const form = reactive(initialState());
        const { state, dispatch } = useReducer(reducer, {
            state: 'idle'
        });
        async function submit() {
            dispatch({ type: 'validate', form });
        }
        const mutation = useMutation(SUBMIT_CARD_ON_FILE);
        watch(state, async (state) => {
            if (state.state == 'validating') {
                const result = await validateForm(form);
                if (result.success) {
                    dispatch({
                        type: 'submit',
                        card: {
                            token: result.form.card.token,
                            gateway: result.form.gateway,
                            card: result.form.card.card,
                            billingAddress: {
                                street: result.form.street,
                                street2: result.form.street2,
                                city: result.form.city,
                                country: 'US',
                                state: result.form.state,
                                zip: result.form.zipCode,
                                careOf: null
                            },
                            billingFirstName: result.form.firstName,
                            billingLastName: result.form.lastName,
                            expirationMonth: Number(result.form.card.expMonth),
                            expirationYear: Number(result.form.card.expYear)
                        }
                    });
                }
                else {
                    dispatch({ type: 'setErrors', errors: result.errors });
                }
            }
            if (state.state == 'idle') {
                Object.assign(form, initialState());
            }
            if (state.state == 'submitting') {
                mutation.onDone(() => {
                    const cardOnFile = {
                        __typename: 'CardOnFile',
                        token: state.card.token,
                        gateway: state.card.gateway,
                        card: state.card.card,
                        billingAddress: {
                            __typename: 'Address',
                            street: state.card.billingAddress.street,
                            street2: state.card.billingAddress.street2 || null,
                            city: state.card.billingAddress.city,
                            state: state.card.billingAddress.state || null,
                            country: state.card.billingAddress.country,
                            zip: state.card.billingAddress.zip || null
                        },
                        billingFirstName: state.card.billingFirstName,
                        billingLastName: state.card.billingLastName,
                        expirationMonth: state.card.expirationMonth,
                        expirationYear: state.card.expirationYear
                    };
                    cardsOnFileCopy.value.push(cardOnFile);
                    emit('input', cardOnFile);
                    dispatch({ type: 'reset' });
                });
                mutation.mutate({
                    card: state.card,
                    donorId: props.donor.id
                });
            }
        });
        function cardChanged(data) {
            form.card = data;
        }
        return {
            addNew,
            submitting: computed(() => state.value.state == 'submitting' || state.value.state == 'validating'),
            showingAddNew: computed(() => state.value.state == 'showingAddNew'),
            dispatch,
            form,
            errors: computed(() => (state.value.state == 'showingAddNew' ? state.value.errors : {})),
            submit,
            cardsOnFileCopy,
            cardChanged
        };
    }
});
