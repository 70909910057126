import { __decorate, __metadata } from "tslib";
import PledgeAuditLog from './audit-log';
import { Vue, Prop, Component, Inject } from 'vue-property-decorator';
import * as TableGroup from '@/shared/ui/table';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import uiLabel from '@/shared/ui/forms/label/ui-label.vue';
import FormSection from '@/shared/ui/forms/form-section/form-section.vue';
import Card from '@/shared/ui/card/card.vue';
import EditInvoice from '@/shared/components/invoice';
import EditReceipt from '@/shared/components/receipt';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import NavItem from '@/shared/components/navigation-panel/navItem.vue';
import NavigationPanel from '@/shared/components/navigation-panel/navigation-panel.vue';
import { TabNav, TabPanel, Tabs } from '@/shared/ui/tabs';
import PledgeTab from './pledge';
import PaymentsTab from './payment';
import { addWithRounding, monthDayYearToDate } from '@/shared/util';
import { dateScalarToDate } from '@velocity/shared/dist/utils';
import { MatchFirst, Route, RouterLink, Redirect } from 'vue-component-router';
import { CurrencyType } from '../../schema/globalTypes';
let PledgeForm = class PledgeForm extends Vue {
    router;
    goals;
    pledge;
    payments;
    donor;
    refreshData;
    audit;
    turnaroundPayments;
    baseUrl;
    get auditProps() {
        return {
            log: this.audit
                .map(item => ({
                ...item,
                type: item.__typename,
                date: dateScalarToDate(item.date)
            }))
                .sort((a, b) => a.date.valueOf() - b.date.valueOf())
        };
    }
    get pledgeTabProps() {
        return {
            donor: this.donor,
            pledge: this.pledge,
            paymentsCount: this.payments ? this.payments.length : 0,
            audit: this.audit,
            turnaroundPayments: this.turnaroundPayments
        };
    }
    get paymentsTabProps() {
        const isUsdPledge = this.pledge?.amount?.currency === CurrencyType.USD;
        const convertedPledgeAmount = this.pledge
            ? addWithRounding([+this.pledge.convertedAmount, -addWithRounding(this.payments.map(x => x.amount))])
            : 0;
        return {
            baseUrl: `${this.baseUrl}/payments`,
            payments: this.payments.sort((a, b) => monthDayYearToDate(a.date).valueOf() - monthDayYearToDate(b.date).valueOf()),
            pledgeId: this.pledge ? this.pledge.id : null,
            pledgeDepartment: this.pledge?.department ?? null,
            donor: this.donor,
            pledgeCurrency: this.pledge?.amount?.currency ?? CurrencyType.USD,
            pledgeAmount: this.pledge && convertedPledgeAmount
                ? addWithRounding([
                    +this.pledge.amount.amount,
                    -addWithRounding(this.payments.map(x => (isUsdPledge ? x.amount : x.originalAmount)))
                ])
                : 0
        };
    }
    get paymentId() {
        return this.payments && this.payments[0].id;
    }
    onPaymentsChanged() {
        this.refreshData();
    }
};
__decorate([
    Inject('router'),
    __metadata("design:type", Object)
], PledgeForm.prototype, "router", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "goals", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "pledge", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "payments", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "donor", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "refreshData", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "audit", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "turnaroundPayments", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], PledgeForm.prototype, "baseUrl", void 0);
PledgeForm = __decorate([
    Component({
        components: {
            PledgeAuditLog,
            Box,
            uiLabel,
            layoutFormRow,
            FormSection,
            Card,
            EditInvoice,
            EditReceipt,
            NavigationPanel,
            NavItem,
            UISelect,
            ...TableGroup,
            TabNav,
            TabPanel,
            Tabs,
            PledgeTab,
            PaymentsTab,
            MatchFirst,
            Route,
            RouterLink,
            Redirect
        }
    })
], PledgeForm);
export default PledgeForm;
