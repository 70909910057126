<template>
	<Box class="lock-ui" v-if="show">
		<span v-if="text">{{ text }}</span>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box.vue'

export default {
	name: 'LockUI',
	components: {
		Box
	},
	props: {
		show: {
			type: Boolean
		},
		text: ''
	},
	watch: {
		show(show) {
			if (show) {
				document.body.parentElement.classList.add('noscroll')
			} else {
				document.body.parentElement.classList.remove('noscroll')
			}
		}
	}
}
</script>
<style scoped lang="scss">
.lock-ui {
	position: absolute;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	display: block;
	transition: opacity 0.3s ease;
	overflow: auto;

	span {
		position: absolute;
		top: 45%;
		left: 49%;
		font-size: 16px;
		color: #666;
	}
}
</style>
