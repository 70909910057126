import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import NotificationsContainer from './notifications-container.vue';
export const notifications = Vue.observable({
    notifications: []
});
export function notify(message, state = 'success') {
    const id = uuid();
    notifications.notifications.unshift({
        id,
        message,
        state,
        startTime: Date.now()
    });
    setTimeout(() => {
        const index = notifications.notifications.findIndex(x => x.id === id);
        notifications.notifications.splice(index, 1);
    }, 5000);
}
export default NotificationsContainer;
