import { getOptions, normalizeProps, rootFieldFromDocumentNode } from '@/shared/util';
import Vue from 'vue';
export default function query(query, { options, props, skip } = {}) {
    if (!options) {
        options = (_props) => ({});
    }
    const rootField = rootFieldFromDocumentNode(query);
    return function GraphqlHOC(WrappedComponent) {
        const childProps = { ...normalizeProps(getOptions(WrappedComponent).props) };
        Object.values(childProps).forEach((x) => {
            delete x.required;
        });
        delete childProps[rootField];
        return Vue.extend({
            props: childProps,
            name: `${getOptions(WrappedComponent).name}WithData`,
            render(h) {
                const ownProps = Object.assign({}, this.$props, this.$vnode.data && this.$vnode.data.props);
                const skipFromProps = skip ? skip(ownProps) : false;
                const optionsFromProps = !skipFromProps ? options(ownProps) : {};
                return h('ApolloQuery', {
                    props: {
                        query,
                        variables: optionsFromProps.variables,
                        skip: skipFromProps,
                        notifyOnNetworkStatusChange: true,
                        fetchPolicy: optionsFromProps.fetchPolicy || 'cache-first',
                        errorPolicy: 'all'
                    },
                    scopedSlots: {
                        default: ({ query, result }) => {
                            const refetch = query.refetch.bind(query);
                            const nextResult = query.nextResult.bind(query);
                            const fetchMore = query.fetchMore.bind(query);
                            const setVariables = query.setVariables.bind(query);
                            const setOptions = query.setOptions.bind(query);
                            const startPolling = query.startPolling.bind(query);
                            const stopPolling = query.stopPolling.bind(query);
                            const currentResult = query.observer ? query.observer.currentResult() : null;
                            if (result.error) {
                                throw new Error(result.error.message);
                            }
                            const graphqlPayload = {
                                loading: currentResult ? currentResult.loading : false,
                                error: result.error,
                                data: !skipFromProps
                                    ? Object.assign({}, result.data, {
                                        refetch,
                                        nextResult,
                                        fetchMore,
                                        setVariables,
                                        setOptions,
                                        startPolling,
                                        stopPolling,
                                        networkStatus: currentResult ? currentResult.networkStatus : 0
                                    })
                                    : undefined
                            };
                            const renderProps = Object.assign({}, ownProps, {
                                loading: graphqlPayload.loading,
                                error: graphqlPayload.error
                            }, props
                                ? props(Object.assign({}, ownProps, graphqlPayload))
                                : Object.assign({}, ownProps, graphqlPayload));
                            return [
                                h(WrappedComponent, {
                                    on: this.$listeners,
                                    scopedSlots: this.$scopedSlots,
                                    props: renderProps
                                })
                            ];
                        }
                    }
                });
            }
        });
    };
}
