import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/shared/components/modal/modal.vue';
import { FormBuilder } from '@/shared/form-builder';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import UISwitch from '@/shared/ui/forms/switch/switch.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
let VoidModal = class VoidModal extends Vue {
    initialFormValues = {
        uncollectible: false,
        uncollectibleReason: null
    };
    formDescription = {
        main: [
            {
                inlineLabel: true,
                label: 'Uncollectible',
                rows: [
                    {
                        type: 'single',
                        field: 'uncollectible',
                        component: UISwitch
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Uncollectible Reason',
                rows: [
                    {
                        type: 'single',
                        field: 'uncollectibleReason',
                        component: UIInput
                    }
                ],
                condition: form => form.uncollectible
            }
        ]
    };
    validate() { }
    onSubmit(args) {
        if (!args.values)
            return;
        this.$emit('submit', args.values);
    }
    onCancel() {
        this.$emit('close');
    }
};
VoidModal = __decorate([
    Component({
        components: {
            Modal,
            FormBuilder,
            Box,
            layoutText,
            UIButton
        }
    })
], VoidModal);
export default VoidModal;
