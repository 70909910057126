<template>
	<tbody>
		<slot></slot>
	</tbody>
</template>

<script>
export default {
	name: 'TableBody',
	props: ['label']
}
</script>

<style scoped></style>
