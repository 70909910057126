var _a;
import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import FancySelect from '@/shared/ui/forms/fancy-select';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
let GoalLookup = class GoalLookup extends Vue {
    value;
    goals;
    loading;
    donorId;
};
__decorate([
    Prop({ type: [Object, Array] }),
    __metadata("design:type", Object)
], GoalLookup.prototype, "value", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Array)
], GoalLookup.prototype, "goals", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], GoalLookup.prototype, "loading", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], GoalLookup.prototype, "donorId", void 0);
GoalLookup = __decorate([
    Component({
        components: {
            FancySelect,
            Box,
            layoutText
        }
    })
], GoalLookup);
export default GoalLookup;
