import HelpIcon from '../components/help-icon/help-icon.vue';
import Vue from 'vue';
import castArray from 'lodash/castArray';
import SimpleForm, { Error, Field } from 'vue-simpleform';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import Box from '@/shared/layout/box/layout-box.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import UICheckbox from '@/shared/ui/forms/checkbox/checkbox.vue';
import LayoutText from '@/shared/layout/text/layout-text.vue';
import FormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import merge from 'lodash/merge';
import Icon from '@/shared/components/icon/icon.vue';
const styles = require('./form.module.css');
function repeatableField(field, h, $scopedSlots, values, errors, key, setValue) {
    return h(Box, { props: { flex: 1 } }, [
        ...values[key].map((item, i) => [
            h(FormRow, [
                ...castArray(field.row)
                    .filter(x => !x.condition || x.condition(values, values[key][i], values[key]))
                    .map(x => componentForField({
                    ...x,
                    field: `${String(field.field)}[${i}].${String(x.field)}`
                }, h, $scopedSlots, values, errors, true, item)),
                h(Box, {
                    props: { flex: 0, paddingTop: 3 },
                    on: {
                        click: () => {
                            const nextValues = [...values[key]];
                            nextValues.splice(i, 1);
                            setValue(String(field.field), nextValues);
                        }
                    }
                }, [
                    h(Icon, {
                        props: { name: 'trash' },
                        attrs: {
                            class: 'del-item'
                        }
                    })
                ])
            ])
        ]),
        h(Box, [
            h(LayoutText, {
                props: {
                    fontSize: 1
                },
                attrs: {
                    class: styles['add-link']
                },
                on: {
                    click: async () => {
                        setValue(String(field.field), [
                            ...values[key],
                            { ...(typeof field.default == 'function' ? field.default() : field.default) } || {}
                        ]);
                    }
                }
            }, [field.addLabel])
        ])
    ]);
}
function componentForField(field, h, $scopedSlots, values, errors, showLabel = true, rowItem) {
    let Component = field.type === 'checkbox' ? UICheckbox : field.component || UIInput;
    if (typeof Component === 'string') {
        if ($scopedSlots && $scopedSlots[Component]) {
            const slot = $scopedSlots[Component];
            Component = Vue.extend({
                name: `${String(field.field)}Component`.replace(/[\[\]]/g, '-'),
                props: ['value'],
                render(_h) {
                    if (!slot) {
                        throw 'No slot';
                    }
                    const vnodes = slot({
                        listeners: this.$listeners,
                        value: this.value,
                        values,
                        errors,
                        rowItem,
                        field: field.field
                    });
                    if (!vnodes)
                        throw 'Nothing returned';
                    //@ts-ignore stopped working in vue 2.6.10
                    return vnodes[0];
                }
            });
        }
    }
    return h(Box, {
        props: {
            marginRight: 2,
            flex: field.proportion === undefined ? '1' : String(field.proportion)
        }
    }, field.type !== 'checkbox'
        ? [
            ...(showLabel ? [h(UILabel, { props: { text: field.label }, attrs: { for: field.field } })] : []),
            h(Field, { props: { name: field.field } }, [
                h(Array.isArray(Component) ? Component[0] : Component, merge(Array.isArray(Component)
                    ? typeof Component[1] === 'function'
                        ? Component[1](values)
                        : Component[1]
                    : {}, {
                    attrs: {
                        id: field.field
                    }
                }))
            ]),
            h(Error, { props: { name: field.field }, class: 'error-label' })
        ]
        : [h(Field, { props: { name: field.field } }, [h(UICheckbox, { props: { labelText: field.label } })])]);
}
export const FormBuilder = Vue.extend({
    name: 'FormBuilder',
    props: {
        form: {
            type: Object
        },
        initial: {},
        validate: {}
    },
    render(h) {
        if (Array.isArray(this.form)) {
            // will do when we have a need for this :)
            return h();
        }
        else {
            const form = this.form;
            const formRows = Object.entries(form);
            const slots = this.$scopedSlots;
            if (!slots || !slots.default) {
                console.warn(`You didn't provide a scoped slot`);
                return h();
            }
            return h(SimpleForm, {
                props: {
                    value: this.initial,
                    validate: this.validate
                },
                on: {
                    submit: (args) => {
                        this.$emit('submit', args);
                    },
                    values: (args) => {
                        this.$emit('values', args);
                    }
                },
                scopedSlots: {
                    default: ({ handleSubmit, values, setValue, errors }) => {
                        const t = (..._args) => true;
                        const components = formRows.reduce((components, [key, rows]) => {
                            const component = Vue.extend({
                                render(h) {
                                    return h('div', rows
                                        .filter(x => !x.condition || x.condition(values))
                                        .map(field => h(FormRow, {
                                        props: {
                                            inlineLabel: field.inlineLabel
                                        },
                                        class: {
                                            'repeater-row': !Array.isArray(field.rows),
                                            ...(field.class ? { [field.class]: true } : {})
                                        }
                                    }, [
                                        ...(field.inlineLabel
                                            ? [h(UILabel, { props: { text: field.label } })]
                                            : []),
                                        ...(Array.isArray(field.rows)
                                            ? field.rows.map(x => {
                                                if (!x.condition || x.condition(values)) {
                                                    return componentForField(x, h, slots, values, errors, !field.inlineLabel);
                                                }
                                                else {
                                                    return h();
                                                }
                                            })
                                            : [
                                                !field.rows.condition ||
                                                    field.rows.condition(values)
                                                    ? repeatableField(field.rows, h, slots, values, errors, field.rows.field, setValue)
                                                    : null
                                            ]),
                                        ...(field.help ? [h(HelpIcon, { props: field.help })] : [])
                                    ])));
                                }
                            });
                            return {
                                ...components,
                                [key]: component
                            };
                        }, {});
                        const vnodes = slots.default({ ...components, $values: values, $errors: errors });
                        return [
                            h('form', {
                                on: {
                                    submit(e) {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }
                            }, [h(Box, vnodes)])
                        ];
                    }
                }
            });
        }
    }
});
