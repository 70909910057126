import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import Icon from '@/shared/components/icon/icon.vue';
import { Component, Prop } from 'vue-property-decorator';
let UICheckbox = class UICheckbox extends Vue {
    value;
    labelText;
    disabled;
    onChange($event) {
        this.$emit('input', $event.target.checked);
        this.$emit('blur');
    }
};
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], UICheckbox.prototype, "value", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], UICheckbox.prototype, "labelText", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], UICheckbox.prototype, "disabled", void 0);
UICheckbox = __decorate([
    Component({
        components: {
            Icon
        }
    })
], UICheckbox);
export default UICheckbox;
