<template>
	<div class="modal-mask" v-if="show" @click="outsideClick()">
		<Box class="modal-wrapper" :class="{ absolute: absolutePos }" :style="{ width, height, bottom, right, top }">
			<span class="close-icon" @click="closeClick()" v-if="!showCloseText">
				<Icon name="times" />
			</span>
			<span v-else class="close-icon close-icon--alt" @click="closeClick()">
				<Icon name="times" />
				<span>&nbsp;Close</span>
			</span>
			<Box class="modal-container" @click.stop :style="{ height }">
				<ScrollablePanel :style="{ height }" v-if="withScroll">
					<slot :requestClose="requestClose" />
				</ScrollablePanel>
				<slot :requestClose="requestClose" v-else />
			</Box>
		</Box>
	</div>
</template>
<script>
import Icon from '@/shared/components/icon/icon.vue'
import Box from '@/shared/layout/box/layout-box.vue'
import ScrollablePanel from '@/shared/components/scrollable-panel/scrollable-panel.vue'

export default {
	name: 'Modal',
	components: {
		Icon,
		Box,
		ScrollablePanel
	},
	props: {
		show: {
			type: Boolean
		},
		closeOnOutClick: {
			type: Boolean,
			default: false
		},
		requestClose: {
			type: Function
		},
		width: {
			type: String
		},
		height: {
			type: String
		},
		bottom: {
			type: String
		},
		right: {
			type: String
		},
		top: {
			type: String
		},
		showCloseText: {
			type: Boolean,
			default: true
		},
		withScroll: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		absolutePos() {
			return !!(this.bottom || this.right)
		}
	},
	methods: {
		closeClick() {
			this.requestClose && this.requestClose()
		},
		outsideClick() {
			if (this.closeOnOutClick) {
				this.requestClose && this.requestClose()
			}
		}
	},
	watch: {
		show(show) {
			if (show) {
				document.body.parentElement.classList.add('noscroll')
			} else {
				document.body.parentElement.classList.remove('noscroll')
			}
		}
	}
}
</script>
<style scoped lang="scss">
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	display: block;
	transition: opacity 0.3s ease;
	overflow: auto;
}

.modal-wrapper {
	display: block;
	position: relative;
	margin: auto;

	&.absolute {
		position: absolute;
	}
}

.modal-container {
	margin: 0px auto;
	padding: 0;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	transition: all 0.3s ease;
	position: relative;
	height: 100%;
	width: 100%;
}

.close-icon {
	cursor: pointer;
	position: absolute;
	top: 7px;
	right: 7px;
	color: #999;
	z-index: 10;
	font-size: 12px;
	transition: all 0.1s ease-in-out;
	padding: 3px 8px;

	&:hover {
		color: #666;
	}
}
.close-icon--alt {
	border-radius: 4px;
	background: #ccc;
	font-size: 11px;
	padding: 4px 8px;
	opacity: 0.7;
	color: #222;
	line-height: 1;

	&:hover {
		background: #666;
		color: white;
	}
}
</style>
