var _a;
import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import DatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
let DateRangePicker = class DateRangePicker extends Vue {
    value;
};
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], DateRangePicker.prototype, "value", void 0);
DateRangePicker = __decorate([
    Component({
        components: {
            DatePicker
        }
    })
], DateRangePicker);
export default DateRangePicker;
