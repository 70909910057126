import FancySelect from '@/shared/ui/forms/fancy-select';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { defineComponent, ref, computed, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import DONOR_LOOKUP from './lookup-donors.graphql';
import { isNumber, formatAddress } from '@/shared/util';
function useSetAfterDoneTyping(callback) {
    const tentativeQuery = ref('');
    function onType(term) {
        tentativeQuery.value = term;
    }
    let timeoutRef;
    watch(tentativeQuery, () => {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        timeoutRef = setTimeout(() => {
            callback(tentativeQuery.value);
        }, 700);
    });
    return onType;
}
export default defineComponent({
    components: {
        FancySelect,
        layoutText
    },
    props: {
        placeholder: { type: String, default: 'Type donor name' },
        value: Object,
        clearable: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        donorKind: String
    },
    setup(props, { attrs, listeners, emit }) {
        const query = ref('');
        const fancyselect = ref(null);
        const { result, loading } = useQuery(DONOR_LOOKUP, () => ({
            query: query.value,
            donorKind: props.donorKind
        }), () => ({
            enabled: !!query.value,
            fetchPolicy: 'no-cache'
        }));
        const donors = useResult(result, [], result => {
            const donors = result.donorLookup.donors.map(donor => ({
                id: donor.id,
                name: donor.name,
                shortId: donor.shortId,
                primaryPerson: donor.__typename == 'Household' ? donor.primaryPerson : null,
                secondaryPerson: donor.__typename == 'Household' ? donor.secondaryPerson : null,
                address: donor.address,
                addressStr: donor.address ? formatAddress(donor.address) : null,
                cardsOnFile: donor.cardsOnFile,
                isHousehold: donor.__typename == 'Household'
            }));
            if (isNumber(query.value)) {
                const index = donors.findIndex(x => x.shortId == query.value);
                if (index != -1) {
                    const [donor] = donors.splice(index, 1);
                    donors.unshift(donor);
                }
            }
            if (result.donorLookup.hasMore) {
                donors.push({
                    id: '',
                    name: 'More Results',
                    address: null,
                    shortId: '',
                    isHousehold: false,
                    addressStr: '',
                    cardsOnFile: []
                });
            }
            return donors;
        });
        return {
            query,
            loading,
            donors,
            fancyselect,
            onSearch: useSetAfterDoneTyping(value => (query.value = value)),
            listeners: computed(() => ({
                ...listeners,
                input(value) {
                    if (value?.id)
                        emit('input', value);
                    else {
                        emit('input', null);
                        fancyselect.value.$refs.multiselect.mutableValue = '';
                    }
                }
            }))
        };
    }
});
