<template>
	<Button v-bind="$attrs" v-on="$listeners" :class="classes">
		<slot></slot>
	</Button>
</template>

<script>
export default {
	name: 'UIButton',
	props: ['color', 'rounded', 'inverted'],
	computed: {
		classes() {
			const classes = {}

			if (this.color) {
				classes[`${this.color}`] = true
			}

			if (this.inverted) {
				classes[`inverted`] = true
			}

			if (this.rounded) {
				classes[`rounded`] = true
			}

			return classes
		}
	}
}
</script>

<style lang="scss" scoped>
button {
	box-shadow: none;
	border-radius: 4px;
	background: var(--main-blue);
	padding: 8px 13px;
	cursor: pointer;
	color: white;
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	font-size: var(--main-font-size);
	font-family: var(--font-main);

	&:hover {
		background: darken(#385da3, 10%);
	}
}

button[disabled] {
	background: var(--main-gray) !important;
	cursor: not-allowed;
}

button.rounded {
	border-radius: 15px;
}
.inverted {
	background: transparent;
	border-color: var(--main-blue);
	color: var(--main-blue);

	&:hover {
		background: var(--main-blue);
		color: white;
	}
}
.smaller {
	font-size: 11px;
	padding: 6px 8px;
}
/* specific colors */
.black {
	background: #444;
	border-color: #444;

	&.inverted {
		background: transparent;
		color: #444;

		&:hover {
			background: #444;
			color: white;
		}
	}
}
.green {
	background: var(--main-green);
	border-color: var(--main-green);

	&.inverted {
		background: transparent;
		color: var(--main-green);

		&:hover {
			background: var(--main-green);
			color: white;
		}
	}
}
.red {
	background: var(--main-red);
	border-color: var(--main-red);

	&.inverted {
		background: transparent;
		color: var(--main-red);

		&:hover {
			background: var(--main-red);
			color: white;
		}
	}
	&:hover {
		background: red;
		border-color: red;
	}
}
</style>
