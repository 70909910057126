import Vue from 'vue';
export default Vue.extend({
    props: {
        tabId: {}
    },
    inject: ['tabs'],
    render(h) {
        if (this.tabId !== this.tabs.tabData.activeTab) {
            return h();
        }
        if (!this.$slots.default) {
            return h();
        }
        const vnodes = this.$slots.default;
        const vnode = vnodes.length > 1 ? h('div', [vnodes]) : vnodes[0];
        return vnode;
    }
});
