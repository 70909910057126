import { GQLDepartment } from './gql-types';
export const departmentGroupsMap = {
    [GQLDepartment.NcsyNationalGroup]: [
        GQLDepartment.Ncsy,
        GQLDepartment.NcsyAlumni,
        GQLDepartment.NcsySummer,
        GQLDepartment.NcsyReliefMissions,
    ],
    [GQLDepartment.NcsyWestCoastAreaBGroup]: [
        GQLDepartment.NcsyArizona,
        GQLDepartment.NcsyNorcal,
        GQLDepartment.NcsyOrangeCounty,
        GQLDepartment.NcsySanDiego,
        GQLDepartment.NcsySeattle,
    ],
    [GQLDepartment.NcsyWestCoastGroup]: [
        GQLDepartment.NcsyWestCoastAreaBGroup,
        GQLDepartment.NcsyWestCoast,
        GQLDepartment.NcsyLaCity,
        GQLDepartment.NcsyLaValley,
        GQLDepartment.NcsyLasVegas,
        GQLDepartment.NcsyParkCityUtah,
    ],
    [GQLDepartment.NcsyCanadaGroup]: [
        GQLDepartment.NcsyCanada,
        GQLDepartment.NcsyMontreal,
        GQLDepartment.NcsyVancouver,
        GQLDepartment.NcsyOttawa,
        GQLDepartment.NcsyBritishColumbia,
    ],
    [GQLDepartment.NcsyCentralEastGroup]: [GQLDepartment.NcsyCentralEast, GQLDepartment.NcsyCleveland],
    [GQLDepartment.NcsyMidwestGroup]: [GQLDepartment.NcsyMidwest, GQLDepartment.NcsyStl],
    [GQLDepartment.NcsyAdminGroup]: [
        GQLDepartment.NcsyNationalGroup,
        GQLDepartment.NcsyArgentina,
        GQLDepartment.NcsyNewEngland,
        GQLDepartment.NcsyAtlanta,
        GQLDepartment.NcsyAtlanticSeaboard,
        GQLDepartment.NcsyChile,
        GQLDepartment.NcsyIsrael,
        GQLDepartment.NcsyNewJersey,
        GQLDepartment.NcsyNewYork,
        GQLDepartment.NcsyNynj,
        GQLDepartment.NcsyUpstateNewYork,
        GQLDepartment.NcsySouthern,
        GQLDepartment.NcsySouthwest,
        GQLDepartment.NcsyCanadaGroup,
        GQLDepartment.NcsyWestCoastGroup,
        GQLDepartment.NcsyCentralEastGroup,
        GQLDepartment.NcsyMidwestGroup,
        GQLDepartment.NcsyPortland,
        GQLDepartment.NcsyMexico,
        GQLDepartment.NcsyBoston,
        GQLDepartment.NcsyTristate,
    ],
    [GQLDepartment.JlicAdminGroup]: [
        GQLDepartment.Jlic,
        GQLDepartment.JlicAtlanta,
        GQLDepartment.JlicAriel,
        GQLDepartment.JlicBenGurion,
        GQLDepartment.JlicBarIlan,
        GQLDepartment.JlicMachonTal,
        GQLDepartment.JlicBinghamton,
        GQLDepartment.JlicBostonUniversity,
        GQLDepartment.JlicBrandeis,
        GQLDepartment.JlicBrooklynCollege,
        GQLDepartment.JlicChicago,
        GQLDepartment.JlicColumbia,
        GQLDepartment.JlicCornell,
        GQLDepartment.JlicCsun,
        GQLDepartment.JlicDrexel,
        GQLDepartment.JlicIdcHerzliya,
        GQLDepartment.JlicJohnsHopkins,
        GQLDepartment.JlicKahal,
        GQLDepartment.JlicMcMaster,
        GQLDepartment.JlicMcGill,
        GQLDepartment.JlicMichigan,
        GQLDepartment.JlicNyu,
        GQLDepartment.JlicPenn,
        GQLDepartment.JlicPrinceton,
        GQLDepartment.JlicQueens,
        GQLDepartment.JlicRutgers,
        GQLDepartment.JlicSmc,
        GQLDepartment.JlicTau,
        GQLDepartment.JlicUcla,
        GQLDepartment.JlicUillinois,
        GQLDepartment.JlicUmass,
        GQLDepartment.JlicUmd,
        GQLDepartment.JlicUwm,
        GQLDepartment.JlicWestern,
        GQLDepartment.JlicYale,
        GQLDepartment.JlicYavneh,
        GQLDepartment.JlicYork,
        GQLDepartment.JlicJerusalem,
        GQLDepartment.JlicTlv,
        GQLDepartment.JlicTechnion,
        GQLDepartment.JlicMachonLev,
        GQLDepartment.JlicWashingtonUniversity,
    ],
    [GQLDepartment.TeachAdminGroup]: [
        GQLDepartment.TeachCoalition,
        GQLDepartment.TeachCoalitionCa,
        GQLDepartment.TeachCoalitionFl,
        GQLDepartment.TeachCoalitionMd,
        GQLDepartment.TeachCoalitionNj,
        GQLDepartment.TeachCoalitionNys,
        GQLDepartment.TeachCoalitionPa,
        GQLDepartment.TeachCoalitionNv,
        GQLDepartment.TeachCoalitionProjectProtect,
    ],
    [GQLDepartment.YachadAdminGroup]: [
        GQLDepartment.Yachad,
        GQLDepartment.YachadBaltimore,
        GQLDepartment.YachadChicago,
        GQLDepartment.YachadCleveland,
        GQLDepartment.YachadDallas,
        GQLDepartment.YachadDetroit,
        GQLDepartment.YachadFlorida,
        GQLDepartment.YachadHouston,
        GQLDepartment.YachadIsrael,
        GQLDepartment.YachadIvdu,
        GQLDepartment.YachadLosAngeles,
        GQLDepartment.YachadMidwest,
        GQLDepartment.YachadNewEngland,
        GQLDepartment.YachadNewJersey,
        GQLDepartment.YachadNewYork,
        GQLDepartment.YachadCleveland,
        GQLDepartment.YachadOmaha,
        GQLDepartment.YachadOurWay,
        GQLDepartment.YachadPhiladelphia,
        GQLDepartment.YachadSpecialInitiatives,
        GQLDepartment.YachadSummer,
        GQLDepartment.YachadToronto,
        GQLDepartment.YachadReach,
    ],
    [GQLDepartment.OuGroup]: [GQLDepartment.Ou],
};
export const DepartmentGroups = Object.keys(departmentGroupsMap);
export function resolveDepartments(departments) {
    if (!Array.isArray(departments))
        departments = [departments];
    return departments.flatMap(x => (isDepartmentGroup(x) ? resolveDepartments(departmentGroupsMap[x]) : x));
}
export function resolveParentDepartments(departments) {
    if (!Array.isArray(departments))
        departments = [departments];
    return departments.flatMap(x => {
        const parents = Object.entries(departmentGroupsMap)
            .filter(([, deps]) => deps.includes(x))
            .map(([g]) => g);
        return [x, ...resolveParentDepartments(parents)];
    });
}
export function isDepartmentGroup(deptOrGroup) {
    return DepartmentGroups.includes(deptOrGroup);
}
export function resolveDepartmentGroup(department) {
    return DepartmentGroups.find(group => departmentGroupsMap[group]?.includes(department));
}
