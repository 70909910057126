import query from '@/shared/graphql/query';
import { CurrencyType } from '@/shared/schema/globalTypes';
import { acceptProps, compose, withData, withProps } from 'vue-compose';
import AmountWithCurrencyField from './amount-with-currency.vue';
import CONVERT_CURRENCY from './convert-currency.graphql';
const convert = query(CONVERT_CURRENCY, {
    skip(props) {
        return !props.convert;
    },
    options(props) {
        return {
            variables: {
                currency: props.currency,
                amount: props.amount,
                date: props.date?.valueOf()
            }
        };
    },
    props(props) {
        return {
            convertedLiveAmount: props.data?.convertCurrency || null
        };
    }
});
export default compose(withData({
    amount: { initialValue: (props) => props.value?.amount || null },
    currency: { initialValue: (props) => props.value?.currency || CurrencyType.USD }
}), withProps({
    convert: (props) => props.currency !== CurrencyType.USD &&
        props.amount &&
        (props.date?.valueOf() !== props.originalDate?.valueOf() ||
            (!props.originalValue && props.value !== null) ||
            (props.originalValue &&
                (props.value?.amount !== props.originalValue.amount ||
                    props.value?.currency !== props.originalValue.currency)))
}), convert, acceptProps(['date', 'originalValue', 'originalDate', 'showTooltip', 'isCurrencyChangeable', 'currency']))(AmountWithCurrencyField);
