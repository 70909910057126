import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import Loading from '@/shared/components/loading/Loading.vue';
import * as jwt from 'jsonwebtoken';
let RequestResponder = class RequestResponder extends Vue {
    resolveAccountingRequest;
    loading;
    error = false;
    tokenData = null;
    async mounted() {
        const query = window.location.search
            .substr(1)
            .split('&')
            .reduce((res, next) => {
            const arr = next.split('=');
            res[arr[0]] = arr[1];
            return res;
        }, {});
        this.tokenData = query.commandToken && jwt.decode(query.commandToken);
        if (!this.tokenData) {
            this.error = true;
        }
        if (!this.error && query.commandToken) {
            try {
                const res = await this.resolveAccountingRequest(query.commandToken);
                if (res.data.resolveAccountingPledgeRequest?.__typename === 'ErrorResponse') {
                    this.error = true;
                }
            }
            catch (e) {
                this.error = true;
            }
        }
    }
};
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], RequestResponder.prototype, "resolveAccountingRequest", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], RequestResponder.prototype, "loading", void 0);
RequestResponder = __decorate([
    Component({
        components: {
            Box,
            Loading
        }
    })
], RequestResponder);
export default RequestResponder;
