import Grid from '@/shared/ui/grid/grid.vue';
import { defineComponent, ref, watch, computed } from 'vue';
import UIDatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import NumberInput from '@/shared/ui/forms/number-input.vue';
import { GQLBillingPlanDistributionType } from '@velocity/shared/dist/gql-types';
import { createPlan } from '@velocity/shared/dist/billing-plan-helpers';
import Box from '@/shared/layout/box/layout-box.vue';
import Icon from '@/shared/components/icon/icon.vue';
import IconS from '@/shared/components/icon/icon--solid.vue';
import { dateToMonthDayYear } from '../../../../util';
export default defineComponent({
    components: {
        Grid,
        UIDatePicker,
        NumberInput,
        UIButton,
        Box,
        Icon,
        IconS
    },
    props: {
        distributionType: {
            type: String,
            required: true
        },
        interval: String,
        total: {
            type: Number,
            required: true
        },
        totalPaid: {
            type: Number,
            required: true
        },
        amount: Number,
        startDate: Object,
        value: Array
    },
    setup(props, { emit }) {
        const items = ref([]);
        const today = dateToMonthDayYear(new Date());
        watch(() => props.value, value => {
            if (value)
                items.value = value.map((x, i) => ({ ...x, row: i }));
        });
        const columns = [
            { field: 'Date', cell: 'datePicker' },
            { field: 'Amount', cell: 'amount' },
            { field: 'Charged', title: ' ', cell: 'charged', width: '45px' }
        ];
        function setItems(value) {
            if (value) {
                items.value = value.map((x, i) => ({
                    row: i,
                    ...x
                }));
            }
            else if (props.distributionType == GQLBillingPlanDistributionType.Custom) {
                items.value = [];
            }
            else {
                items.value = createPlan(props.distributionType, props.interval, props.amount, props.total - props.totalPaid, props.startDate).map((x, i) => ({
                    row: i,
                    ...x
                }));
            }
            // add initial installment for the partially paid pledge
            if (!value && props.totalPaid) {
                items.value.unshift({
                    row: 0,
                    date: { ...today, day: today.day - 1 },
                    amount: props.totalPaid,
                    paid: true
                });
                items.value.forEach((item, i) => {
                    item.row = 0;
                });
            }
            setTimeout(() => {
                emit('input', items.value);
            }, 0);
        }
        watch(() => [props.distributionType, props.interval, props.amount, props.total, props.startDate], () => {
            setItems();
        });
        setItems(props.value);
        function updateRow(row, value) {
            items.value[row] = value;
            emit('input', [...items.value]);
        }
        function removeRow(row) {
            items.value.splice(row, 1);
            items.value.forEach((item, i) => {
                item.row = 0;
            });
            emit('input', [...items.value]);
        }
        function addPayment() {
            emit('input', [...items.value, { row: items.value.length, date: null, amount: null }]);
        }
        return {
            columns: computed(() => props.distributionType == GQLBillingPlanDistributionType.Custom
                ? [...columns, { field: ' ', cell: 'removeRow' }]
                : columns),
            items,
            updateRow,
            addPayment,
            removeRow,
            today
        };
    }
});
