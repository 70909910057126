<template>
	<Box v-bind="$attrs">
		<Box tag="nav" class="nav" :paddingX="headerPaddingX" :paddingY="headerPaddingY">
			<Box class="inner-nav" display="flex">
				<slot name="nav"></slot>
			</Box>
		</Box>
		<Box tag="main">
			<slot name="body"></slot>
		</Box>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box.vue'

export default {
	name: 'Tabs',
	props: {
		headerPaddingX: {
			type: Number,
			default: 0
		},
		headerPaddingY: {
			type: Number,
			default: 0
		},
		bodyPadding: {
			type: Number,
			default: 5
		}
	},
	components: {
		Box
	},
	computed: {}
}
</script>

<style lang="scss" scoped>
.nav {
	border-bottom: 1px solid #efefef;
}
main {
	border-top: 1px solid white;
}

//for button group style nav
.btn-group .nav {
	border: none;
}
.btn-group .nav /deep/ .nav-item {
	border: 1px solid #ddd;
	border-left: none;
	background: white;
	font-size: var(--main-font-size);

	&:first-child {
		border-radius: 4px 0 0 4px;
		border-left: 1px solid #ddd;
	}
	&:last-child {
		border-radius: 0 4px 4px 0;
	}
	&:hover {
		background: #f7f7f7;
	}
	&.active {
		color: white;
		background: var(--main-blue);
		border-color: #ddd;
	}
}
.btn-group main {
	border: none;
}

//for left vertical nav
.v-nav {
	display: flex;
	.nav {
		display: block;
		flex: 1;
		border-bottom: none;
		border-right: 1px solid #efefef;
		background: #f7f7f7;
	}
	main {
		flex: 3;
		border-top: none;
	}

	/deep/ .nav-item.active {
		background: var(--main-blue);
		color: white;
		border: none;
	}
}

// for seconadry navigation
.sec-navigation .nav {
	margin: -22px -20px 30px;
	max-width: none;
	background: #e6e9ef;
	box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.1);

	.inner-nav {
		max-width: var(--max-screen-width);
		padding-left: 40px;
		padding-right: 40px;
		margin: auto;
	}
	/deep/ .nav-item {
		padding-top: 13px;
	}
	/deep/ .nav-item.active {
		font-weight: 600;
	}
}
</style>
