import PledgeSelector from './pledge-selector.vue';
import { compose, acceptProps } from 'vue-compose';
import withLoader from '../../loading/with-loader';
import query from '@/shared/graphql/query';
import LOAD_PLEDGES from './load-pledges.graphql';
import { CurrencyType } from '@/shared/schema/globalTypes';
import { dateScalarToDate } from '@velocity/shared/src/utils';
import { addWithRounding } from '@/shared/util';
function getOutstandingAmount(pledge) {
    if (pledge.currency === CurrencyType.USD) {
        return pledge.amount - pledge.totalPaid;
    }
    return addWithRounding([+pledge.originalAmount, -addWithRounding(pledge.applications.map(x => x.originalAmount))]);
}
const loadPledges = query(LOAD_PLEDGES, {
    options(props) {
        return {
            fetchPolicy: 'network-only',
            variables: {
                id: props.donorId
            }
        };
    },
    props(props) {
        return {
            pledges: props.data && props.data.donor
                ? props.data.donor.pledges
                    .filter(x => x.amount - x.totalPaid > 0)
                    .map(x => ({
                    id: x.id,
                    currency: x.currency,
                    fundraisers: x.fundraisers.map(fundraiser => ({
                        fundraiserID: fundraiser.fundraiser.firstName,
                        percent: fundraiser.percent
                    })),
                    campaign: x.campaign
                        ? {
                            id: x.campaign.id,
                            label: x.campaign.name
                        }
                        : null,
                    date: dateScalarToDate(x.date),
                    department: x.department,
                    notes: x.notes || '',
                    outstanding: getOutstandingAmount(x),
                    type: null,
                    tributeText: null,
                    grantId: null,
                    subCampaign: x.subCampaign
                        ? {
                            id: x.subCampaign.id,
                            label: x.subCampaign.name
                        }
                        : null
                }))
                : []
        };
    }
});
export default compose(loadPledges, withLoader(), acceptProps(['donorId', 'currency']))(PledgeSelector);
