import RequestResponder from './request-responder.vue';
import { compose } from 'vue-compose';
import mutate from '@/shared/graphql/mutate';
import RESOLVE_ACCOUNTING_REQUEST from './resolve-accounting-request.graphql';
const resolveAccountingRequest = mutate(RESOLVE_ACCOUNTING_REQUEST, {
    props(props) {
        return {
            loading: props.loading,
            resolveAccountingRequest(token) {
                return props.mutate({
                    variables: {
                        token
                    }
                });
            }
        };
    }
});
export default compose(resolveAccountingRequest)(RequestResponder);
