import { __decorate, __metadata } from "tslib";
import { Prop, Component } from 'vue-property-decorator';
import Vue from 'vue';
let SimpleSelect = class SimpleSelect extends Vue {
    value;
    get props() {
        return {
            ...this.$attrs,
            ...this.$props
        };
    }
    get listeners() {
        return {
            ...this.$listeners,
            input: (e) => {
                const option = e.target.selectedOptions[0];
                const baseInput = this.$listeners.input;
                baseInput(option._value !== undefined ? option._value : option.value);
            }
        };
    }
};
__decorate([
    Prop({ type: [String, Object, Number, Boolean] }),
    __metadata("design:type", Object)
], SimpleSelect.prototype, "value", void 0);
SimpleSelect = __decorate([
    Component({
        inheritAttrs: false
    })
], SimpleSelect);
export default SimpleSelect;
