import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import FormSection from '@/shared/ui/forms/form-section/form-section.vue';
import { TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, UITable } from '@/shared/ui/table';
import Card from '@/shared/ui/card/card.vue';
import { formatMoney } from '@/shared/util';
import PaymentForm from '@/shared/components/payment-form';
import EditPaymentForm from '@/shared/components/payment-edit-form';
import Box from '@/shared/layout/box/layout-box.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import uiInput from '@/shared/ui/forms/input/ui-input.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import uiLabel from '@/shared/ui/forms/label/ui-label.vue';
import UICheckbox from '@/shared/ui/forms/checkbox/checkbox.vue';
import { UserRole } from '@/shared/schema/globalTypes';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import Icon from '@/shared/components/icon/icon.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import SmallAuditView from './small-audit-view';
import { RouterLink, Route, Redirect } from 'vue-component-router';
import some from 'lodash/some';
let Payment = class Payment extends Vue {
    router;
    payments;
    pledgeAmount;
    pledgeId;
    donor;
    access_token;
    pledgeDepartment;
    pledgeCurrency;
    hasRole;
    canGenerateInvoicesAndReceipts;
    baseUrl;
    version = 0;
    addNewPayment = false;
    UserRole = UserRole;
    get showSidebar() {
        return this.payments.length > 0 && !(this.fullyPaid && this.payments.length == 1);
    }
    get fullyPaid() {
        return this.pledgeAmount == 0;
    }
    formatMoney(amount) {
        return formatMoney(amount);
    }
    get paymentFormProps() {
        return {
            pledgeAmount: this.pledgeAmount,
            pledgeId: this.pledgeId,
            pledgeCurrency: this.pledgeCurrency,
            pledgeDepartment: this.pledgeDepartment,
            donor: this.donor,
            hideDonor: true
        };
    }
    isPaymentExist(id) {
        return this.payments.length && some(this.payments, { id });
    }
    toggleEditForm() {
        this.addNewPayment = false;
        this.$emit('updated');
    }
    getReceiptLink(paymentId) {
        return process.env.VUE_APP_API.replace('/graphql', `/pdf/pledgeReceipt/${this.pledgeId}/${paymentId}?token=${this.access_token}`);
    }
};
__decorate([
    Inject('router'),
    __metadata("design:type", Object)
], Payment.prototype, "router", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], Payment.prototype, "payments", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], Payment.prototype, "pledgeAmount", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], Payment.prototype, "pledgeId", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], Payment.prototype, "donor", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], Payment.prototype, "access_token", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], Payment.prototype, "pledgeDepartment", void 0);
__decorate([
    Prop({ type: String, required: true }),
    __metadata("design:type", Object)
], Payment.prototype, "pledgeCurrency", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], Payment.prototype, "hasRole", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], Payment.prototype, "canGenerateInvoicesAndReceipts", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], Payment.prototype, "baseUrl", void 0);
Payment = __decorate([
    Component({
        components: {
            FormSection,
            SmallAuditView,
            UICheckbox,
            UISelect,
            layoutFormRow,
            uiInput,
            uiLabel,
            UITable,
            TableBody,
            layoutText,
            TableCell,
            TableContainer,
            TableHead,
            TableRow,
            TableHeader,
            Card,
            PaymentForm,
            EditPaymentForm,
            Box,
            UIButton,
            Icon,
            RouterLink,
            Route,
            Redirect
        }
    })
], Payment);
export default Payment;
