import GET_USERS from '@/shared/graphql/get-users.graphql';
import { when } from '@/shared/util';
import { useQuery, useResult } from '@vue/apollo-composable';
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import cloudinary from 'cloudinary-core';
import Quill from 'quill';
import 'quill-mention';
import './quill.snow.css';
import { SortOrder } from '@/shared/schema/globalTypes';
import 'quill-mention/dist/quill.mention.min.css';
let Delta = Quill.import('delta');
let Break = Quill.import('blots/break');
let Embed = Quill.import('blots/embed');
let Clipboard = Quill.import('modules/clipboard');
class SmartBreak extends Break {
    length() {
        return 1;
    }
    value() {
        return '\n';
    }
    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
    }
}
class PlainClipboard extends Clipboard {
    onPaste(e) {
        e.preventDefault();
        const range = this.quill.getSelection();
        const text = e.clipboardData.getData('text/plain');
        const delta = new Delta()
            .retain(range.index)
            .delete(range.length)
            .insert(text);
        const index = text.length + range.index;
        const length = 0;
        this.quill.updateContents(delta);
        this.quill.setSelection(index, length);
        this.quill.scrollIntoView();
    }
}
function lineBreakMatcher() {
    var newDelta = new Delta();
    newDelta.insert({ break: '' });
    return newDelta;
}
function useLoadUsers(enabled) {
    const { result, loading } = useQuery(GET_USERS, {
        filters: { active: true },
        sort: [{ name: 'name', order: SortOrder.ASC }]
    }, {
        enabled
    });
    const data = useResult(result, [], data => {
        return data.users.records.map(user => {
            return {
                id: user.id,
                value: `${user.firstName} ${user.lastName}`,
                userMentionId: user.id
            };
        });
    });
    async function suggestPeople(search) {
        await when(() => !loading.value);
        return data.value.filter(item => item.value.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
    return {
        suggestPeople
    };
}
export default defineComponent({
    props: {
        mentions: { type: Boolean, default: false },
        value: String,
        placeholder: String,
        toolbarExtras: { type: Array, default: () => [] }
    },
    setup(props, { emit }) {
        const toolbar = ref(null);
        const file = ref(null);
        const editor = ref(null);
        const editorRef = ref(null);
        const initialBlur = ref(true);
        watch(() => props.value, () => {
            if (!editor.value)
                return;
            if (editor.value.root.innerHTML !== props.value) {
                editor.value.pasteHTML(props.value || '');
            }
        });
        const { suggestPeople } = useLoadUsers(props.mentions);
        onMounted(() => {
            const handlers = {};
            props.toolbarExtras.forEach(extra => (handlers[extra.name] = (value) => extra.handler(editor.value, value)));
            const modules = {
                toolbar: {
                    container: toolbar.value,
                    handlers: {
                        ...handlers,
                        image: () => {
                            const $file = file.value;
                            $file.click();
                            $file.addEventListener('change', e => {
                                const files = e.target.files;
                                const fd = new FormData();
                                fd.append('file', files[0]);
                                fd.append('upload_preset', 'j8qejnjb');
                                const url = `https://api.cloudinary.com/v1_1/ouinternal/image/upload`;
                                axios
                                    .post(url, fd, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                })
                                    .then(({ data }) => {
                                    const { public_id: publicID, version } = data;
                                    const cl = cloudinary.Cloudinary.new({ cloud_name: 'ouinternal' });
                                    const selection = editor.value.getSelection(true);
                                    editor.value.insertEmbed(selection.index || 0, 'image', cl.image(publicID, { version }).src);
                                });
                            }, false);
                        }
                    }
                },
                clipboard: {
                    matchers: [['BR', lineBreakMatcher]]
                },
                keyboard: {
                    bindings: {
                        linebreak: {
                            key: 13,
                            shiftKey: true,
                            handler: function (range) {
                                // @ts-ignore
                                let currentLeaf = this.quill.getLeaf(range.index)[0];
                                // @ts-ignore
                                let nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                                // @ts-ignore
                                this.quill.insertEmbed(range.index, 'break', true, 'user');
                                // Insert a second break if:
                                // At the end of the editor, OR next leaf has a different parent (<p>)
                                if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                                    // @ts-ignore
                                    this.quill.insertEmbed(range.index, 'break', true, 'user');
                                }
                                // move the cursor forward
                                // @ts-ignore
                                this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                            }
                        }
                    }
                }
            };
            if (props.mentions)
                modules.mention = {
                    minChars: 3,
                    dataAttributes: ['userMentionId'],
                    source: async function (searchTerm, renderList) {
                        const matchedPeople = await suggestPeople(searchTerm);
                        renderList(matchedPeople);
                    }
                };
            SmartBreak.blotName = 'break';
            SmartBreak.tagName = 'BR';
            Quill.register(SmartBreak);
            Quill.register('modules/clipboard', PlainClipboard, true);
            editor.value = new Quill(editorRef.value, {
                placeholder: props.placeholder,
                theme: 'snow',
                modules
            });
            const value = () => {
                const value = editor.value.root.innerHTML;
                if (value === '<p><br></p>') {
                    return null;
                }
                else {
                    return value;
                }
            };
            editor.value.on('text-change', () => {
                emit('input', value());
            });
            editor.value.root.addEventListener('blur', () => {
                // when component mounts, it sends an blur event right away, so we want to ignore that.
                if (initialBlur.value) {
                    initialBlur.value = false;
                    return;
                }
                emit('blur', value());
            });
            props.value && editor.value.pasteHTML(props.value);
        });
        return {
            editor,
            toolbar,
            file,
            editorRef
        };
    }
});
