<template>
	<div v-bar>
		<div>
			<slot />
		</div>
	</div>
</template>
<script>
export default {
	name: 'ScrollablePanel'
}
</script>
<style>
.vb {
	height: 100%;
}
.vb > .vb-dragger {
	z-index: 5;
	width: 12px;
	right: -5px;
}

.vb > .vb-dragger > .vb-dragger-styler {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: rotate3d(0, 0, 0, 0);
	transform: rotate3d(0, 0, 0, 0);
	-webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
	transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
	background-color: rgba(0, 0, 0, 0);
	margin: 5px 5px 5px 0;
	border-radius: 20px;
	height: calc(100% - 10px);
	display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.2);
}

.vb:hover > .vb-dragger > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.1);
	height: 100%;
}
.vb > .vb-dragger:hover > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.2);
	height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.2);
	height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.2);
}

.main-sidebar .vb:hover > .vb-dragger > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.05);
}
.main-sidebar .vb > .vb-dragger:hover > .vb-dragger-styler {
	background-color: rgba(0, 0, 0, 0.08);
}
</style>
