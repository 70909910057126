import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, from, Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import VueApollo from 'vue-apollo';
import { getAuthStore } from './authentication';
import { notify } from './notifications';
const introspectionQueryResultData = require('../../fragmentTypes.json');
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});
const authStore = getAuthStore();
const authLink = setContext((_, { headers }) => {
    if (!authStore.user)
        return {
            headers
        };
    const authorization = `Bearer ${authStore.user.access_token}`;
    const emulate = authStore.emulatedUser && _.operationName !== 'GetCurrentUser';
    return {
        headers: {
            ...headers,
            authorization,
            ...(emulate ? { 'x-useroverride': authStore.emulatedUser } : {})
        }
    };
});
// try to fix graphql not showing in LR
//@ts-ignore
const fetcher = (...args) => {
    //@ts-ignore
    return window.fetch(...args);
};
const httpLink = createHttpLink({ uri: process.env.VUE_APP_API, fetch: fetcher });
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors[0].extensions;
        const projectionError = graphQLErrors.find(x => !x.extensions
            ? false
            : x.extensions.code == 'INTERNAL_SERVER_ERROR' &&
                x.extensions.exception &&
                x.extensions.exception.projection);
        const timeoutError = graphQLErrors.find(x => !x.extensions
            ? false
            : x.extensions.code == 'INTERNAL_SERVER_ERROR' &&
                x.extensions.exception &&
                x.extensions.exception.projections);
        if (projectionError) {
            notify('The request was processed succesfully, but the results may not show up right away.', 'error');
        }
        if (timeoutError) {
            notify('The request was processed succesfully, but there is a delay in updates appearing.', 'error');
        }
    }
    if (networkError)
        console.log(`[Network error]: ${networkError}`);
});
function getOperationType(query) {
    const definitionNode = query.definitions.filter(d => d.kind === 'OperationDefinition')[0];
    return definitionNode.operation;
}
const readOnlyLink = new ApolloLink((request, forward) => {
    if (true ||
        ['ablock@ou.org', 'dobkins@ou.org', 'ulianova@ou.org', 'vesnina@ou.org', 'VesninA@ou.org'].includes(authStore.user.profile.email))
        return forward(request);
    if (getOperationType(request.query) == 'mutation') {
        notify('We apologize but the system is in "read-only" mode right now.\nIf will hopefully be back online soon.');
        return Observable.of({
            errors: []
        });
    }
    else {
        return forward(request);
    }
});
export const client = new ApolloClient({
    link: from([readOnlyLink, errorLink, authLink, httpLink]),
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: true
});
export const provider = new VueApollo({
    defaultClient: client
});
export { VueApollo };
