import withLoader from '@/shared/components/loading/with-loader';
import LayoutText from '@/shared/layout/text/layout-text.vue';
import Card from '@/shared/ui/card/card.vue';
import Grid from '@/shared/ui/grid/grid.vue';
import Box from '@/shared/layout/box/layout-box.vue';
import DateRangePicker from '@/shared/ui/forms/date-picker/date-range-picker.vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed } from 'vue';
import { defineComponent, reactive } from 'vue';
import moment from 'moment';
import GET_REPORT from './get-engagement-report.graphql';
const GridWithLoader = withLoader({ overlay: true })(Grid);
const columns = [
    { field: 'name', title: 'Name' },
    { field: 'type', title: 'Type', width: '200px' },
    { field: 'date', title: 'Date', width: '200px', cell: 'date' },
    { field: 'email', title: 'Email', width: '200px' }
];
function initialRange() {
    return {
        start: new Date(),
        end: moment()
            .add(1, 'month')
            .toDate()
    };
}
export default defineComponent({
    components: {
        Card,
        LayoutText,
        Grid: GridWithLoader,
        Box,
        DateRangePicker
    },
    setup() {
        const state = reactive({
            offset: 0,
            limit: 20
        });
        const filters = reactive({
            dateRange: initialRange()
        });
        function onPageChange(page) {
            state.offset = page.skip;
            state.limit = page.take;
        }
        function setDateRange(range) {
            if (!range?.start)
                filters.dateRange = initialRange();
            else
                filters.dateRange = range;
        }
        const { loading, result } = useQuery(GET_REPORT, () => ({
            filters: {
                startDate: filters.dateRange.start.valueOf(),
                endDate: filters.dateRange.end.valueOf()
            }
        }), {
            enabled: !!filters.dateRange.start && !!filters.dateRange.end
        });
        function formatDate(v) {
            return moment(v).format('MMMM Do');
        }
        const events = useResult(result, [], data => data.engagementReport.events);
        const page = computed(() => {
            return {
                total: events.value.length,
                events: events.value.slice(state.offset, state.offset + state.limit)
            };
        });
        return {
            state,
            page,
            filters,
            columns,
            loading,
            setDateRange,
            onPageChange,
            formatDate
        };
    }
});
