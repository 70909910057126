import Box from '@/shared/layout/box/layout-box.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import uiLabel from '@/shared/ui/forms/label/ui-label.vue';
import FormSection from '@/shared/ui/forms/form-section/form-section.vue';
import Card from '@/shared/ui/card/card.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import UICheckBox from '@/shared/ui/forms/checkbox/checkbox.vue';
import CCSelector from '@/shared/components/cc-selector/cc-selector.vue';
import { RadioGroup, RadioGroupItem } from '@/shared/ui/forms/radio-group';
import DatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
import uiRadio from '@/shared/ui/forms/radio/radio.vue';
import BoxRadio from '@/shared/ui/forms/box-radio/box-radio.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import InstallmentSelector from './installment-selector.vue';
import { defineComponent, ref, computed, watch } from 'vue';
import { BillingPlanDistributionType } from '@/shared/schema/globalTypes';
import { formatMoney, dateToMonthDayYear, monthDayYearToDate } from '@/shared/util';
export default defineComponent({
    components: {
        Box,
        uiLabel,
        layoutFormRow,
        FormSection,
        CCSelector,
        Card,
        UISelect,
        DatePicker,
        UICheckBox,
        RadioGroup,
        RadioGroupItem,
        UIInput,
        uiRadio,
        BoxRadio,
        InstallmentSelector
    },
    props: {
        total: Number,
        totalPaid: Number,
        donor: Object,
        value: Object
    },
    setup(props, { emit }) {
        const interval = ref(props.value?.interval || null);
        const distributionType = ref(props.value?.distributionType || null);
        const amount = ref(props.value?.distributionAmount);
        const startDate = ref(props.value?.planStart || null);
        const plan = ref(props.value?.installments || null);
        const autoBill = ref(props.value?.autoBill || false);
        const cardOnFile = ref(props.value?.cardOnFile || null);
        const today = dateToMonthDayYear(new Date());
        const hasUnpaidInstallment = computed(() => plan.value && plan.value.some(x => x.date && monthDayYearToDate(x.date) < new Date() && !x.paid));
        const showSelector = computed(() => distributionType.value
            ? distributionType.value == BillingPlanDistributionType.Custom ||
                (interval.value && amount.value && startDate.value)
            : false);
        watch([plan, autoBill, cardOnFile], () => {
            if (!plan)
                return;
            const billingPlan = {
                distributionType: distributionType.value,
                distributionAmount: amount.value,
                interval: interval.value,
                installments: plan.value,
                planStart: startDate.value,
                autoBill: autoBill.value,
                cardOnFile: cardOnFile.value
            };
            emit('input', billingPlan);
            emit('blur', billingPlan);
        });
        const ccSelectorProps = computed(() => ({
            testMode: JSON.parse(process.env.VUE_APP_PAYMENT_TEST_MODE),
            donor: props.donor
        }));
        return {
            interval,
            distributionType,
            amount,
            startDate,
            showSelector,
            plan,
            formatMoney,
            autoBill,
            cardOnFile,
            ccSelectorProps,
            today,
            hasUnpaidInstallment
        };
    }
});
