var _a;
import { __decorate, __metadata } from "tslib";
import { ApolloError } from 'apollo-client';
import { Prop, Component } from 'vue-property-decorator';
import Vue from 'vue';
let LoadingError = class LoadingError extends Vue {
    error;
    get errorMsg() {
        return this.error.message;
    }
    get details() {
        let details = null;
        const networkError = this.error.networkError;
        if (networkError && networkError.result && networkError.result.errors) {
            details = networkError.result.errors.map((err) => err.message);
        }
        return details;
    }
};
__decorate([
    Prop(),
    __metadata("design:type", typeof (_a = typeof ApolloError !== "undefined" && ApolloError) === "function" ? _a : Object)
], LoadingError.prototype, "error", void 0);
LoadingError = __decorate([
    Component({})
], LoadingError);
export default LoadingError;
