import withLoader from '@/shared/components/loading/with-loader';
import graphql from '@/shared/graphql';
import { acceptProps, compose } from 'vue-compose';
import SmallPaymentAuditLog from './small-payment-audit-log.vue';
import GET_PAYMENT_AUDIT from './get-payment-audit.graphql';
import { dateScalarToDate } from '@velocity/shared/dist/utils';
const getLog = graphql(GET_PAYMENT_AUDIT, {
    skip(props) {
        return !props.paymentId;
    },
    options(props) {
        return {
            variables: {
                id: props.paymentId
            }
        };
    },
    // @ts-ignore
    props(props) {
        if (!props.data || props.loading || !props.data.payment) {
            return {
                first: null,
                tail: [],
                sliced: false
            };
        }
        const payment = props.data.payment;
        const tailSize = 2;
        let auditLog = payment.auditLog
            .map(item => ({
            ...item,
            type: item.__typename,
            date: dateScalarToDate(item.date)
        }))
            .sort((a, b) => a.date.valueOf() - b.date.valueOf());
        return {
            first: auditLog[0],
            tail: auditLog.slice(Math.max(-tailSize, 1 - auditLog.length)),
            sliced: auditLog.length > tailSize + 1
        };
    }
});
export default compose(getLog, withLoader(), acceptProps(['paymentId']))(SmallPaymentAuditLog);
