<template>
	<input
		:disabled="disabled"
		v-bind="props"
		v-on="listeners"
		:class="classes"
		autocomplete="off"
		data-lpignore="true"
	/>
</template>
<script>
export default {
	name: 'UIInput',
	props: {
		size: {
			type: [Number, String]
		},
		value: {
			type: [String, Number]
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			listeners: {
				...this.$listeners,
				input: e => this.$emit('input', e.target.value),
				change: e => this.$emit('change', e.target.value)
			}
		}
	},
	computed: {
		props() {
			return {
				...this.$attrs,
				...this.$props
			}
		},
		classes() {
			const classes = {}

			if (this.size) {
				classes[`size-${this.size}`] = true
			}

			return classes
		}
	}
}
</script>

<style lang="scss" scoped>
input {
	box-sizing: border-box;
	height: 36px;
	width: 100%;
	display: block;
	border: 1px solid var(--input-border);
	padding: 0 10px;
	border-radius: 4px;
	background: var(--input-bg);
	transition: all 0.3s ease-in-out;
	font-size: var(--main-font-size);
	color: #222;
	font-family: var(--font-main);

	&:hover {
		box-shadow: none;
		border-color: var(--main-blue);
	}

	&:focus {
		border-color: var(--main-blue);
		box-shadow: none;
		color: #222;
		background: white;
		box-shadow: 0 0 3px rgba(56, 93, 163, 0.5);
	}

	&.white-bg {
		background: white;
	}

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
		border: 1px solid #e4e4e4;
		background: #f3f2f2;
	}
}

.size-small {
	height: 28px;
	font-size: 12px;
}

.size-default {
	height: 36px;
}

.size-large {
	height: 42px;
}

input::-webkit-input-placeholder {
	color: #ccc;
}
input::-moz-placeholder {
	color: #ccc;
}
input:-ms-input-placeholder {
	color: #ccc;
}
</style>
