import { __decorate, __metadata } from "tslib";
import FancySelect from '@/shared/ui/forms/fancy-select';
import { Vue, Component, Prop } from 'vue-property-decorator';
import layoutText from '@/shared/layout/text/layout-text.vue';
let BatchLookup = class BatchLookup extends Vue {
    batches;
    value;
    loading;
    disabled;
    onSearch(value) {
        this.$emit('search', value);
    }
    mounted() {
        if (typeof this.value === 'string') {
            const value = this.value;
            this.$emit('input', null);
            const unwatch = this.$watch('batches', () => {
                if (this.batches.length === 0)
                    return;
                unwatch();
                const batch = this.batches.find(x => x.label == value || x.id == value);
                this.$emit('input', batch || null);
            });
        }
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], BatchLookup.prototype, "batches", void 0);
__decorate([
    Prop({ type: [Object, String] }),
    __metadata("design:type", Object)
], BatchLookup.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], BatchLookup.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], BatchLookup.prototype, "disabled", void 0);
BatchLookup = __decorate([
    Component({
        components: {
            FancySelect,
            layoutText
        }
    })
], BatchLookup);
export default BatchLookup;
