import { __decorate, __metadata } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import Icon from '@/shared/components/icon/icon.vue';
import Card from '@/shared/ui/card/card.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import NumberInput from '@/shared/ui/forms/number-input.vue';
import DepartmentSelector from '@/shared/components/department-selector';
import CampaignSelector from '@/shared/components/campaign-lookup';
import SubCampaignSelector from '@/shared/components/subcampaign-lookup';
import UITextarea from '@/shared/ui/forms/textarea/ui-textarea.vue';
import { formatDate, formatMoney } from '@/shared/util';
import DatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
import { dateToMonthDayYear } from '@/shared/util';
import Fundraisers from '@/shared/components/fundraisers';
import LayoutDivider from '@/shared/layout/divider/layout-divider.vue';
import { PledgeCategory } from '@/shared/schema/globalTypes';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import GrantLookup from '@/shared/components/grant-lookup';
import startCase from 'lodash/startCase';
let SinglePledge = class SinglePledge extends Vue {
    pledge;
    donorId;
    open = false;
    formatMoney = formatMoney;
    formatDate = formatDate;
    dateToMonthDayYear = dateToMonthDayYear;
    PledgeCategory = PledgeCategory;
    pledgeCategories = Object.entries(PledgeCategory).map(([key]) => ({
        value: key,
        label: startCase(key)
    }));
    get outstandingAmount() {
        if (this.pledge.id) {
            return this.pledge.outstanding ? this.pledge.outstanding : 0;
        }
        return this.pledge.amount ? this.pledge.amount : 0;
    }
    mounted() {
        this.open = this.pledge.id ? false : true;
    }
};
__decorate([
    Prop({ required: true, type: Object }),
    __metadata("design:type", Object)
], SinglePledge.prototype, "pledge", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], SinglePledge.prototype, "donorId", void 0);
SinglePledge = __decorate([
    Component({
        components: {
            Box,
            Icon,
            Card,
            layoutText,
            layoutFormRow,
            UILabel,
            UIInput,
            UITextarea,
            DepartmentSelector,
            CampaignSelector,
            SubCampaignSelector,
            NumberInput,
            DatePicker,
            Fundraisers,
            LayoutDivider,
            UISelect,
            GrantLookup
        }
    })
], SinglePledge);
export default SinglePledge;
