<template>
	<label class="radio" :class="color">
		<input :checked="checked" type="radio" :name="name" @click="set" />
		<span class="check"></span>
		<span class="text">{{ labelText }}</span>
	</label>
</template>

<script>
export default {
	name: 'UIRadio',
	component: {},
	props: {
		color: String,
		set: Function,
		checked: Boolean,
		name: String,
		labelText: String
	}
}
</script>

<style lang="scss" scoped>
.radio {
	display: flex;
	cursor: pointer;
	margin-bottom: 5px;
	margin-right: 15px;
}
.radio input {
	position: absolute;
	left: -9999px;
	opacity: 0;
	visibility: hidden;
}
.text {
	font-weight: 400;
	color: #333;
	line-height: 20px;
	font-size: var(--main-font-size);
}
.check {
	display: inline-block;
	width: 20px;
	height: 20px;
	min-width: 20px;
	border: 1px solid #ddd;
	background: #fcfcfc;
	color: transparent;
	margin-right: 5px;
	border-radius: 50%;
	position: relative;
	transition: all 0.1s ease-in-out;
}
.radio:hover .check {
	border: 1px solid #ccc;
}
.check:before {
	content: '';
	position: absolute;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	top: 4px;
	left: 4px;
	font-size: 14px;
	transition: all 0.1s ease-in-out;
}
.radio input:checked ~ .check {
	border: 1px solid #29ce5d;
	color: white;
	background: white;
}
.radio input:focus ~ .check {
	box-shadow: 0 0 10px #93cea6;
}
.radio input:checked ~ .check:before {
	background: #29ce5d;
}
</style>
