var _a;
import { __decorate, __metadata } from "tslib";
import '@progress/kendo-theme-default/dist/all.css';
import Loading from '@/shared/components/loading/Loading.vue';
import hash from 'object-hash';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import layoutText from '@/shared/layout/text/layout-text.vue';
import Box from '@/shared/layout/box/layout-box.vue';
const Grid = require('@progress/kendo-vue-grid/dist/es/Grid');
const GridNoRecords = require('@progress/kendo-vue-grid/dist/es/GridNoRecords');
const GridToolbar = require('@progress/kendo-vue-grid/dist/es/GridToolbar');
let UIGrid = class UIGrid extends Vue {
    loading;
    items;
    /** Can be reset by passing an empty array
     * Also, it emits selectedItems event on selection change */
    selectedItems;
    /** By default multiple rows can be selected and highlighted */
    multiselect;
    columns;
    noRecordsMsg;
    scrollable;
    detail;
    addNew;
    toolbarHeader;
    sortable;
    sort;
    pageable;
    pageSize;
    page;
    total;
    skip;
    hasDetails;
    groupable;
    group;
    customCell;
    highlightOnHover;
    highlightOnSelect;
    rows = this.getRows(this.items || []);
    _selectedItems = cloneDeep(this.selectedItems);
    get isRowClickable() {
        return 'rowclick' in this.$listeners || 'selectedItemsChange' in this.$listeners;
    }
    mounted() {
        this._selectedItems = [];
        this.rows = this.getRows(this.items);
    }
    getSkip() {
        return this.skip !== undefined ? this.skip : this.page * this.pageSize;
    }
    watchItems(items) {
        this.rows = this.getRows(items);
        this._selectedItems = [];
        this.$emit('selectedItemsChange', this._selectedItems);
        this.updateSelectedCssClasses();
    }
    onRowClick({ dataItem }) {
        this.updateSelectedItems(dataItem);
        this.$emit('selectedItemsChange', this._selectedItems);
        this.updateSelectedCssClasses();
        if (this.$listeners.rowclick)
            this.$listeners.rowclick(dataItem);
    }
    onParentSelectedItemsChange() {
        this._selectedItems = cloneDeep(this.selectedItems);
        this.updateSelectedCssClasses();
    }
    updateSelectedCssClasses() {
        const grid = this.$refs['grid'];
        const kendoGridRows = [...grid.$children].filter((i) => !!i.dataItem);
        kendoGridRows.forEach(row => {
            row.$el?.classList?.remove('selected');
        });
        if (!this._selectedItems || this._selectedItems.length === 0) {
            return;
        }
        const allItems = 'data' in this.rows ? this.rows.data : this.rows;
        const allItemsHashes = allItems.map(item => hash(item));
        const selectedItemsIndices = this._selectedItems.map(selectedItem => allItemsHashes.findIndex(itemHash => itemHash === hash(selectedItem)));
        kendoGridRows.forEach((row, index) => {
            if (selectedItemsIndices.includes(index)) {
                row.$el.classList.add('selected');
            }
        });
    }
    updateSelectedItems(item) {
        const currentItemHash = hash(item);
        const selectedItemIndex = this._selectedItems.findIndex(item => hash(item) === currentItemHash);
        if (this.multiselect) {
            if (selectedItemIndex !== -1) {
                this._selectedItems.splice(selectedItemIndex, 1);
                return;
            }
            this._selectedItems.push(item);
            return;
        }
        if (this._selectedItems.length === 0 || selectedItemIndex === -1) {
            this._selectedItems = [item];
            return;
        }
        this._selectedItems = [];
    }
    onPageChange({ page }) {
        if (this.$listeners.pagechange)
            this.$listeners.pagechange(page);
    }
    onSortChange({ sort }) {
        if (this.$listeners.sortchange)
            this.$listeners.sortchange(sort);
    }
    onSelectionChange(event) {
        event.dataItem.selected = !event.dataItem.selected;
        if (this.$listeners.selectionchange)
            this.$listeners.selectionchange(event.dataItem);
    }
    onHeaderSelectionChange(event) {
        let checked = event.event.target.checked;
        event.target.dataItems.forEach((d) => (d.selected = checked));
        if (this.$listeners.headerselectionchange)
            this.$listeners.headerselectionchange(checked);
    }
    getRows(items) {
        if (items && 'data' in items) {
            return {
                total: items.total,
                data: items.data.map(item => ({
                    $expanded: true,
                    ...item
                }))
            };
        }
        return (items || []).map(item => ({
            $expanded: false,
            ...item
        }));
    }
    expandChange(event) {
        event.dataItem['$expanded'] = !event.dataItem['$expanded'];
    }
    cellRender(h, defaultRender, props) {
        if (props.field !== '$expanded')
            return defaultRender;
        if (this.hasDetails && !this.hasDetails(props.dataItem))
            defaultRender.children = [];
        return defaultRender;
    }
};
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], UIGrid.prototype, "loading", void 0);
__decorate([
    Prop({ type: [Array, Object] }),
    __metadata("design:type", Array)
], UIGrid.prototype, "items", void 0);
__decorate([
    Prop({ type: Array, default: () => [] }),
    __metadata("design:type", Array)
], UIGrid.prototype, "selectedItems", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Boolean)
], UIGrid.prototype, "multiselect", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Array)
], UIGrid.prototype, "columns", void 0);
__decorate([
    Prop({ type: String, default: 'No records' }),
    __metadata("design:type", String)
], UIGrid.prototype, "noRecordsMsg", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", typeof (_a = typeof String !== "undefined" && String) === "function" ? _a : Object)
], UIGrid.prototype, "scrollable", void 0);
__decorate([
    Prop({ type: [String, Function, Object] }),
    __metadata("design:type", Object)
], UIGrid.prototype, "detail", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], UIGrid.prototype, "addNew", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], UIGrid.prototype, "toolbarHeader", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], UIGrid.prototype, "sortable", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], UIGrid.prototype, "sort", void 0);
__decorate([
    Prop({ type: [Boolean, Object] }),
    __metadata("design:type", Object)
], UIGrid.prototype, "pageable", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Number)
], UIGrid.prototype, "pageSize", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Number)
], UIGrid.prototype, "page", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Number)
], UIGrid.prototype, "total", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], UIGrid.prototype, "skip", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Function)
], UIGrid.prototype, "hasDetails", void 0);
__decorate([
    Prop({ type: [Boolean, Object] }),
    __metadata("design:type", Boolean)
], UIGrid.prototype, "groupable", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], UIGrid.prototype, "group", void 0);
__decorate([
    Prop({ type: [String, Object] }),
    __metadata("design:type", String)
], UIGrid.prototype, "customCell", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], UIGrid.prototype, "highlightOnHover", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], UIGrid.prototype, "highlightOnSelect", void 0);
__decorate([
    Watch('items'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], UIGrid.prototype, "watchItems", null);
__decorate([
    Watch('selectedItems'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UIGrid.prototype, "onParentSelectedItemsChange", null);
UIGrid = __decorate([
    Component({
        components: {
            Loading,
            Grid: Grid.Grid,
            Box,
            GridNoRecords: GridNoRecords.GridNoRecords,
            GridToolbar: GridToolbar.GridToolbar,
            layoutText
        }
    })
], UIGrid);
export default UIGrid;
