import { useCurrentUser } from '@/shared/composables/useCurrentUser';
import Box from '@/shared/layout/box/layout-box.vue';
import { Department } from '@/shared/schema/globalTypes';
import FancySelect from '@/shared/ui/forms/fancy-select';
import { isDepartmentGroup, resolveDepartments } from '@/shared/util';
import { DepartmentGroups } from '@velocity/shared/src/departments';
import { onMounted } from 'vue';
import { computed, defineComponent } from 'vue';
import startCase from 'lodash/startCase';
export default defineComponent({
    components: {
        FancySelect,
        Box
    },
    props: {
        value: [String, Array],
        withGroups: { type: Boolean, required: false, default: false },
        resetAfter: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
        groupsOnly: { type: Boolean, required: false, default: false },
        defaultToUserDepartment: { type: Boolean, required: false, default: false },
        limitToUserDepartment: { type: Boolean, required: false, default: false },
        placeholder: String
    },
    setup(props, { emit }) {
        const { user } = useCurrentUser();
        const departments = computed(() => {
            if (props.groupsOnly)
                return DepartmentGroups;
            if (props.withGroups)
                return [...Object.keys(Department)];
            return Object.keys(Department).filter(d => !isDepartmentGroup(d));
        });
        const departmentOptions = computed(() => departments.value
            .filter(dep => {
            const userDeps = user?.departments || [];
            return props.limitToUserDepartment
                ? [...userDeps, ...resolveDepartments(userDeps)].includes(dep)
                : true;
        })
            .map(key => ({
            id: key,
            label: startCase(key)
        })));
        const currentValue = computed(() => {
            if (Array.isArray(props.value))
                return props.value.map(dep => departmentOptions.value.find(op => op.id === dep));
            return departmentOptions.value.find(dep => dep.id === props.value);
        });
        onMounted(() => {
            if (!props.value) {
                if (props.defaultToUserDepartment && user.departments[0] != Department.OU) {
                    emit('input', user.departments[0]);
                }
                return;
            }
            if (props.value && !props.multiple) {
                const index = departments.value.findIndex(x => x === props.value);
                if (index == -1)
                    emit('input', null);
            }
            if (props.value && props.multiple) {
                const valid = departments.value.filter(dep => props.value?.includes(dep));
                if (valid.length !== props.value.length)
                    emit('input', valid);
            }
        });
        function onInput(input) {
            if (input) {
                if (Array.isArray(input)) {
                    emit('input', input.map(item => item.id));
                }
                else {
                    emit('input', input.id);
                }
            }
            else {
                emit('input', null);
            }
        }
        return {
            departments,
            departmentOptions,
            currentValue,
            onInput
        };
    }
});
