import { __decorate, __metadata } from "tslib";
import FancySelect from '@/shared/ui/forms/fancy-select';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { formatMoney } from '@/shared/util';
let GrantLookup = class GrantLookup extends Vue {
    formatMoney = formatMoney;
    value;
    grants;
    loading;
    clearable;
};
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], GrantLookup.prototype, "value", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], GrantLookup.prototype, "grants", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], GrantLookup.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], GrantLookup.prototype, "clearable", void 0);
GrantLookup = __decorate([
    Component({
        components: {
            FancySelect,
            layoutText
        }
    })
], GrantLookup);
export default GrantLookup;
