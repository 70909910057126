import { __decorate, __metadata } from "tslib";
import FancySelect from '@/shared/ui/forms/fancy-select';
import { Vue, Component, Prop } from 'vue-property-decorator';
let CampaignLookup = class CampaignLookup extends Vue {
    campaigns;
    value;
    loading;
    multiple;
    department;
    forceLoad;
    includeSubCampaigns;
    get selectValue() {
        if (typeof this.value === 'string')
            return null;
        return this.value;
    }
    mounted() {
        if (this.multiple && Array.isArray(this.value) && typeof this.value[0] === 'string') {
            const value = this.value.slice();
            const unwatch = this.$watch('campaigns', () => {
                if (this.campaigns.length == 0)
                    return;
                unwatch();
                const campaigns = value
                    .map(value => this.campaigns.find(x => x.label == value || x.id == value))
                    .filter(Boolean);
                this.$emit('input', campaigns);
            });
        }
        else if (typeof this.value === 'string') {
            const value = this.value;
            const unwatch = this.$watch('campaigns', () => {
                if (this.campaigns.length == 0)
                    return;
                unwatch();
                const campaign = this.campaigns.find(x => x.label == value || x.id == value);
                this.$emit('input', campaign || null);
            });
        }
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "campaigns", void 0);
__decorate([
    Prop({ type: [Object, String, Array] }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "multiple", void 0);
__decorate([
    Prop({ type: [String, Array] }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "department", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "forceLoad", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], CampaignLookup.prototype, "includeSubCampaigns", void 0);
CampaignLookup = __decorate([
    Component({
        components: {
            FancySelect
        }
    })
], CampaignLookup);
export default CampaignLookup;
