import { client, provider, VueApollo } from '@/shared/apollo-provider';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import '@voerro/vue-tagsinput/dist/style.css';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { h, provide } from 'vue';
import LogRocket from 'logrocket';
import 'reflect-metadata';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import JsonCSV from './shared/components/vue-json-csv/JsonCSV.vue';
import JsonExcel from 'vue-json-excel';
import VueTimeago from 'vue-timeago';
import Vuebar from 'vuebar';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import App from './App.vue';
import './assets/css/main.css';
import { getAuthStore } from './shared/authentication';
if (process.env.VUE_APP_ENABLE_LOGROCKET) {
    LogRocket.init('orthodox-union/ou-velocity', {
        release: process.env.NODE_ENV
    });
}
// if (process.env.VUE_APP_INTERCOM_APP_ID) {
// 	;(function() {
// 		var w = window as any
// 		var ic = w.Intercom
// 		if (typeof ic === 'function') {
// 			ic('reattach_activator')
// 			ic('update', w.intercomSettings)
// 		} else {
// 			var d = document
// 			var i = function() {
// 				i.c(arguments)
// 			} as any
// 			i.q = []
// 			i.c = function(args: any) {
// 				i.q.push(args)
// 			}
// 			w.Intercom = i
// 			var l = function() {
// 				var s = d.createElement('script')
// 				s.type = 'text/javascript'
// 				s.async = true
// 				s.src = 'https://widget.intercom.io/widget/' + process.env.VUE_APP_INTERCOM_APP_ID
// 				var x = d.getElementsByTagName('script')[0]
// 				x.parentNode!.insertBefore(s, x)
// 			}
// 			if (document.readyState === 'complete') {
// 				l()
// 			} else if (w.attachEvent) {
// 				w.attachEvent('onload', l)
// 			} else {
// 				w.addEventListener('load', l, false)
// 			}
// 		}
// 	})()
// }
if (process.env.VUE_APP_ENABLE_SENTRY) {
    const dsn = process.env.NODE_ENV === 'production'
        ? 'https://61c809b1b7f541bfa38eedb11acdcf40@sentry.oustatic.com/9'
        : 'https://4466676afa034e87a4ac9c3b4ffaf27c@sentry.oudev.org/12';
    Sentry.init({
        dsn,
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['localhost:3000', 'velocity2.ouapis.org']
            }),
            new VueIntegration({
                Vue,
                attachProps: true,
                logErrors: true,
                tracing: true,
                tracingOptions: { trackComponents: true }
            })
        ]
        // beforeSend(event) {
        // 	const logRocketSession = LogRocket.sessionURL
        // 	if (logRocketSession !== null && event.extra) {
        // 		event.extra['LogRocket'] = logRocketSession
        // 		return event
        // 	} else {
        // 		return event
        // 	}
        // }
    });
    Sentry.setTag('application', 'ui');
    if (process.env.VUE_APP_ENABLE_LOGROCKET) {
        LogRocket.getSessionURL(sessionURL => {
            console.log('---> logs <---');
            Sentry.addBreadcrumb({
                category: 'message',
                message: `LogRocket url: ${sessionURL}`,
                level: Sentry.Severity.Info
            });
            Sentry.configureScope(scope => {
                scope.setExtra('sessionURL', sessionURL);
            });
        });
    }
}
Vue.component('tags-input', VoerroTagsInput);
Vue.component('downloadCsv', JsonCSV);
Vue.component('downloadExcel', JsonExcel);
Vue.use(Vuebar);
Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(VueTimeago);
Vue.use(VTooltip);
Vue.use(VuejsDialog);
new Vue({
    setup() {
        provide(DefaultApolloClient, client);
        return () => h(App);
    },
    provide: {
        ...provider.provide(),
        $authStore: getAuthStore()
    }
}).$mount('#app');
