import GetSignedUrl from '@/shared/components/get-signed-url/get-signed-url.vue';
import Icon from '@/shared/components/icon/icon.vue';
import { useGetTemplate, useGetTemplateNames, useSaveTemplate } from './data';
import Loading from '@/shared/components/loading/Loading.vue';
import UserLookup from '@/shared/components/user-lookup';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { MailMergeTemplateType, SalutationType } from '@/shared/schema/globalTypes';
import UIButton from '@/shared/ui/button/ui-button.vue';
import Card from '@/shared/ui/card/card.vue';
import FancySelect from '@/shared/ui/forms/fancy-select';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import UITextArea from '@/shared/ui/forms/textarea/ui-textarea.vue';
import WysiwygEditor from '@/shared/ui/forms/wysiwyg/WysiwygEditor.vue';
import S3Uploader from '@/shared/ui/s3uploader';
import { TableBody, TableCell, TableHead, TableHeader, TableRow, UITable } from '@/shared/ui/table';
import { computed, defineComponent, reactive } from 'vue';
import { startCase } from 'lodash';
import UIRadio from '@/shared/ui/forms/radio/radio.vue';
import { RadioGroup, RadioGroupItem } from '@/shared/ui/forms/radio-group';
import UICheckbox from '@/shared/ui/forms/checkbox/checkbox.vue';
const toolbarExtras = [
    {
        name: 'variables',
        label: 'Variables',
        handler: (editor, value) => {
            if (value === 'none')
                return;
            const selection = editor.getSelection(true);
            editor.deleteText(selection.index, selection.length);
            editor.insertText(selection.index, value);
            const picker = editor.theme.pickers[0];
            picker.selectItem(picker.options.childNodes[0]);
        },
        options: [
            { title: 'Insert Value', value: 'none' },
            { title: 'Donor Name', value: '{{donorName}}' },
            { title: 'Salutation', value: '{{salutation}}' },
            { title: 'Salutation without last name', value: '{{salutationWOLastName}}' },
            { title: 'Fundraiser name', value: '{{fundraiser.firstName}} {{fundraiser.lastName}}' },
            { title: 'Fundraiser title', value: '{{fundraiser.title}}' },
            { title: 'Fundraiser dig. Signature', value: '{{{fundraiserSignatureTag}}}' },
            { title: 'Account id', value: '{{accountId}}' },
            { title: 'Fund', value: '{{fund}}' },
            { title: 'Pledge Date', value: '{{pledgeDate}}' },
            { title: 'Pledge Amount', value: '{{pledgeAmount}}' },
            { title: 'Paid Amount', value: '{{paidAmount}}' },
            { title: 'Balance', value: '{{balance}}' },
            { title: 'Amount Due', value: '{{amountDue}}' }
        ]
    }
];
export default defineComponent({
    components: {
        UIInput,
        layoutFormRow,
        FancySelect,
        Loading,
        UserLookup,
        UILabel,
        Box,
        Icon,
        Card,
        layoutText,
        UITextArea,
        WysiwygEditor,
        UIButton,
        UITable,
        TableBody,
        TableCell,
        TableRow,
        TableHead,
        TableHeader,
        S3Uploader,
        GetSignedUrl,
        UIRadio,
        RadioGroup,
        RadioGroupItem,
        UICheckbox
    },
    props: {
        campaignId: String,
        pledgeId: String,
        department: String,
        showFundraiser: Boolean
    },
    setup(props, { emit }) {
        const { saveTemplate } = useSaveTemplate(props);
        const { template, loading: templateLoading, onLoaded } = useGetTemplate(props);
        const { names, loading: namesLoading, onLoaded: onNamesLoaded } = useGetTemplateNames();
        const loading = computed(() => templateLoading.value || namesLoading.value);
        const nameOptions = computed(() => {
            return names.value
                ?.find(item => item.type === 'Receipt')
                ?.names.map(name => ({ id: name, label: startCase(name) }));
        });
        const state = reactive({
            body: null,
            firstLine: null,
            footerLine: null,
            templateNameValue: null,
            fundraiserId: null,
            secondFundraiserId: null,
            thirdFundraiserId: null,
            logoUrl: null,
            logoFile: {},
            salutationType: null,
            defaultTemplateName: null,
            emailText: null,
            removeSignature: false
        });
        function setDefaultTemplate() {
            if (!names.value?.length && !template.value)
                return;
            const typeNames = names.value?.find(item => item.type === MailMergeTemplateType.Invoice)?.names;
            const defaultName = typeNames?.[0];
            state.defaultTemplateName = typeNames?.[0] || null;
            state.templateNameValue =
                (template.value?.templateName
                    ? nameOptions.value?.find(item => item.id === template.value?.templateName)
                    : nameOptions.value?.find(item => item.id === defaultName)) || null;
        }
        onLoaded(res => {
            const data = res?.data?.getMailMergeTemplate;
            if (!data)
                return;
            setDefaultTemplate();
            state.logoFile = data.logoUrl ? { file: data.logoUrl } : {};
            state.body = data.body;
            state.logoUrl = data.logoUrl;
            state.firstLine = data.firstLine;
            state.footerLine = data.footerLine;
            state.fundraiserId = data.fundraiser?.id || null;
            state.secondFundraiserId = data.secondFundraiser?.id || null;
            state.thirdFundraiserId = data.thirdFundraiser?.id || null;
            state.salutationType = data.salutationType;
            state.emailText = data.emailText;
            state.removeSignature = data.removeSignature || false;
        });
        onNamesLoaded(res => {
            const data = res?.data?.getMailMergeTemplateNames;
            if (!data)
                return;
            setDefaultTemplate();
        });
        const dirty = computed(() => {
            let value = template.value;
            if (!value)
                return (!!state.body ||
                    !!state.firstLine ||
                    !!state.fundraiserId ||
                    !!state.logoUrl ||
                    !!state.footerLine ||
                    !!state.salutationType ||
                    !!state.emailText ||
                    (state.templateNameValue && state.templateNameValue.id !== state.defaultTemplateName));
            const fundraiserId = value.fundraiser?.id || null;
            const secondFundraiserId = value.secondFundraiser?.id || null;
            const thirdFundraiserId = value.thirdFundraiser?.id || null;
            if (value.body !== state.body)
                return true;
            if (value.emailText !== state.emailText)
                return true;
            if (value.firstLine !== state.firstLine)
                return true;
            if (fundraiserId !== state.fundraiserId)
                return true;
            if (secondFundraiserId !== state.secondFundraiserId)
                return true;
            if (thirdFundraiserId !== state.thirdFundraiserId)
                return true;
            if (value.logoUrl !== state.logoUrl)
                return true;
            if (value.footerLine !== state.footerLine)
                return true;
            if (value.salutationType !== state.salutationType)
                return true;
            if (value.templateName !== state.templateNameValue?.id)
                return true;
            if (value.removeSignature !== state.removeSignature)
                return true;
            return false;
        });
        async function handleFundraiserChange(user) {
            state.fundraiserId = user ? user.id : null;
            emit('fundraiserId', user ? user.id : null);
        }
        async function handleSecondFundraiserChange(user) {
            state.secondFundraiserId = user ? user.id : null;
            emit('secondFundraiserId', user ? user.id : null);
        }
        async function handleThirdFundraiserChange(user) {
            state.thirdFundraiserId = user ? user.id : null;
            emit('thirdFundraiserId', user ? user.id : null);
        }
        function onLogoChanged(file) {
            if (state.logoFile?.status !== 'uploaded' && file.status === 'uploaded') {
                state.logoUrl = file.file;
            }
            state.logoFile = file;
        }
        const salutations = Object.keys(SalutationType).map(x => ({
            value: x,
            label: startCase(x)
        }));
        return {
            state,
            nameOptions,
            loading,
            handleFundraiserChange,
            handleSecondFundraiserChange,
            handleThirdFundraiserChange,
            onLogoChanged,
            toolbarExtras,
            saveTemplate: () => saveTemplate({ ...state, templateName: state.templateNameValue.id }),
            dirty,
            salutations
        };
    }
});
