<template>
	<div class="popover-container" :class="{ open: localShow, 'align-right': alignRight, 'align-left': alignLeft }">
		<span class="trigger" @click="handleTriggerClick" v-if="hasTriggerSlot">
			<slot name="trigger"></slot>
		</span>
		<FontAwesomeIcon :icon="['far', 'plus-circle']" v-else @click="handleTriggerClick"></FontAwesomeIcon>
		<div
			class="popover"
			:style="{ width: width, left: posX + 'px', top: posY + 'px' }"
			v-if="localShow"
			v-on-clickaway="handleTriggerClick"
			@click="handleTriggerClick"
		>
			<slot name="content"></slot>
		</div>
	</div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway2'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'

library.add(faPlusCircle)

// todo: make it work inside overflow:hidden blocks

export default {
	name: 'Popover',
	data() {
		return {
			localShow: this.$vnode.componentOptions.propsData.show !== undefined ? this.show : false,
			posY: null,
			posX: null
		}
	},
	watch: {
		show() {
			this.localShow = this.show
		}
	},
	components: {
		FontAwesomeIcon
	},
	directives: {
		onClickaway: onClickaway
	},
	props: {
		show: {
			type: Boolean
		},
		width: {
			default: '100px',
			type: String
		},
		alignRight: {
			type: Boolean,
			default: false
		},
		alignLeft: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		marginLeft() {
			if (!this.alignLeft) {
				return 0 - parseInt(this.width) / 2
			} else {
				return 0
			}
		},
		hasTriggerSlot() {
			return !!this.$slots.trigger
		}
	},
	methods: {
		handleTriggerClick(event) {
			this.posX = event.pageX - this.width.slice(0, -2) / 2
			this.posY = event.pageY

			if (this.$vnode.componentOptions.propsData.show !== undefined) {
				this.$emit('triggerClick')
			} else {
				this.localShow = !this.localShow
			}
		}
	}
}
</script>
<style scoped lang="scss">
.popover-container {
	position: relative;
	display: inline-block;
	width: 100%;
	line-height: 1;
}
.fa-plus-circle {
	color: #999;
	font-size: 28px;
	cursor: pointer;
	border: 3px solid transparent;
	border-radius: 50%;

	&:hover {
		border-color: #efefef;
	}
}
.popover {
	line-height: 1.6;
	position: fixed;
	min-width: 100px;
	background: white;
	box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
	padding: 10px 20px;
	margin-top: 15px;
	border: 1px solid #efefef;
	border-radius: 4px;
	z-index: 100;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -10px;
		border: 10px solid transparent;
		border-bottom-color: white;
		top: -20px;
	}
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -10px;
		border: 10px solid transparent;
		border-bottom-color: #efefef;
		top: -21px;
	}
}

.align-right .popover {
	margin-left: -43px !important;

	&:after,
	&:before {
		left: auto;
		right: 6px;
	}
}
.align-left .popover {
	right: auto;
	left: -12px;
	margin-right: 0 !important;

	&:after,
	&:before {
		right: auto;
		left: 26px;
	}
}
.trigger {
	cursor: pointer;
}
</style>
