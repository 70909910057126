var _a;
import { __decorate, __metadata } from "tslib";
import Box from '@/shared/layout/box/layout-box.vue';
import DonorLookup, { Donor } from '../../payment-form/donor-lookup';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { formatAddress } from '../../../util';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let DonorBox = class DonorBox extends Vue {
    value;
    disabled;
    formatAddress = formatAddress;
};
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", typeof (_a = typeof Donor !== "undefined" && Donor) === "function" ? _a : Object)
], DonorBox.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], DonorBox.prototype, "disabled", void 0);
DonorBox = __decorate([
    Component({
        components: {
            Box,
            DonorLookup,
            layoutText
        }
    })
], DonorBox);
export default DonorBox;
