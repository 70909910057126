export function extractFullFilename(url) {
    return decodeURIComponent(url.split('/').slice(-1)[0]);
}
export function extractShortFilename(url) {
    let str = decodeURIComponent(url.split('/').slice(-1)[0]);
    if (str.length > 40)
        return str.substring(0, 40) + '...';
    else
        return str;
}
