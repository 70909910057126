var _a, _b;
import { __decorate, __metadata } from "tslib";
import Box from '@/shared/layout/box/layout-box.vue';
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { monthDayYearToDate, dateToMonthDayYear } from '@/shared/util';
function monthDayYearToToken(date) {
    return `${date.year}/${String(date.month).padStart(2, '0')}/${String(date.day).padStart(2, '0')}`;
}
function tokenToMonthDayYear(token) {
    if (!token)
        return null;
    const [year, month, day] = token.split('/').map(Number);
    return {
        year,
        month,
        day
    };
}
let DatePicker = class DatePicker extends Vue {
    value;
    range;
    placeholder;
    allowedDates;
    disabled;
    clearable;
    disabledDates(date) {
        if (this.allowedDates && this.allowedDates.maxDate && monthDayYearToDate(this.allowedDates.maxDate) > date)
            return true;
        if (this.allowedDates && this.allowedDates.minDate && monthDayYearToDate(this.allowedDates.minDate) < date)
            return true;
        return false;
    }
    get date() {
        if (!this.value)
            return;
        if (this.range) {
            const value = this.value;
            return [
                value.start ? monthDayYearToToken(dateToMonthDayYear(value.start)) : null,
                value.end ? monthDayYearToToken(dateToMonthDayYear(value.end)) : null
            ];
        }
        else {
            const value = this.value;
            return monthDayYearToToken(value);
        }
    }
    emit(token) {
        if (!token)
            this.$emit('input', null);
        if (this.range) {
            this.$emit('input', {
                start: token[0] ? monthDayYearToDate(tokenToMonthDayYear(token[0])) : null,
                end: token[1] ? monthDayYearToDate(tokenToMonthDayYear(token[1])) : null
            });
        }
        else {
            this.$emit('input', tokenToMonthDayYear(token));
        }
    }
};
__decorate([
    Prop(),
    __metadata("design:type", Object)
], DatePicker.prototype, "value", void 0);
__decorate([
    Prop({ default: false }),
    __metadata("design:type", Boolean)
], DatePicker.prototype, "range", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], DatePicker.prototype, "placeholder", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Object)
], DatePicker.prototype, "allowedDates", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Boolean)
], DatePicker.prototype, "disabled", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Boolean)
], DatePicker.prototype, "clearable", void 0);
DatePicker = __decorate([
    Component({
        inheritAttrs: false,
        components: {
            Box,
            VueDatePicker
        }
    })
], DatePicker);
export default DatePicker;
