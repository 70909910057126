import { extractContactInfoFromDonor, extractContactInfoFromPerson } from '@/pages/household/pages/_shared/util';
import { ContactInfoType, GrantStatus, RelationshipType } from '@/shared/schema/globalTypes';
import { dateScalarToDate } from '@velocity/shared/dist/utils';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import GET_DONOR from './get-donor.graphql';
import SET_AS_PRIMARY_CONTACT_INFO from './set-as-primary-contact-info.graphql';
function formatTimestamp(ts) {
    return dateScalarToDate(ts).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
}
export function loadSummary(props) {
    const { result, loading } = useQuery(GET_DONOR, () => ({ id: props.id }), {});
    const summary = useResult(result, null, data => {
        let donor = data.donor;
        if (!donor)
            return null;
        const contactInfo = donor.__typename === 'Household'
            ? donor.personAssociations
                .filter(x => x.relationship == RelationshipType.Husband || RelationshipType.Wife)
                .map(x => extractContactInfoFromPerson(x.person, donor.primaryAddressID, donor.primaryPhoneID, donor.primaryEmailID))
            : donor.contacts.map(x => extractContactInfoFromPerson(x, donor.primaryAddressID, donor.primaryPhoneID, donor.primaryEmailID));
        const companyContacts = donor.__typename !== 'Household' ? [extractContactInfoFromDonor(donor)] : [];
        const upcomingMoves = orderBy([
            ...donor.activities
                .filter(x => dateScalarToDate(x.dueDate) >= new Date())
                .map(x => ({
                type: 'Activity',
                activityType: x.type,
                files: x.files,
                comments: x.comments.map(comment => ({ ...comment, date: dateScalarToDate(comment.date) })),
                assignee: {
                    id: x.assignee.id,
                    name: `${x.assignee.firstName} ${x.assignee.lastName}`
                },
                departments: x.assignee.departments,
                duration: x.duration,
                note: x.note || undefined,
                goal: x.goal ? { id: x.goal.id, label: x.goal.title } : null,
                dueDate: x.dueDate,
                id: x.id,
                subject: x.subject,
                completed: x.completed,
                private: x.private,
                timeZone: x.timeZone,
                departmentCollaborators: x.departmentCollaborators,
                userCollaborators: x.userCollaborators.map(x => ({
                    id: x.id,
                    label: `${x.firstName} ${x.lastName}`,
                    firstName: x.firstName,
                    lastName: x.lastName
                }))
            }))
        ], (move) => {
            if (move.type === 'Pledge')
                return move.date;
            if (move.type === 'Activity')
                return move.dueDate;
        }, 'asc');
        const grants = donor.__typename === 'Foundation'
            ? donor.grants
                .filter(grant => [GrantStatus.Pending, GrantStatus.Approved, GrantStatus.Denied].indexOf(grant.status) !== -1)
                .map(x => {
                return {
                    type: 'Grant',
                    id: x.id,
                    amount: x.status === GrantStatus.Approved ? x.amountApproved : x.amount,
                    status: x.status,
                    dateSent: x.dateSent,
                    fundraiser: x.fundraiser ? `${x.fundraiser.firstName} ${x.fundraiser.lastName}` : '',
                    department: x.department,
                    projectName: x.projectName || '',
                    proposedBy: x.proposedBy ? `${x.proposedBy.firstName} ${x.proposedBy.lastName}` : '',
                    campaign: x.campaign ? x.campaign.name : '',
                    subcampaign: x.subCampaign ? x.subCampaign.name : '',
                    householdID: donor.id
                };
            })
            : [];
        const pastMoves = orderBy([
            ...donor.activities
                .filter(x => dateScalarToDate(x.dueDate) < new Date())
                .map(x => ({
                type: 'Activity',
                activityType: x.type,
                files: x.files,
                comments: x.comments.map(comment => ({ ...comment, date: dateScalarToDate(comment.date) })),
                assignee: {
                    id: x.assignee.id,
                    name: `${x.assignee.firstName} ${x.assignee.lastName}`
                },
                departments: x.assignee.departments,
                duration: x.duration,
                note: x.note || undefined,
                goal: x.goal ? { id: x.goal.id, label: x.goal.title } : null,
                dueDate: x.dueDate,
                id: x.id,
                subject: x.subject,
                completed: x.completed,
                private: x.private,
                timeZone: x.timeZone,
                departmentCollaborators: x.departmentCollaborators,
                userCollaborators: x.userCollaborators.map(x => ({
                    id: x.id,
                    label: `${x.firstName} ${x.lastName}`,
                    firstName: x.firstName,
                    lastName: x.lastName
                }))
            })),
            ...donor.pledges.map(x => {
                let enteredBy = x.enteredBy || { firstName: 'System', lastName: '' };
                const outerPayments = x.applications.filter(a => a.payment.donor && a.payment.donor.id !== data.donor.id);
                return {
                    type: 'Pledge',
                    pledgeCategory: x.type,
                    amount: x.amount,
                    amountPaid: x.totalPaid,
                    date: x.date,
                    fundraiser: `${enteredBy.firstName} ${enteredBy.lastName}`,
                    id: x.id,
                    department: x.department,
                    uncollectible: x.uncollectible,
                    campaign: x.campaign ? x.campaign.name : '',
                    subcampaign: x.subCampaign ? x.subCampaign.name : '',
                    householdID: data.donor.id,
                    outerPayments: outerPayments.map(p => ({
                        id: p.payment.id,
                        amount: p.amount,
                        donor: p.payment.donor.name
                    })) || null
                };
            }),
            ...donor.notes.map(x => {
                return {
                    type: 'Note',
                    enteredBy: x.enteredBy,
                    departments: x.enteredBy?.departments,
                    departmentCollaborators: x.departmentCollaborators,
                    userCollaborators: x.userCollaborators.map(x => ({
                        id: x.id,
                        label: `${x.firstName} ${x.lastName}`,
                        firstName: x.firstName,
                        lastName: x.lastName
                    })),
                    createdAt: x.date,
                    id: x.id,
                    text: x.note,
                    private: x.private,
                    files: x.files.map(file => ({ name: file.fileUrl, id: file.id })),
                    comments: x.comments.map(comment => ({ ...comment, date: dateScalarToDate(comment.date) }))
                };
            }),
            ...donor.goals.map(x => {
                let enteredBy = x.enteredBy || { firstName: 'System', lastName: '' };
                return {
                    type: 'Goal',
                    id: x.id,
                    createdAt: x.createdAt,
                    creator: `${enteredBy.firstName} ${enteredBy.lastName}`,
                    title: x.title,
                    department: x.department
                };
            }),
            ...grants
        ], (move) => {
            if (move.type === 'Pledge')
                return move.date;
            if (move.type === 'Activity')
                return move.dueDate;
            if (move.type === 'Goal')
                return move.createdAt;
            if (move.type === 'Note')
                return move.createdAt;
            if (move.type === 'Grant')
                return move.dateSent;
        }, 'desc');
        const contacts = donor.__typename !== 'Household' ? [{ id: donor.id, name: donor.name }] : [];
        return {
            type: donor.__typename,
            tags: donor.tags,
            primaryEmailID: donor.primaryEmailID,
            primaryAddressID: donor.primaryAddressID,
            primaryPhoneID: donor.primaryPhoneID,
            approaches: donor.__typename === 'Foundation' ? donor.approaches : [],
            goals: donor.goals,
            followers: donor.followers,
            phones: [...flatMap(contactInfo, x => x.phones), ...flatMap(companyContacts, x => x.phones)],
            emails: [...flatMap(contactInfo, x => x.emails), ...flatMap(companyContacts, x => x.emails)],
            name: donor.name,
            ein: donor.__typename === 'Foundation' && donor.ein ? donor.ein : undefined,
            isFederation: false,
            addresses: [...flatMap(contactInfo, x => x.addresses), ...flatMap(companyContacts, x => x.addresses)],
            givingSummary: donor.givingSummary,
            optOutCall: donor.noCalls ? formatTimestamp(donor.noCalls) : undefined,
            optOutContact: donor.noContact ? formatTimestamp(donor.noContact) : undefined,
            optOutEmail: donor.noEmail ? formatTimestamp(donor.noEmail) : undefined,
            optOutMail: donor.noMail ? formatTimestamp(donor.noMail) : undefined,
            optOutText: donor.noText ? formatTimestamp(donor.noText) : undefined,
            usedDAF: donor.usedDAF,
            people: donor.__typename === 'Household'
                ? donor.personAssociations.map(x => ({
                    id: x.personId,
                    name: `${x.person.firstName} ${x.person.lastName}`,
                    facebookLink: x.person.facebookLink,
                    linkedInLink: x.person.linkedInLink,
                    twitterLink: x.person.twitterLink
                }))
                : [
                    ...contacts,
                    ...donor.contacts.map(x => ({
                        id: x.id,
                        name: `${x.firstName} ${x.lastName}`
                    }))
                ],
            pastMoves,
            upcomingMoves,
            ...(donor.__typename !== 'Household' ? { donorID: donor.id } : {}),
            ...(donor.__typename === 'Company' || donor.__typename === 'School' || donor.__typename === 'Foundation'
                ? {
                    billingEmailID: donor.billingEmailID,
                    billingAddressID: donor.billingAddressID,
                    billingName: donor.billingName
                }
                : {})
        };
    });
    return { summary, loading };
}
export function setAsPrimaryMutation(props, summary) {
    const { mutate, loading } = useMutation(SET_AS_PRIMARY_CONTACT_INFO);
    function setAsPrimary(type, id) {
        const optimisticResponse = summary.value
            ? {
                setPrimaryContactInfo: {
                    __typename: summary.value.type,
                    id: props.id,
                    primaryAddressID: type === ContactInfoType.Address ? id : summary.value.primaryAddressID,
                    primaryPhoneID: type === ContactInfoType.Phone ? id : summary.value.primaryPhoneID,
                    primaryEmailID: type === ContactInfoType.Email ? id : summary.value.primaryEmailID
                }
            }
            : undefined;
        return mutate({ donorId: props.id, id, type }, { optimisticResponse });
    }
    return { setAsPrimary, loading };
}
