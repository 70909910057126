var _a;
import { __decorate, __metadata } from "tslib";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import UIInput from './input/ui-input.vue';
let NumberInput = class NumberInput extends Vue {
    size;
    value;
    /** Enables comma separator (it only affects how input looks) */
    enableComma;
    get listeners() {
        return {
            ...this.$listeners,
            // number is shown as a string but emitted without (float)
            input: (value) => {
                let parsedNumber = parseFloat(value.replace(/[,\s]/g, ''));
                if (Number.isNaN(parsedNumber)) {
                    parsedNumber = 0;
                }
                this.$emit('input', parsedNumber);
            },
            change: (value) => {
                let parsedNumber = parseFloat(value.replace(/[,\s]/g, ''));
                if (Number.isNaN(parsedNumber)) {
                    parsedNumber = 0;
                }
                this.$emit('change', parsedNumber);
            }
        };
    }
    get formattedValue() {
        if (this.value === 0) {
            return '0';
        }
        if (!this.value || Number.isNaN(this.value)) {
            return '';
        }
        return this.enableComma ? this.value.toLocaleString() : this.value.toString();
    }
    get props() {
        return {
            ...this.$attrs,
            ...this.$props
        };
    }
};
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], NumberInput.prototype, "size", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], NumberInput.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Boolean)
], NumberInput.prototype, "enableComma", void 0);
NumberInput = __decorate([
    Component({
        components: { UIInput }
    })
], NumberInput);
export default NumberInput;
