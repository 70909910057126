var _a;
import { __decorate, __metadata } from "tslib";
import { UserProvider } from '@/shared/authentication';
import Box from '@/shared/layout/box/layout-box.vue';
import NavItem from '@/shared/components/navigation-panel/navItem.vue';
import NavigationPanel from '@/shared/components/navigation-panel/navigation-panel.vue';
import Avatar from '@/shared/components/avatar/avatar.vue';
import uiButton from '@/shared/ui/button/ui-button.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { MatchFirst, Route, Router, RouterLink } from 'vue-component-router';
import Icon from '@/shared/components/icon/icon.vue';
import PledgeForm from '@/shared/components/pledge-form';
import Modal from '@/shared/components/modal/modal.vue';
import { Vue, Component, Inject } from 'vue-property-decorator';
let DashboardsLayout = class DashboardsLayout extends Vue {
    userProvider;
    get userType() {
        return this.userProvider.user ? this.userProvider.user.type : null;
    }
};
__decorate([
    Inject(),
    __metadata("design:type", typeof (_a = typeof UserProvider !== "undefined" && UserProvider) === "function" ? _a : Object)
], DashboardsLayout.prototype, "userProvider", void 0);
DashboardsLayout = __decorate([
    Component({
        components: {
            Box,
            NavItem,
            NavigationPanel,
            Avatar,
            uiButton,
            Icon,
            layoutText,
            Route,
            RouterLink,
            Router,
            MatchFirst,
            PledgeForm,
            Modal,
            FundraiserDashboard: () => import('./pages/fundraiser'),
            DSDashboard: () => import('./pages/ds/ds.vue'),
            AccountingDashboard: () => import('./pages/accounting/accounting.vue')
        }
    })
], DashboardsLayout);
export default DashboardsLayout;
