<template>
	<Box v-bind="$attrs">
		<VueAvatar
			v-bind="$attrs"
			:rounded="rounded"
			:size="size"
			:class="{ shadow: shadow }"
			:backgroundColor="backgroundColor"
		></VueAvatar>
	</Box>
</template>
<script>
import VueAvatar from 'vue-avatar'
import Box from '@/shared/layout/box/layout-box.vue'

export default {
	name: 'V2Avatar',
	props: {
		rounded: {
			type: Boolean,
			default: false
		},
		size: {
			type: Number,
			default: 80
		},
		shadow: {
			type: Boolean,
			default: false
		},
		gender: {
			type: String
		}
	},
	components: {
		VueAvatar,
		Box
	},
	computed: {
		backgroundColor() {
			if (this.gender === 'male') {
				return '#4285f4'
			}
			if (this.gender === 'female') {
				return '#e97eea'
			}
			const colors = ['#FFDB8E', '#8298D6', '#74C6CE']
			const color = colors[Math.floor(Math.random() * colors.length)]

			return color
		}
	}
}
</script>

<style lang="scss" scoped>
.shadow {
	border: 1px solid white;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
</style>
