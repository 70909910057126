<template>
	<label class="box-radio" :class="color">
		<input :checked="checked" type="radio" :name="name" @click="set" />
		<div class="check">
			<span class="text">{{ labelText }}</span>
		</div>
	</label>
</template>

<script>
export default {
	name: 'UIRadio',
	component: {},
	props: {
		color: String,
		set: Function,
		checked: Boolean,
		name: String,
		labelText: String
	}
}
</script>

<style lang="scss" scoped>
.box-radio {
	cursor: pointer;
	margin-right: 0px;
	display: flex;
}
.box-radio input {
	position: absolute;
	left: -9999px;
	opacity: 0;
	visibility: hidden;
}
.text {
	color: var(--main-blue);
	line-height: 20px;
	font-size: var(--main-font-size);
}
.box-radio:first-child .check {
	border-radius: 4px 0 0 4px;
}
.box-radio:last-child .check {
	border-radius: 0px 4px 4px 0px;
	margin-left: 0;
}
.check {
	border: 1px solid var(--main-blue);
	padding: 6px 15px;
	font-size: var(--main-font-size);
	margin: 0 -1px;
	background: var(--input-bg);
	transition: all 0.1s ease-in-out;
}
.box-radio input:checked ~ .check {
	background: var(--main-blue);

	.text {
		color: white;
		font-weight: 400;
	}
}
.box-radio input:focus ~ .check {
	box-shadow: 0 0 10px var(--main-blue);
}

.black.box-radio {
	.text {
		color: #333;
	}
	.check {
		border: 1px solid #333;
	}
	input:checked ~ .check {
		background: #333;
	}
	input:focus ~ .check {
		box-shadow: 0 0 10px #333;
	}
}
</style>
