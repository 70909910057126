import { __decorate, __metadata } from "tslib";
import Loading from '@/shared/components/loading/Loading.vue';
import { CurrencyType } from '@/shared/schema/globalTypes';
import { Vue, Prop, Component } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import NumberInput from '@/shared/ui/forms/number-input.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import { formatMoney } from '@/shared/util';
let AmountWithCurrencyField = class AmountWithCurrencyField extends Vue {
    value;
    convertedAmount;
    convertedLiveAmount;
    isCurrencyChangeable;
    loading;
    amount;
    currency;
    convert;
    showTooltip;
    errorStr;
    formatMoney = formatMoney;
    CurrencyType = CurrencyType;
    onCurrencyChange(e) {
        this.$emit('currency', e);
        if (!this.amount)
            return;
        this.$emit('input', { currency: e, amount: this.amount });
    }
    onAmountChange(e) {
        this.$emit('amount', e);
        this.$emit('input', { currency: this.currency, amount: e });
    }
};
__decorate([
    Prop({ type: Object, default: null }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "value", void 0);
__decorate([
    Prop({ type: Number, default: null }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "convertedAmount", void 0);
__decorate([
    Prop({ type: Number, default: null }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "convertedLiveAmount", void 0);
__decorate([
    Prop({ type: Boolean, default: true, required: false }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "isCurrencyChangeable", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "loading", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "amount", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "currency", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "convert", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: true }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "showTooltip", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], AmountWithCurrencyField.prototype, "errorStr", void 0);
AmountWithCurrencyField = __decorate([
    Component({
        components: {
            Loading,
            Box,
            layoutText,
            UILabel,
            UISelect,
            NumberInput,
            layoutFormRow,
            UIInput
        }
    })
], AmountWithCurrencyField);
export default AmountWithCurrencyField;
