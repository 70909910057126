import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { notifications } from './';
import Toastr from '@/shared/components/toastr/toastr.vue';
let NotificationsContainer = class NotificationsContainer extends Vue {
    get notifications() {
        return notifications.notifications;
    }
};
NotificationsContainer = __decorate([
    Component({
        components: { Toastr }
    })
], NotificationsContainer);
export default NotificationsContainer;
