import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RouterLink } from 'vue-component-router';
import { orderBy, uniq } from 'lodash';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import Icon from '@/shared/components/icon/icon.vue';
import Card from '@/shared/ui/card/card.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import DateCell from '@/shared/ui/grid/cells/DateCell.vue';
import Grid from '@/shared/ui/grid/grid.vue';
import UICheckbox from '@/shared/ui/forms/checkbox/checkbox.vue';
import UISwitch from '@/shared/ui/forms/switch/switch.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import FancySelect from '@/shared/ui/forms/fancy-select';
import MoneyCell from '@/shared/ui/grid/cells/MoneyCell.vue';
import DepartmentSelector from '@/shared/components/department-selector';
import UIButton from '@/shared/ui/button/ui-button.vue';
import withLoader from '@/shared/components/loading/with-loader';
import { getAuthStore } from '@/shared/authentication';
let ReceiptSummary = class ReceiptSummary extends Vue {
    loading;
    total;
    payments;
    filters;
    page = { skip: 0, take: 20 };
    sort = { field: 'date', dir: 'desc' };
    bankFilter = null;
    columns = [
        { cell: 'PDFCell', width: '100px', sortable: false },
        { field: 'appliedAmount', title: 'Applied amount', cell: MoneyCell, width: '150px', sortable: true },
        { field: 'type', title: 'Type', width: '110px', sortable: true },
        { field: 'batch.name', title: 'Batch', width: '150px', sortable: true },
        { field: 'date', title: 'Payment Date', cell: DateCell, width: '130px', sortable: true },
        { field: 'department', title: 'Department', width: '150px', sortable: true },
        { field: 'campaign', title: 'Campaign', width: '150px', sortable: true },
        { field: 'subCampaign', title: 'Sub Campaign', width: '150px', sortable: true },
        { field: 'notes', title: 'Notes', width: '180px', sortable: true },
        { field: 'fundraisers', title: 'Fundraisers', width: '180px', sortable: true },
        { cell: 'EditCell', width: '50px', sortable: false }
    ];
    get bankOptions() {
        if (this.loading && this.bankFilter)
            return [this.bankFilter];
        const banks = this.payments
            .filter(payment => payment.batch)
            .map(payment => payment.batch)
            .map(batch => batch.bank);
        const uniqueBanks = uniq(banks);
        return uniqueBanks.map(bank => ({ id: bank, label: bank }));
    }
    get departmentOptions() {
        if (this.loading && this.departmentFilter)
            return [this.departmentFilter];
        const departments = uniq(this.payments.map(payment => payment.department));
        return departments.map(department => ({ id: department, label: department }));
    }
    get departmentFilter() {
        if (!this.filters.department)
            return null;
        return { id: this.filters.department, label: this.filters.department };
    }
    get filteredPayments() {
        return this.payments.filter(payment => {
            if (!this.bankFilter)
                return true;
            if (!payment.batch)
                return false;
            return payment.batch.bank === this.bankFilter.id;
        });
    }
    get currentPayments() {
        const sortedPayments = orderBy(this.filteredPayments, [this.sort.field], [this.sort.dir]);
        return sortedPayments.slice(this.page.skip, this.page.skip + this.page.take);
    }
    onDepartmentFilterChange(department) {
        this.$emit('filters', { ...this.filters, department: department ? department.id : null });
        this.resetPagination();
    }
    onBankFilterChange(bank) {
        this.bankFilter = bank;
        this.resetPagination();
    }
    onPageChange(page) {
        this.page = page;
    }
    onSortChange(sortOptions) {
        if (sortOptions.length === 0)
            return;
        this.sort = sortOptions[0];
    }
    resetPagination() {
        this.page = { skip: 0, take: 20 };
    }
    clearFilters() {
        this.bankFilter = null;
        this.$emit('filters', {});
    }
    getPaymentReceiptLink(item) {
        return process.env.VUE_APP_API.replace('/graphql', `/pdf/pledgeReceipt/${item.pledgeId}/${item.id}?token=${getAuthStore().user?.access_token}`);
    }
};
__decorate([
    Prop({ type: Boolean, required: true }),
    __metadata("design:type", Object)
], ReceiptSummary.prototype, "loading", void 0);
__decorate([
    Prop({ type: Number, required: true }),
    __metadata("design:type", Object)
], ReceiptSummary.prototype, "total", void 0);
__decorate([
    Prop({ type: Array, required: true }),
    __metadata("design:type", Object)
], ReceiptSummary.prototype, "payments", void 0);
__decorate([
    Prop({ type: Object, required: true }),
    __metadata("design:type", Object)
], ReceiptSummary.prototype, "filters", void 0);
ReceiptSummary = __decorate([
    Component({
        components: {
            Box,
            Icon,
            UIButton,
            UILabel,
            Card,
            UISwitch,
            UISelect,
            Grid: withLoader({ overlay: true })(Grid),
            layoutText,
            DepartmentSelector,
            UICheckbox,
            FancySelect,
            RouterLink
        }
    })
], ReceiptSummary);
export default ReceiptSummary;
