import { notify } from '@/shared/notifications';
import { Department, PaymentCategory, PaymentType, PledgeCategory, SendVia, CurrencyType, WireBank } from '@/shared/schema/globalTypes';
import uniq from 'lodash/uniq';
import SUBMIT_PAYMENT from './submit-payment.graphql';
import CHARGE_CARD from './charge-card.graphql';
import { FormBuilder } from '@/shared/form-builder';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import Card from '@/shared/ui/card/card.vue';
import AmountWithCurrencyField from '@/shared/ui/forms/amount-with-currency';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import FormSection from '@/shared/ui/forms/form-section/form-section.vue';
import { addWithRounding, formatAddress, formatMoney, monthDayYearToDate } from '@/shared/util';
import { v4 as uuid } from 'uuid';
import DonorLookup from './donor-lookup';
import { formDescription } from './form';
import PledgeSelector from './pledge-selector';
import { defineComponent, ref, computed } from 'vue';
import { useMutation } from '@vue/apollo-composable';
const ncsyGateways = {
    NCSY_Arizona: 'USAePay-NcsyWestcoast',
    NCSY_AtlanticSeaboard: 'USAePay-NcsyAtlanticseaboard',
    NCSY_CentralEast: 'USAePay-NcsyCentraleast',
    NCSY_Cleveland: 'USAePay-NcsyCentraleast',
    [Department.NCSY_LACity]: 'USAePay-NcsyWestcoast',
    [Department.NCSY_LAValley]: 'USAePay-NcsyWestcoast',
    NCSY_Midwest: 'USAePay-NcsyMidwest',
    NCSY_NewEngland: 'USAePay-NcsyUpstate',
    NCSY_NewJersey: 'USAePay-NcsyJersey',
    NCSY_NewYork: 'USAePay-NcsyNewyork',
    NCSY_Norcal: 'USAePay-NcsyWestcoast',
    NCSY_Portland: 'USAePay-NcsyWestcoast',
    NCSY_Southern: 'USAePay-Southern',
    NCSY_Southwest: 'USAePay-Southewest',
    NCSY_UpstateNewYork: 'USAePay-Upstate',
    NCSY_WestCoast: 'USAePay-Westcoast'
};
function getApplications(form, pledgeId) {
    return !form.applications
        ? [
            {
                amount: form.amount?.amount,
                pledgeId
            }
        ]
        : form.applications.map(x => ({
            amount: +x.amount,
            ...(x.pledgeId !== null
                ? { pledgeId: x.pledgeId }
                : {
                    pledge: {
                        id: uuid(),
                        amount: +x.amount,
                        campaignId: x.campaign?.id,
                        currency: form.amount?.currency,
                        date: x.date.toISOString(),
                        department: x.department,
                        notes: x.notes,
                        type: x.type || PledgeCategory.Gift,
                        tributeText: x.tributeText || null,
                        grantId: x.grantId || null,
                        fundraisers: x.fundraisers,
                        subCampaignId: x.subCampaign?.id,
                        donorId: form.donorId
                    }
                })
        }));
}
function useChargeCard() {
    const mutation = useMutation(CHARGE_CARD);
    mutation.onError(error => {
        console.error(error);
        notify('The payment did not go through', 'error');
    });
    async function chargeCard(form, pledgeId, pledgeDepartment) {
        let campaign = null;
        let subCampaign = null;
        let department = null;
        if (form.applications?.length) {
            const app = form.applications[0];
            campaign = app.campaign;
            subCampaign = app.subCampaign;
            department = app.department;
        }
        const applications = form.applications
            ? form.applications.map(x => x.pledgeId !== null
                ? {
                    pledgeId: x.pledgeId,
                    amount: x.amount,
                    department: x.department
                }
                : {
                    pledgeId: x.pledgeId,
                    amount: x.amount,
                    department: x.department,
                    campaignId: x.campaign?.id || null,
                    subCampaignId: x.subCampaign?.id || null,
                    notes: x.notes,
                    fundraisers: x.fundraisers.map(x => ({
                        id: x.fundraiserID,
                        percentage: x.percent || 100
                    }))
                })
            : [
                {
                    pledgeId: pledgeId,
                    amount: form.amount?.amount,
                    department: pledgeDepartment
                }
            ];
        let gateway = form.card.gateway == 'SensePassCardknox' ? form.card.gateway : gatewayFromApplications(applications);
        if (gateway == 'USAePay')
            gateway = 'USAePay-Velocity';
        try {
            const result = await mutation.mutate({
                input: {
                    amount: form.amount.amount,
                    pledgeId,
                    campaign: campaign?.name,
                    subCampaign: subCampaign?.name,
                    department,
                    description: form.notes,
                    token: form.card.token,
                    test_mode: JSON.parse(process.env.VUE_APP_PAYMENT_TEST_MODE),
                    gateway,
                    AccountID: form.selectedDonor.id,
                    applications
                }
            });
            if (!result.data?.chargeCard?.error) {
                notify('Payment went through');
            }
            else {
                console.log(result.data.chargeCard.error);
                notify('The payment did not go through', 'error');
            }
        }
        catch (error) {
            notify('Something has gone wrong', 'error');
        }
    }
    return {
        processing: computed(() => mutation.loading.value),
        chargeCard
    };
}
function gatewayFromApplications(applications) {
    const gateways = uniq(applications.map(x => {
        const gateway = ncsyGateways[x.department];
        if (gateway)
            return gateway;
        return 'USAePay-Velocity';
    }));
    const gateway = gateways.length === 1 ? gateways[0] : 'USAePay-Velocity';
    return gateway;
}
function useRecordPayment(pledgeId) {
    const mutation = useMutation(SUBMIT_PAYMENT, {
        refetchQueries: ['GetFidelityTransactions', 'GetCardknoxTransactions']
    });
    function recordPayment(form) {
        const applications = getApplications(form, pledgeId);
        return mutation.mutate({
            data: {
                donorId: form.donorId,
                amount: form.amount?.amount,
                currency: form.amount?.currency,
                category: form.category,
                DAFName: form.dafName,
                IRAName: form.iraName,
                MatchingGiftName: form.matchingGiftName,
                type: form.type,
                id: form.id,
                batchId: form.batchId.id,
                date: monthDayYearToDate(form.date).toISOString(),
                sendReceiptVia: form.sendReceipt ? form.sendReceiptVia : null,
                notes: form.notes || null,
                channel: form.channel || null,
                applications,
                ...(form.type === PaymentType.Check
                    ? {
                        check: {
                            MICR: form.MICR,
                            backScan: form.backScan.file,
                            frontScan: form.frontScan.file,
                            checkNum: form.checkNum,
                            depositDate: form.depositDate
                                ? monthDayYearToDate(form.depositDate).toISOString()
                                : null,
                            receivedDate: form.receivedDate
                                ? monthDayYearToDate(form.receivedDate).toISOString()
                                : null
                        }
                    }
                    : {}),
                ...(form.type === PaymentType.CreditCard
                    ? {
                        creditCard: {
                            cardHolder: form.cardHolder,
                            expirationMonth: form.expirationMonth,
                            expirationYear: form.expirationYear,
                            cardNumber: form.cardNumber,
                            transactionID: form.transactionId
                        }
                    }
                    : {}),
                ...(form.type === PaymentType.Stock
                    ? {
                        stock: {
                            dateOfSold: monthDayYearToDate(form.dateOfSold).toISOString(),
                            dayHigh: form.dayHigh,
                            dayLow: form.dayLow,
                            howItSold: form.howItSoldFor,
                            numberOfShares: form.numOfShares,
                            stockSymbol: form.stockSymbol
                        }
                    }
                    : {}),
                ...(form.type === PaymentType.InKind
                    ? {
                        inkind: {
                            description: form.description
                        }
                    }
                    : {}),
                ...(form.type === PaymentType.Wire
                    ? {
                        wire: {
                            wireBank: form.wireBank
                        }
                    }
                    : {}),
                ...(form.type === PaymentType.Crypto
                    ? {
                        crypto: {
                            cryptoType: form.cryptoType,
                            exchangeRate: form.exchangeRate,
                            bank: form.bank
                        }
                    }
                    : {}),
                ...([
                    PaymentType.ACH,
                    PaymentType.ApplePay,
                    PaymentType.Bit,
                    PaymentType.GooglePay,
                    PaymentType.Paypal,
                    PaymentType.Venmo
                ].includes(form.type) && form.transactionId
                    ? {
                        creditCard: { transactionID: form.transactionId }
                    }
                    : {})
            }
        });
    }
    return {
        recordPayment,
        recording: mutation.loading
    };
}
export default defineComponent({
    components: {
        Box,
        layoutText,
        layoutFormRow,
        UIButton,
        FormSection,
        Card,
        FormBuilder,
        DonorLookup,
        PledgeSelector,
        AmountWithCurrencyField
    },
    props: {
        pledgeAmount: Number,
        pledgeCurrency: { type: String, required: false, default: null },
        pledgeId: String,
        donor: Object,
        pledgeDepartment: String,
        hideDonor: Boolean,
        transaction: Object
    },
    setup(props, { emit }) {
        const state = ref(null);
        const chargeMode = ref(false);
        const selectedDonor = ref(null);
        const appError = ref('');
        const amountError = ref('');
        const showError = ref(false);
        const formVersion = ref(0);
        const cc = ref(null);
        const { chargeCard, processing } = useChargeCard();
        const { recordPayment, recording } = useRecordPayment(props.pledgeId);
        function validate(form) {
            const errors = {};
            if (!form.selectedDonor) {
                errors['selectedDonor'] = 'Donor is required';
            }
            amountError.value = '';
            if (form.amount === null || !form.amount.amount) {
                errors['amount'] = '';
                amountError.value = showError.value ? 'Amount is required' : '';
            }
            else if (+form.amount.amount <= 0 || (props.pledgeAmount && +form.amount.amount > props.pledgeAmount)) {
                errors['amount'] = '';
                amountError.value = showError.value
                    ? `Enter an amount greater than 0, and less than the pledge balance (${formatMoney(props.pledgeAmount, props.pledgeCurrency || CurrencyType.USD)})`
                    : '';
            }
            if (!form.type) {
                errors['type'] = 'Please select the payment type';
            }
            if (form.type !== 'ChargeCard') {
                if (!form.batchId) {
                    errors['batchId'] = 'Please select a batch';
                }
                if (!form.date) {
                    errors['date'] = 'Date is required';
                }
            }
            else {
                if (!form.card) {
                    errors['card'] = 'Please select a card';
                }
            }
            if (form.type === PaymentType.Check) {
                if (!form.checkNum) {
                    errors['checkNum'] = 'Check number is required';
                }
            }
            if (form.type === PaymentType.CreditCard) {
                if (!form.transactionId) {
                    errors['transactionId'] = 'Enter a transaction ID';
                }
            }
            if (form.type === PaymentType.InKind) {
                if (!form.description) {
                    errors['description'] = 'Please enter a description of the item';
                }
            }
            const applications = form.applications ? form.applications : [];
            appError.value = '';
            if (!props.pledgeId) {
                // todo: sometimes applications block rerenders and error is hidden from the view
                // As a temporary workaround, it may be feasible to create a block with the error
                // ... below 'applications' form-builder part
                if (applications.some(x => x !== null && x.amount !== null && !x.department)) {
                    errors['applications'] = 'All the pledges must have a department';
                }
                if (applications.some(x => x !== null && x.amount !== null && !x.campaign)) {
                    errors['applications'] = 'All the pledges must have a campaign';
                }
                if (form.amount && applications.length === 0) {
                    errors['applications'] = '';
                    appError.value = showError.value ? 'You must pick at least one pledge to apply the payment' : '';
                }
                else if (form.amount?.amount &&
                    addWithRounding(applications.map(app => app.amount)) !== form.amount.amount) {
                    errors['applications'] = '';
                    appError.value = showError.value
                        ? 'The amount of applications has to equal the amount of the payment'
                        : '';
                }
                if (form.type == PaymentType.ChargeCard) {
                    if (form.card) {
                        let gateway = gatewayFromApplications([{ department: props.pledgeDepartment }]);
                        if (form.card.gateway == 'USAePay') {
                            form.card.gateway = 'USAePay-Velocity';
                        }
                        if (form.card.gateway !== gateway && form.card.gateway !== 'SensePassCardknox') {
                            errors['applications'] = 'The card selected cannot be used for this department';
                        }
                    }
                }
            }
            else {
                let gateway = gatewayFromApplications([{ department: props.pledgeDepartment }]);
                if (form.type == PaymentType.ChargeCard && form.card) {
                    if (form.card.gateway == 'USAePay') {
                        form.card.gateway = 'USAePay-Velocity';
                    }
                    if (form.card.gateway !== gateway && form.card.gateway !== 'SensePassCardknox') {
                        errors['card'] = 'The card selected cannot be used for this department';
                    }
                }
            }
            if (form.sendReceipt && !form.sendReceiptVia) {
                errors['sendReceiptVia'] = 'Receipt type should be chosen';
            }
            return errors;
        }
        const form = ref({
            selectedDonor: props.donor ? props.donor : null,
            id: uuid(),
            donorId: props.donor ? props.donor.id : null,
            batchId: null,
            dafName: null,
            iraName: null,
            matchingGiftName: null,
            card: null,
            date: {
                year: new Date().getFullYear(),
                day: new Date().getDate(),
                month: new Date().getMonth() + 1
            },
            receivedDate: null,
            sendReceiptVia: SendVia.Mail,
            sendReceipt: true,
            amount: { currency: props.pledgeCurrency ?? CurrencyType.USD, amount: 0 },
            type: null,
            category: PaymentCategory.Payment,
            channel: null,
            notes: null,
            MICR: null,
            backScan: {
                file: null,
                status: null
            },
            checkNum: null,
            depositDate: null,
            frontScan: {
                file: null,
                status: null
            },
            cardNumber: null,
            cardHolder: null,
            expirationMonth: null,
            expirationYear: null,
            transactionId: null,
            description: null,
            dateOfSold: null,
            stockSymbol: null,
            howItSoldFor: null,
            dayHigh: null,
            dayLow: null,
            numOfShares: null,
            applications: null,
            wireBank: WireBank.Other,
            cryptoType: null,
            exchangeRate: null,
            bank: WireBank.Other,
            ...(!props.transaction
                ? {}
                : {
                    amount: { currency: CurrencyType.USD, amount: props.transaction.fidAmount },
                    transactionId: props.transaction.transactionId
                        ? props.transaction.transactionId.toString()
                        : null,
                    description: props.transaction.description || null,
                    type: PaymentType.CreditCard
                })
        });
        async function submit(args) {
            showError.value = true;
            if ('errors' in args)
                return;
            if (args.values.type === 'ChargeCard') {
                await chargeCard(args.values, props.pledgeId, props.pledgeDepartment);
            }
            else {
                await recordPayment({
                    ...args.values,
                    donorId: args.values.donorId || args.values.selectedDonor.id
                });
            }
            form.value.id = uuid();
            formVersion.value++;
            emit('submitted');
            notify('Payment was saved');
        }
        return {
            processing: computed(() => recording.value || processing.value),
            state,
            chargeMode,
            selectedDonor,
            appError,
            amountError,
            showError,
            PaymentCategory,
            monthDayYearToDate,
            formVersion,
            cc,
            formatMoney,
            formatAddress(address) {
                if (address)
                    return formatAddress(address);
                return '';
            },
            submit,
            formDescription,
            form,
            validate
        };
    }
});
