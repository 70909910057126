import Loading from '@/shared/components/loading/Loading.vue';
import { createHOC, createRenderFn } from 'vue-hoc';
import LoadingError from './LoadingError.vue';
function wrapName(ctor, name) {
    const target = typeof ctor === 'function' ? ctor.options : ctor;
    return name + '-' + (target.name || 'Anonymous');
}
const withLoader = (options = {}) => (ctor) => {
    const loadedFn = createRenderFn(ctor);
    return createHOC(ctor, {
        name: wrapName(ctor, 'with-loader'),
        props: {
            loading: Boolean,
            error: {}
        },
        render: function render(h) {
            const loading = this.loading;
            const error = this.error;
            const componentVNode = (loading || error) && !options.overlay ? null : loadedFn.call(this, h);
            const style = options.overlay ? { position: 'relative' } : {};
            //@ts-ignore
            const res = h('div', { style }, [
                loading && h(Loading, { props: { overlay: options.overlay } }),
                // this is for better dev experience at the moment. We can switch to popup or better error processing later
                error && h(LoadingError, { props: { error } }),
                options.keepAlive ? h('keep-alive', {}, [componentVNode]) : componentVNode
            ]);
            return res;
        }
    });
};
export default withLoader;
