import RMRequestResponder from './rm-request-responder.vue';
import { compose } from 'vue-compose';
import mutate from '@/shared/graphql/mutate';
import RESOLVE_RM_REQUEST from './resolve-rm-request.graphql';
const resolveRMRequest = mutate(RESOLVE_RM_REQUEST, {
    props(props) {
        return {
            loading: props.loading,
            resolveRMRequest(token) {
                return props.mutate({
                    variables: {
                        token
                    }
                });
            }
        };
    }
});
export default compose(resolveRMRequest)(RMRequestResponder);
