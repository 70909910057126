<template>
	<label :style="{ width: width }">{{ text }}<slot v-if="!text"></slot></label>
</template>
<script>
export default {
	name: 'UiLabel',
	props: ['text', 'width']
}
</script>

<style lang="scss" scoped>
label {
	font-size: var(--main-font-size);
	color: #333333;
	margin-bottom: 3px;
	display: block;
}
</style>
