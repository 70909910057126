<template>
	<Box class="form-row" :class="{ inlineLabel: inlineLabel }" display="flex" v-bind="$attrs">
		<slot></slot>
	</Box>
</template>

<script>
import Box from '@/shared/layout/box/layout-box.vue'

export default {
	components: {
		Box
	},
	props: {
		inlineLabel: Boolean
	},
	name: 'FormRow'
}
</script>

<style scoped lang="scss">
.form-row {
	max-width: 600px;
	flex-wrap: wrap;
}
.form-row:not(:last-child) {
	margin-bottom: 20px;
}
.form-row.smaller {
	max-width: 300px;
}
.form-row.bigger {
	max-width: 100%;
}

.form-row /deep/ .nolabel {
	padding-top: 22px;
}
/deep/ .error-label {
	font-size: 10px;
	color: red;
	width: 100%;
	display: block;

	input {
		height: 0px;
		opacity: 0;
		margin: 0;
		padding: 0;
		width: 0;
	}
}

// Inline label css
.inlineLabel.form-row {
	align-items: flex-start;

	&.smaller {
		max-width: 400px;
	}
	&.tiny {
		max-width: 200px;
	}
}
.inlineLabel.form-row.center {
	align-items: center;
}
.reverseRow.form-row {
	flex-direction: row-reverse;
	place-content: flex-end;

	/deep/ > div {
		max-width: 20px !important;
	}
}
.inlineLabel.form-row /deep/ > label {
	padding-right: 10px;
	min-width: 115px;
	padding-top: 4px;
}
.inlineLabel.form-row /deep/ > div {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	> label {
		margin-right: 10px;
		padding-top: 5px;
	}
}

// css for inline repeater fields
.repeater-row.inlineLabel.form-row /deep/ > label {
	padding-top: 5px;
}
.repeater-row.inlineLabel.form-row /deep/ > div {
	display: block;
}

//add percentage symbol to percentage fields
.form-row.percentage.inlineLabel.repeater-row .form-row {
	position: relative;

	&:after {
		content: '%';
		position: absolute;
		z-index: 100;
		top: 11px;
		right: 25px;
		color: #999;
	}
}
</style>
