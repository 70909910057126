<template>
	<hr :class="classes" />
</template>
<script>
import boxWhitespace from '../css/boxWhitespace.module.css'

export default {
	props: {
		lineStyle: {
			type: String,
			default: 'solid'
		},
		marginBottom: {
			type: Number
		},
		marginTop: {
			type: Number
		}
	},
	name: 'LayoutDevider',
	data() {
		return {
			boxWhitespace
		}
	},
	computed: {
		classes() {
			const classes = []
			if (this.lineStyle) {
				classes.push(this.lineStyle)
			}
			if (this.marginTop) {
				classes.push(boxWhitespace[`marginTop${this.marginTop}`])
			}
			if (this.marginBottom) {
				classes.push(boxWhitespace[`marginBottom${this.marginBottom}`])
			}

			return classes
		}
	}
}
</script>

<style scoped>
hr {
	border: 0;
	border-top: 1px solid #ddd;
	height: 1px;
}
.dashed {
	border-style: dashed;
}
.dotted {
	border-style: dotted;
}
</style>
