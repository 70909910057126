import { __decorate, __metadata } from "tslib";
import { Vue, Prop, Component } from 'vue-property-decorator';
import GET_S3_URL from './get-signed-url.graphql';
let GetSignedUrl = class GetSignedUrl extends Vue {
    filename;
    quiet;
    loadingMsg;
    url = null;
    error = false;
    async mounted() {
        if (!this.filename)
            return;
        try {
            const signedUrl = await this.$apollo.getClient().query({
                query: GET_S3_URL,
                fetchPolicy: 'no-cache',
                variables: {
                    fileName: this.filename
                }
            });
            this.url = signedUrl.data.s3SignedUrl;
        }
        catch (e) {
            this.error = true;
        }
    }
    render(h) {
        if (this.error && !this.quiet)
            return h('div', 'Error');
        if (!this.url && !this.quiet)
            return h('div', this.loadingMsg);
        const slots = this.$scopedSlots;
        if (!slots.default)
            throw new Error('No slot provided');
        const vnodes = slots.default({ url: this.url });
        if (!vnodes)
            throw new Error('Nothing doing');
        return vnodes[0];
    }
};
__decorate([
    Prop(),
    __metadata("design:type", Object)
], GetSignedUrl.prototype, "filename", void 0);
__decorate([
    Prop({ default: false }),
    __metadata("design:type", Boolean)
], GetSignedUrl.prototype, "quiet", void 0);
__decorate([
    Prop({ type: String, default: 'Loading Preview...' }),
    __metadata("design:type", String)
], GetSignedUrl.prototype, "loadingMsg", void 0);
GetSignedUrl = __decorate([
    Component
], GetSignedUrl);
export default GetSignedUrl;
