import { ref, watch } from 'vue';
import { defineComponent } from 'vue';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default defineComponent({
    components: {
        VueSelect
    },
    props: {
        options: Array,
        loading: Boolean,
        placeholder: String,
        value: { type: [Object, String, Number, Array] },
        clearable: { type: Boolean, default: true },
        resetAfter: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    setup(props, { attrs, emit }) {
        const selectProps = ref({
            ...props,
            value: props.value
        });
        const multiselect = ref(null);
        watch(props, props => {
            selectProps.value = { ...props, value: selectProps.value.value };
            if ('value' in props)
                selectProps.value.value = Array.isArray(props.value) ? [...props.value] : props.value;
        });
        watch(() => props.options, (current, old) => {
            const value = selectProps.value.value;
            if (!value)
                return; // nothing is set so nothing has to change
            if (isEqual(old, current))
                return;
            if (value &&
                ((props.multiple &&
                    Array.isArray(value) &&
                    !value.some(value => current.findIndex(x => isEqual(x, value)) === -1)) ||
                    current.indexOf(value) !== -1))
                return;
            if (value &&
                current.some(x => {
                    if (typeof value == 'object' && typeof x == 'object')
                        return isEqual(omit(x, 'date'), omit(value, 'date'));
                    return isEqual(x, value);
                }))
                return;
            emit('input', null);
            selectProps.value.value = null;
        });
        async function handleInput(value) {
            if (isEqual(selectProps.value.value, value))
                return;
            emit('input', value);
            emit('blur', value);
            if (props.resetAfter) {
                selectProps.value.value = null;
                //@ts-ignore
                multiselect?.value?.clearSelection();
            }
        }
        return {
            attrs,
            handleInput,
            selectProps,
            multiselect
        };
    }
});
