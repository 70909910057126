var _a, _b;
import { __decorate, __metadata } from "tslib";
import { User, withCurrentUser } from '@/shared/authentication/index';
import { UserRole } from '@/shared/schema/globalTypes';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let ProtectedBlock = class ProtectedBlock extends Vue {
    requiredRole;
    requiredRoles;
    requiredTypes;
    currentUser;
    hasRole;
    render(h) {
        let allowed = false;
        if (!this.currentUser)
            return null;
        if (this.requiredRole && this.hasRole(this.requiredRole))
            allowed = true;
        if (this.requiredRoles && this.requiredRoles.some(role => this.hasRole(role)))
            allowed = true;
        if (this.requiredTypes && this.requiredTypes.includes(this.currentUser.type))
            allowed = true;
        if (!allowed)
            return null;
        return h('div', this.$slots.default);
    }
};
__decorate([
    Prop({ type: String }),
    __metadata("design:type", typeof (_a = typeof UserRole !== "undefined" && UserRole) === "function" ? _a : Object)
], ProtectedBlock.prototype, "requiredRole", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Array)
], ProtectedBlock.prototype, "requiredRoles", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Array)
], ProtectedBlock.prototype, "requiredTypes", void 0);
__decorate([
    Prop(),
    __metadata("design:type", typeof (_b = typeof User !== "undefined" && User) === "function" ? _b : Object)
], ProtectedBlock.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Function)
], ProtectedBlock.prototype, "hasRole", void 0);
ProtectedBlock = __decorate([
    Component({})
], ProtectedBlock);
export default withCurrentUser(ProtectedBlock);
