import GET_USERS from '@/shared/graphql/get-users.graphql';
import graphql from '@/shared/graphql';
import { acceptProps, compose } from 'vue-compose';
import UserLookup from './user-lookup.vue';
import { SortOrder } from '@/shared/schema/globalTypes';
const getUsers = graphql(GET_USERS, {
    options(props) {
        return {
            variables: {
                filters: {
                    active: props.filterActive
                },
                sort: [{ name: 'name', order: SortOrder.ASC }]
            }
        };
    },
    props(props) {
        const users = !props.loading && props.data && props.data.users
            ? props.data.users.records.map(x => ({
                id: x.id,
                label: `${x.firstName} ${x.lastName}`,
                firstName: x.firstName,
                lastName: x.lastName,
                disabled: props.disableInactive && !x.active ? true : false
            }))
            : [];
        let value;
        if (props.value) {
            if ('multiple' in props && props.multiple) {
                if (typeof props.value[0] == 'string') {
                    const ids = props.value;
                    value = ids.map(id => users.find(user => user.id === id)).filter(Boolean);
                }
                else {
                    value = props.value;
                }
            }
            else {
                value = null;
                if (props.value) {
                    value =
                        typeof props.value === 'string'
                            ? users.find(u => u.id === props.value)
                            : users.find(u => u.id === props.value.id);
                }
            }
        }
        return {
            loading: props.loading,
            users,
            value
        };
    }
});
export default compose(acceptProps(['filterActive', 'disableInactive']), getUsers, acceptProps({ value: { type: [Array, String, Object] } }))(UserLookup);
