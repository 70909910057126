import { __decorate, __metadata } from "tslib";
import { getAuthStore } from '@/shared/authentication';
import ProtectedBlock from '@/shared/authentication/ProtectedBlock';
import CampaignLookup from '@/shared/components/campaign-lookup';
import GET_S3_URL from '@/shared/components/get-signed-url/get-signed-url.graphql';
import GoalLookup from '@/shared/components/goal-lookup/';
import Icon from '@/shared/components/icon/icon.vue';
import Modal from '@/shared/components/modal/modal.vue';
import DonorLookup from '@/shared/components/payment-form/donor-lookup';
import Popover from '@/shared/components/popover/popover.vue';
import SubCampaignLookup from '@/shared/components/subcampaign-lookup';
import { FormBuilder } from '@/shared/form-builder';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutDivider from '@/shared/layout/divider/layout-divider.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { notify } from '@/shared/notifications';
import { PledgeCategory, SendVia, UserRole, UserType } from '@/shared/schema/globalTypes';
import UIButton from '@/shared/ui/button/ui-button.vue';
import Card from '@/shared/ui/card/card.vue';
import AmountWithCurrency from '@/shared/ui/forms/amount-with-currency';
import UICheckBox from '@/shared/ui/forms/checkbox/checkbox.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import FormSection from '@/shared/ui/forms/form-section/form-section.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import uiLabel from '@/shared/ui/forms/label/ui-label.vue';
import NumberInput from '@/shared/ui/forms/number-input.vue';
import { RadioGroup, RadioGroupItem } from '@/shared/ui/forms/radio-group';
import uiRadio from '@/shared/ui/forms/radio/radio.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import UISwitch from '@/shared/ui/forms/switch/switch.vue';
import UITextArea from '@/shared/ui/forms/textarea/ui-textarea.vue';
import S3MultiUploader from '@/shared/ui/s3-multi-uploader';
import { monthDayYearToDate, resolveDepartments, formatDate, formatMoney, dateToMonthDayYear } from '@/shared/util';
import { dateScalarToDate, fiscalYear } from '@velocity/shared/dist/utils';
import startCase from 'lodash/startCase';
import sum from 'lodash/sum';
import isEqual from 'lodash/isEqual';
import sumBy from 'lodash/sumBy';
import { v4 as uuid } from 'uuid';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import SmallAuditView from '../audit-log/small-audit-view';
import { formDescription } from './form';
import { extractFullFilename } from '@/shared/files';
import InvoicePreview from '@/pages/household/pages/giving/invoice-preview';
import ReceiptPreview from '@/pages/household/pages/giving/receipt-preview';
const pledgeCategories = Object.entries(PledgeCategory).map(([key]) => ({
    value: key,
    label: startCase(key)
}));
let PledgeTab = class PledgeTab extends Vue {
    router;
    addPledge;
    currentUser;
    updatePledge;
    movePledge;
    deletePledge;
    chargeCard;
    processing;
    pledge;
    donor;
    paymentsCount;
    audit;
    turnaroundPayments;
    turnaround = false;
    monthDayYearToDate = monthDayYearToDate;
    dateScalarToDate = dateScalarToDate;
    formatDate = formatDate;
    formatMoney = formatMoney;
    get originalAmount() {
        return this.pledge ? { ...this.pledge.amount } : null;
    }
    get originalDate() {
        return this.pledge ? monthDayYearToDate(this.pledge.date) : null;
    }
    invoiceModal = false;
    get backupLocation() {
        return `backups/pledges/${this.form.id}`;
    }
    get form() {
        return (this.pledge ||
            {
                id: uuid(),
                shortId: null,
                goal: null,
                donor: this.donor,
                grant: null,
                type: PledgeCategory.Gift,
                amount: null,
                convertedAmount: null,
                description: null,
                date: {
                    day: new Date().getDate(),
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                },
                campaign: null,
                subcampaign: null,
                department: null,
                fundraisers: [],
                backupFiles: [],
                invoice_template: null,
                sent_status: null,
                billingPlan: null,
                calendarYear: new Date().getFullYear(),
                fiscalYear: fiscalYear(new Date()),
                accountingFY: fiscalYear(new Date()),
                tributeText: null,
                hasTurnaround: false,
                taCampaign: null,
                taDepartment: null,
                taSubcampaign: null,
                uncollectible: false,
                totalPaid: 0,
                uncollectibleReason: null,
                conditionalPledge: false,
                accountingPledge: false,
                sendInvoiceVia: SendVia.Mail,
                shouldDSPrintAnInvoice: true
            });
    }
    get formDescription() {
        return Object.freeze(formDescription(this.showTurnaround, this.currentUser.id, this.currentUser.type, this.partialBillingPlan, this.donor?.id));
    }
    pledgeCategories = Object.freeze(pledgeCategories);
    billingType = null;
    paymentType = null;
    moveModal = false;
    delModal = false;
    linkModal = false;
    formBaseUrl = 'https://donateou.org/pledge/ou-pledge';
    UserRole = UserRole;
    selectedDonor = null;
    withPayments = false;
    get invoiceReceiptLink() {
        if (!this.pledge)
            return null;
        const path = this.pledge.amount.amount === this.pledge.totalPaid ? 'pledgeReceipt' : 'pledgeInvoice';
        return process.env.VUE_APP_API.replace('/graphql', `/pdf/${path}/${this.pledge.id}?token=${getAuthStore().user.access_token}`);
    }
    closeModal() {
        this.moveModal = false;
        this.delModal = false;
        this.linkModal = false;
        this.withPayments = false;
        this.selectedDonor = null;
        this.invoiceModal = false;
    }
    async onMovePledge() {
        if (!this.pledge || !this.selectedDonor)
            return;
        await this.movePledge(this.pledge.id, this.selectedDonor.id, this.withPayments);
        this.closeModal();
        notify('Pledge was moved successfully');
        if (this.donor) {
            this.router.history.push(`/household/${this.donor.id}/giving-history`);
        }
    }
    async onDeletePledge() {
        if (!this.pledge || !this.pledge.id)
            return;
        await this.deletePledge(this.pledge.id);
        this.closeModal();
        notify('Pledge was deleted successfully');
        if (this.donor) {
            this.router.history.push(`/household/${this.donor.id}/giving-history`);
        }
    }
    get showTurnaround() {
        return this.currentUser.type === UserType.DataServices;
    }
    get partialBillingPlan() {
        return this.pledge && this.pledge.totalPaid && !this.pledge.billingPlan ? true : false;
    }
    get canMoveDeletePledge() {
        const departments = this.currentUser ? resolveDepartments(this.currentUser.departments) : [];
        return ((this.currentUser.roles.includes(UserRole.MovePledgeAndPayment) ||
            this.currentUser.roles.includes(UserRole.DeletePledgeAndPayment)) &&
            (this.currentUser.type !== UserType.Fundraiser || departments.includes(this.pledge.department)));
    }
    mounted() {
        const simpleForm = this.$refs.formBuilder.$children[0];
        simpleForm.$watch('values.date', (date) => {
            if (!date)
                return;
            simpleForm.setValues({
                accountingFY: fiscalYear(new Date(date.year, date.month - 1, date.day)),
                fiscalYear: fiscalYear(new Date(date.year, date.month - 1, date.day)),
                calendarYear: date.year
            });
        });
    }
    updateUploadedFiles(result) {
        const simpleForm = this.$refs.formBuilder.$children[0];
        if (result.status === 'uploaded') {
            const existingFiles = simpleForm.values.backupFiles.filter(file => file.uploadedFile !== null);
            const newlyUploadedFiles = result.files
                .filter(file => file.uploadedPercentage === 100)
                .map(file => ({
                description: null,
                uploadedFile: {
                    file: `${this.backupLocation}/${file.name}`,
                    status: 'uploaded'
                }
            }));
            simpleForm.setValues({ backupFiles: [...existingFiles, ...newlyUploadedFiles] });
        }
    }
    FilePlaceholder(item) {
        return item.uploadedFile.file ? item.uploadedFile.file.replace(/^.*[\\\/]/, '') : 'Add a description';
    }
    validate(values) {
        const errors = {};
        if (!this.pledge && !values.donor) {
            errors['donor'] = 'Donor is required';
        }
        if (values.hasTurnaround && !values.taDepartment) {
            errors['taDepartment'] = 'Department is required';
        }
        if (values.hasTurnaround && !values.taCampaign) {
            errors['taCampaign'] = 'Campaign is required';
        }
        if (!values.amount) {
            errors['amount'] = 'Pledge Value is required';
        }
        if (values.amount && values.amount.amount <= 0) {
            errors['amount'] = 'Pledge value should be a number greater than 0';
        }
        if (!values.date) {
            errors['date'] = 'Please select a date';
        }
        if (!values.department) {
            errors['department'] = 'Please select a department';
        }
        if (!values.campaign) {
            errors['campaign'] = 'Please select a campaign';
        }
        if (!values.fiscalYear || values.fiscalYear < 1900) {
            errors['fiscalYear'] = 'Year must look like a real year';
        }
        if (!values.accountingFY || values.accountingFY < 1900) {
            errors['accountingFY'] = 'Year must look like a real year';
        }
        if (!values.calendarYear || values.calendarYear < 1900) {
            errors['calendarYear'] = 'Year must look like a year';
        }
        values.fundraisers.forEach((x, i) => {
            if (!x.fundraiser)
                errors[`fundraisers[${i}].fundraiser`] = 'Please select a fundraiser';
            const percent = Number(x.percent);
            if (!Number.isInteger(percent) || percent < 1 || percent > 100) {
                errors[`fundraisers[${i}].percent`] = 'Please enter a number between 1 and 100';
            }
        });
        if (values.fundraisers.length) {
            if (sumBy(values.fundraisers, 'percent') > 100) {
                values.fundraisers.forEach((x, i) => {
                    errors[`fundraisers[${i}].percent`] =
                        errors[`fundraisers[${i}].percent`] || 'The total percentage must not exceed 100';
                });
            }
        }
        if (values.backupFiles.length &&
            values.backupFiles.some(file => !!file.uploadedFile && file.uploadedFile.status === 'failed')) {
            errors['backupFiles'] = 'Upload Failed';
        }
        if (values.shouldDSPrintAnInvoice && !values.sendInvoiceVia) {
            errors['sendInvoiceVia'] = 'Invoice type should be selected';
        }
        if (values.billingPlan?.autoBill && !values.billingPlan?.cardOnFile) {
            errors['billingPlan'] = 'Please select a card, or add a new one';
        }
        if (values.amount && values.billingPlan?.installments) {
            if (values.billingPlan.installments.length > 0 &&
                sum(values.billingPlan.installments.map(x => x.amount)) != values.amount.amount) {
                errors['billingPlan'] = 'The amount of installments must equal the pledge amount';
            }
            for (let i = 0; i < values.billingPlan.installments.length; i++) {
                if (!values.billingPlan.installments[i].date || !values.billingPlan.installments[i].amount) {
                    errors['billingPlan'] = 'Make sure each installment has a date and amount';
                    break;
                }
                const date = new Date(values.billingPlan.installments[i].date.year, values.billingPlan.installments[i].date.month - 1, values.billingPlan.installments[i].date.day);
                if (!values.billingPlan.installments[i + 1] || !values.billingPlan.installments[i + 1].date)
                    continue;
                const nextDate = new Date(values.billingPlan.installments[i + 1].date.year, values.billingPlan.installments[i + 1].date.month - 1, values.billingPlan.installments[i + 1].date.day);
                if (date >= nextDate) {
                    errors['billingPlan'] = 'The installment dates must be in order';
                }
            }
        }
        return errors;
    }
    async submit(args) {
        if ('errors' in args)
            return;
        let needToChargeCard = false;
        if (args.values.billingPlan?.autoBill) {
            const installment = args.values.billingPlan?.installments && args.values.billingPlan?.installments[0];
            const installmentDate = installment && installment.date;
            const hasOriginalPlan = (this.pledge && this.pledge.billingPlan?.autoBill) || false;
            if (installment &&
                installmentDate &&
                isEqual(installmentDate, dateToMonthDayYear(new Date())) &&
                !installment.paid &&
                !hasOriginalPlan) {
                installment.date.day += 1;
                needToChargeCard = true;
            }
        }
        if (this.pledge) {
            await this.updatePledge(args.values);
            if (needToChargeCard) {
                await this.chargeCard(args.values);
            }
            notify('Pledge was updated successfully');
        }
        else {
            await this.addPledge(args.values);
            if (needToChargeCard) {
                await this.chargeCard(args.values);
            }
            let donorId = args.values.donor.id;
            this.router.history.push(`/household/${donorId}/pledge/${args.values.id}`);
        }
        this.$emit('added');
    }
    //todo: we should have download file component
    async downloadBackup(backup) {
        const filePath = backup.uploadedFile ? backup.uploadedFile.file : null;
        if (!filePath)
            return;
        const signedUrl = await this.$apollo.getClient().query({
            query: GET_S3_URL,
            fetchPolicy: 'no-cache',
            variables: {
                fileName: filePath
            }
        });
        const url = signedUrl.data.s3SignedUrl;
        const blob = await fetch(url).then(x => x.blob());
        const content = await new Promise(res => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const result = reader.result;
                res(result);
            };
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = content;
        hiddenElement.target = '_blank';
        hiddenElement.download = extractFullFilename(filePath);
        hiddenElement.click();
    }
    get auditProps() {
        if (!this.pledge)
            return;
        let auditLog = this.audit
            .map(item => ({
            ...item,
            type: item.__typename,
            date: dateScalarToDate(item.date)
        }))
            .sort((a, b) => a.date.valueOf() - b.date.valueOf());
        let tail = [];
        auditLog.forEach((item, index) => {
            if (item.eventType === 'payments-invoiceSent' ||
                item.eventType === 'payments-receiptSent' ||
                (auditLog.length > 1 && index > 0 && index > auditLog.length - 3)) {
                tail.push(item);
            }
        });
        return {
            first: auditLog[0],
            tail: tail,
            sliced: auditLog.length > tail.length + 1
        };
    }
    async copyLink() {
        try {
            await navigator.clipboard.writeText(`${this.formBaseUrl}?id=${this.pledge.id}&accountId=${this.pledge.donor.id}`);
        }
        catch (e) {
            console.log('Cannot copy', e);
        }
    }
    get invoicePreviewProps() {
        return {
            donorId: this.pledge.donor.id,
            pledgeId: this.pledge.id
        };
    }
};
__decorate([
    Inject('router'),
    __metadata("design:type", Object)
], PledgeTab.prototype, "router", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Function)
], PledgeTab.prototype, "addPledge", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Function)
], PledgeTab.prototype, "updatePledge", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "movePledge", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "deletePledge", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Function)
], PledgeTab.prototype, "chargeCard", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], PledgeTab.prototype, "processing", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "pledge", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "donor", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "paymentsCount", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "audit", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeTab.prototype, "turnaroundPayments", void 0);
PledgeTab = __decorate([
    Component({
        components: {
            UIInput,
            CampaignLookup,
            SubCampaignLookup,
            SmallAuditView,
            Box,
            Popover,
            Icon,
            layoutDivider,
            layoutText,
            uiLabel,
            uiRadio,
            layoutFormRow,
            UITextArea,
            UIButton,
            NumberInput,
            FormSection,
            Card,
            Modal,
            RadioGroup,
            RadioGroupItem,
            UISelect,
            FormBuilder,
            UICheckBox,
            GoalLookup,
            UISwitch,
            DonorLookup,
            ProtectedBlock,
            S3MultiUploader,
            AmountWithCurrency,
            InvoicePreview,
            ReceiptPreview
        }
    })
], PledgeTab);
export default PledgeTab;
