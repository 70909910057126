<template>
	<component :is="tag" :class="classes" :style="styles" v-on="$listeners">
		<slot></slot>
	</component>
</template>
<script>
import boxWhitespace from '../css/boxWhitespace.module.css'
import layout from '../css/layout.module.css'

export default {
	props: {
		display: {
			type: String,
			default: 'block'
		},
		height: {
			type: String
		},
		tag: {
			type: String,
			default: 'div'
		},
		paddingX: {
			type: Number
		},
		paddingY: {
			type: Number
		},
		marginRight: {
			type: Number
		},
		marginLeft: {
			type: Number
		},
		marginTop: {
			type: Number
		},
		marginBottom: {
			type: Number
		},
		paddingRight: {
			type: Number
		},
		paddingLeft: {
			type: Number
		},
		paddingTop: {
			type: Number
		},
		paddingBottom: {
			type: Number
		},
		padding: {
			type: Number
		},
		margin: {
			type: Number
		},
		flex: {
			type: [String, Number]
		},
		flexDirection: {
			type: String
		},
		flexAlign: {
			type: String
		},
		justifyContent: {
			type: String
		}
	},
	name: 'Box',
	data() {
		return {
			boxWhitespace,
			layout
		}
	},
	computed: {
		classes() {
			const classes = []
			if (this.display) {
				classes.push(layout[this.display])
			}

			if (this.paddingX) {
				classes.push(boxWhitespace[`paddingX${this.paddingX}`])
			}
			if (this.paddingY) {
				classes.push(boxWhitespace[`paddingY${this.paddingY}`])
			}

			if (this.marginRight) {
				classes.push(boxWhitespace[`marginRight${this.marginRight}`])
			}
			if (this.marginLeft) {
				classes.push(boxWhitespace[`marginLeft${this.marginLeft}`])
			}
			if (this.marginTop) {
				classes.push(boxWhitespace[`marginTop${this.marginTop}`])
			}
			if (this.marginBottom) {
				classes.push(boxWhitespace[`marginBottom${this.marginBottom}`])
			}

			if (this.paddingRight) {
				classes.push(boxWhitespace[`paddingRight${this.paddingRight}`])
			}
			if (this.paddingLeft) {
				classes.push(boxWhitespace[`paddingLeft${this.paddingLeft}`])
			}
			if (this.paddingTop) {
				classes.push(boxWhitespace[`paddingTop${this.paddingTop}`])
			}
			if (this.paddingBottom) {
				classes.push(boxWhitespace[`paddingBottom${this.paddingBottom}`])
			}

			if (this.padding) {
				classes.push(boxWhitespace[`padding${this.padding}`])
			}
			if (this.margin) {
				classes.push(boxWhitespace[`margin${this.margin}`])
			}

			return classes
		},
		styles() {
			const styles = []

			if (this.flex) {
				styles.push({
					flex: this.flex
				})
			}
			if (this.display == 'flex') {
				styles.push({
					alignItems: this.flexAlign
				})
			}
			if (this.justifyContent) {
				styles.push({
					justifyContent: this.justifyContent
				})
			}
			if (this.flexDirection) {
				styles.push({
					flexDirection: this.flexDirection
				})
			}
			if (this.height) {
				styles.push({
					height: this.height
				})
			}
			return styles
		}
	}
}
</script>
<style scoped>
/* some helper classes to Box component */

.max-width {
	width: 100%;
	max-width: var(--max-screen-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
	padding-right: 40px;
}
.full-width {
	padding-left: 40px;
	padding-right: 40px;
}
</style>
