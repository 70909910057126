<template>
	<Box
		v-bind="$attrs"
		:class="{ card: true, flex: containerFlex }"
		:padding="padding"
		:marginBottom="marginBottom"
		v-on="$listeners"
	>
		<slot></slot>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box.vue'

export default {
	name: 'Card',
	props: {
		containerFlex: {
			type: Boolean,
			default: false
		},
		padding: {
			type: Number,
			default: 3
		},
		marginBottom: {
			type: Number,
			default: 5
		}
	},
	components: {
		Box
	}
}
</script>

<style lang="scss" scoped>
.containerFlex {
	display: flex;
}

.card {
	background: white;
	border-radius: 4px;
	border: 1px solid #efefef;

	&.shadow {
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -1px rgba(0, 0, 0, 0.12);
		border: none;
	}
}
.card.hoverShadow {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -1px rgba(0, 0, 0, 0.12);
	transition: all 0.2s linear;
	border: 1px solid transparent;

	&:hover {
		border: 1px solid var(--main-blue);
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	}
}
.card.nopadding {
	padding: 0;
}
</style>
