<template>
	<div><slot></slot></div>
</template>

<script>
export default {
	name: 'TableContainer',
	props: ['label']
}
</script>

<style scoped></style>
