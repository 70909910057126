<template>
	<Icon
		name="question-circle"
		class="help"
		:size="size"
		v-bind="$attrs"
		:style="checkedStyle"
		v-tooltip.right="text"
	></Icon>
</template>
<script>
import Box from '@/shared/layout/box/layout-box.vue'
import Icon from '@/shared/components/icon/icon.vue'

export default {
	name: 'Help',
	props: {
		size: {
			type: String,
			default: '1x'
		},
		color: {
			type: String,
			default: '#385da3'
		},
		text: {
			type: String,
			default: ''
		}
	},
	computed: {
		checkedStyle: function() {
			return {
				color: this.color
			}
		}
	},
	components: {
		Box,
		Icon
	}
}
</script>

<style lang="scss" scoped>
.help {
	cursor: pointer;
	display: inline-block;
	padding-right: 3px;
	margin-left: 5px;
}
</style>
