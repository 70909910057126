<template>
	<td>
		<slot></slot>
	</td>
</template>

<script>
export default {
	name: 'TableCell',
	props: ['label']
}
</script>

<style scoped>
td {
	border-bottom: 2px solid #f9f9f9;
}
td,
th {
	padding: 8px 12px 8px 5px;
	background: white;
	font-size: 13px;
}
</style>
