import Vue from 'vue';
import { formatMoney, getField } from '@/shared/util';
const MoneyCell = Vue.extend({
    props: {
        field: String,
        dataItem: Object
    },
    methods: {
        formatAmount() {
            const money = getField(this.dataItem, this.field);
            if (money === null || money === undefined)
                return '';
            return formatMoney(money, this.dataItem['currency']);
        }
    }
});
export default MoneyCell;
