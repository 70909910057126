var _a;
import { __decorate, __metadata } from "tslib";
import FancySelect from '@/shared/ui/forms/fancy-select';
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
let UserLookup = class UserLookup extends Vue {
    value;
    users;
    clearable;
    resetAfter;
    loading;
    multiple;
    placeholder;
};
__decorate([
    Prop({ type: [Object, Array] }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], UserLookup.prototype, "value", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Array)
], UserLookup.prototype, "users", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], UserLookup.prototype, "clearable", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], UserLookup.prototype, "resetAfter", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], UserLookup.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Boolean)
], UserLookup.prototype, "multiple", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], UserLookup.prototype, "placeholder", void 0);
UserLookup = __decorate([
    Component({
        components: {
            FancySelect
        }
    })
], UserLookup);
export default UserLookup;
