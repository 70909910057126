<template functional>
	<component
		:class="[...$options.classes(props), data.staticClass, data.class]"
		:style="[...$options.styles(props), data.staticStyle, data.style]"
		v-on="listeners"
		v-bind="data.attrs"
		:is="props.tag"
	>
		<slot></slot>
	</component>
</template>
<script>
import boxWhitespace from '../css/boxWhitespace.module.css'
import layout from '../css/layout.module.css'

export default {
	props: {
		display: {
			type: String,
			default: 'block'
		},
		height: {
			type: String
		},
		tag: {
			type: String,
			default: 'div'
		},
		paddingX: {
			type: Number
		},
		paddingY: {
			type: Number
		},
		marginRight: {
			type: Number
		},
		marginLeft: {
			type: Number
		},
		marginTop: {
			type: Number
		},
		marginBottom: {
			type: Number
		},
		paddingRight: {
			type: Number
		},
		paddingLeft: {
			type: Number
		},
		paddingTop: {
			type: Number
		},
		paddingBottom: {
			type: Number
		},
		padding: {
			type: Number
		},
		margin: {
			type: Number
		},
		flex: {
			type: [String, Number]
		},
		flexDirection: {
			type: String
		},
		flexAlign: {
			type: String
		},
		justifyContent: {
			type: String
		}
	},
	name: 'Box',
	classes(props) {
		const classes = []
		if (props.display) {
			classes.push(layout[props.display])
		}

		if (props.paddingX) {
			classes.push(boxWhitespace[`paddingX${props.paddingX}`])
		}
		if (props.paddingY) {
			classes.push(boxWhitespace[`paddingY${props.paddingY}`])
		}

		if (props.marginRight) {
			classes.push(boxWhitespace[`marginRight${props.marginRight}`])
		}
		if (props.marginLeft) {
			classes.push(boxWhitespace[`marginLeft${props.marginLeft}`])
		}
		if (props.marginTop) {
			classes.push(boxWhitespace[`marginTop${props.marginTop}`])
		}
		if (props.marginBottom) {
			classes.push(boxWhitespace[`marginBottom${props.marginBottom}`])
		}

		if (props.paddingRight) {
			classes.push(boxWhitespace[`paddingRight${props.paddingRight}`])
		}
		if (props.paddingLeft) {
			classes.push(boxWhitespace[`paddingLeft${props.paddingLeft}`])
		}
		if (props.paddingTop) {
			classes.push(boxWhitespace[`paddingTop${props.paddingTop}`])
		}
		if (props.paddingBottom) {
			classes.push(boxWhitespace[`paddingBottom${props.paddingBottom}`])
		}

		if (props.padding) {
			classes.push(boxWhitespace[`padding${props.padding}`])
		}
		if (props.margin) {
			classes.push(boxWhitespace[`margin${props.margin}`])
		}

		return classes
	},
	styles(props) {
		const styles = []

		if (props.flex) {
			styles.push({
				flex: props.flex
			})
		}
		if (props.display == 'flex') {
			styles.push({
				alignItems: props.flexAlign
			})
		}
		if (props.justifyContent) {
			styles.push({
				justifyContent: props.justifyContent
			})
		}
		if (props.flexDirection) {
			styles.push({
				flexDirection: props.flexDirection
			})
		}
		if (props.height) {
			styles.push({
				height: props.height
			})
		}
		return styles
	}
}
</script>
