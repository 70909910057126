import { __decorate, __metadata } from "tslib";
import { Vue, Component, Inject } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import { RouterLink } from 'vue-component-router';
import Icon from '@/shared/components/icon/icon.vue';
import DonorLookup from '@/shared/components/payment-form/donor-lookup';
let GlobalSearch = class GlobalSearch extends Vue {
    router;
    version = 1;
    handleInput(value) {
        if (!value || !value.id)
            return;
        this.version++;
        this.router.history.push(`/household/${value.id}/summary`);
    }
};
__decorate([
    Inject('router'),
    __metadata("design:type", Object)
], GlobalSearch.prototype, "router", void 0);
GlobalSearch = __decorate([
    Component({
        components: {
            Box,
            Icon,
            RouterLink,
            DonorLookup
        }
    })
], GlobalSearch);
export default GlobalSearch;
