import set from 'lodash/set';
import Vue from 'vue';
export default Vue.extend({
    props: {
        tabId: {}
    },
    inject: ['tabs'],
    render(h) {
        if (!this.$scopedSlots.default)
            return h();
        const vnodes = this.$scopedSlots.default({
            active: this.tabs.tabData.activeTab === this.tabId
        });
        if (!vnodes)
            return h();
        const vnode = vnodes.length > 1 ? h('div', [vnodes]) : vnodes[0];
        set(vnode, 'data.on.click', () => this.tabs.selectTab(this.tabId));
        set(vnode, 'data.nativeOn.click', () => this.tabs.selectTab(this.tabId));
        return vnode;
    }
});
