import graphql from '@/shared/graphql';
import mutate from '@/shared/graphql/mutate';
import { PaymentType } from '@/shared/schema/globalTypes';
import { monthDayYearToDate, dateToMonthDayYear } from '@/shared/util';
import { dateScalarToDate } from '@velocity/shared/src/utils';
import { acceptProps, compose } from 'vue-compose';
import EditPayment from './edit-payment-form.vue';
import LOAD_PAYMENT from './load-payment.graphql';
import UNVOID_PAYMENT from './unvoid-payment.graphql';
import UPDATE_PAYMENT_TYPE from './update-payment-type.graphql';
import UPDATE_PAYMENT from './update-payment.graphql';
import VOID_PAYMENT from './void-payment.graphql';
import { withCurrentUser } from '@/shared/authentication';
import DELETE_PAYMENT from './delete-payment.graphql';
import GET_HOUSEHOLD_GIVING_HISTORY from '@/pages/household/pages/giving/get-household-giving-history.graphql';
import MOVE_PAYMENT from './move-payment.graphql';
const updatePayment = mutate(UPDATE_PAYMENT, {
    props(props) {
        return {
            processing: props.loading,
            async updatePayment(form) {
                const checkUpdateData = form.type === PaymentType.Check
                    ? {
                        check: {
                            checkNum: form.checkNum,
                            frontScan: form.frontScan?.file || null,
                            backScan: form.backScan?.file || null,
                            receivedDate: form.receivedDate
                                ? monthDayYearToDate(form.receivedDate).toISOString()
                                : null
                        }
                    }
                    : {};
                const creditCardUpdateData = [
                    PaymentType.CreditCard,
                    PaymentType.ACH,
                    PaymentType.ApplePay,
                    PaymentType.Bit,
                    PaymentType.GooglePay,
                    PaymentType.Paypal,
                    PaymentType.Venmo
                ].includes(form.type) && form.transactionID
                    ? { creditCard: { transactionID: form.transactionID } }
                    : {};
                const stockUpdateData = form.type === PaymentType.Stock
                    ? {
                        stock: {
                            dateOfSold: monthDayYearToDate(form.dateOfSold).toISOString(),
                            dayHigh: form.dayHigh,
                            dayLow: form.dayLow,
                            howItSold: form.howItSold,
                            numberOfShares: form.numberOfShares,
                            stockSymbol: form.stockSymbol
                        }
                    }
                    : {};
                const wireData = form.type === PaymentType.Wire
                    ? {
                        wire: {
                            wireBank: form.wireBank
                        }
                    }
                    : {};
                const cryptoData = form.type === PaymentType.Crypto
                    ? {
                        crypto: {
                            cryptoType: form.cryptoType,
                            exchangeRate: form.exchangeRate,
                            bank: form.bank
                        }
                    }
                    : {};
                return props.mutate({
                    variables: {
                        data: {
                            id: props.paymentId,
                            amount: form.amount.amount,
                            batchId: form.batch.id,
                            date: monthDayYearToDate(form.date).valueOf(),
                            notes: form.notes,
                            category: form.category,
                            DAFName: form.DAFName,
                            IRAName: form.IRAName,
                            MatchingGiftName: form.MatchingGiftName,
                            voidReason: form.voidReason,
                            sendReceiptVia: form.sendReceipt ? form.sendReceiptVia : null,
                            applications: form.applications.map(app => ({
                                paymentId: app.paymentId,
                                pledgeId: app.pledgeId,
                                amount: app.originalAmount ?? app.amount,
                                date: monthDayYearToDate(form.date).valueOf(),
                                currency: app.currency
                            })),
                            channel: form.channel || null,
                            ...checkUpdateData,
                            ...creditCardUpdateData,
                            ...stockUpdateData,
                            ...wireData,
                            ...cryptoData
                        }
                    }
                });
            }
        };
    }
});
const updatePaymentType = mutate(UPDATE_PAYMENT_TYPE, {
    props(props) {
        return {
            processing: props.processing || props.loading,
            async updatePaymentType(data) {
                return props.mutate({
                    variables: { data }
                });
            }
        };
    }
});
const voidPayment = mutate(VOID_PAYMENT, {
    props(props) {
        return {
            processing: props.processing || props.loading,
            async voidPayment(paymentId, data) {
                return props.mutate({
                    variables: {
                        paymentId,
                        data: {
                            markPledgesUncollectible: data.markPledgesUncollectible,
                            reason: data.reason,
                            reasonFile: data.reasonFile,
                            uncollectibleReason: data.uncollectibleReason
                        }
                    }
                });
            }
        };
    }
});
const unvoidPayment = mutate(UNVOID_PAYMENT, {
    props(props) {
        return {
            processing: props.processing || props.loading,
            async unvoidPayment(paymentId) {
                return props.mutate({
                    variables: {
                        paymentId
                    }
                });
            }
        };
    }
});
const loadPayment = graphql(LOAD_PAYMENT, {
    skip(props) {
        return !props.paymentId;
    },
    options(props) {
        return {
            variables: {
                id: props.paymentId
            }
        };
    },
    props(props) {
        if (!props.data || props.loading || !props.data.payment)
            return null;
        const payment = props.data.payment;
        const transactionID = [
            PaymentType.CreditCard,
            PaymentType.ChargeCard,
            PaymentType.ACH,
            PaymentType.ApplePay,
            PaymentType.Bit,
            PaymentType.GooglePay,
            PaymentType.Paypal,
            PaymentType.Venmo
        ].includes(payment.type) &&
            'creditCard' in payment &&
            payment.creditCard
            ? payment.creditCard.transactionID
            : null;
        return {
            payment: {
                referrer: null,
                ...payment,
                transactionID,
                DAFName: props.data.payment.DAFName,
                IRAName: props.data.payment.IRAName,
                MatchingGiftName: props.data.payment.MatchingGiftName,
                batch: props.data.payment.batch
                    ? {
                        id: props.data.payment.batch.id,
                        label: props.data.payment.batch.name
                    }
                    : null,
                batchDate: (props.data.payment?.batch?.date && dateScalarToDate(props.data.payment.batch.date)) || null,
                date: dateToMonthDayYear(dateScalarToDate(props.data.payment.date)),
                donorName: payment.donor.name,
                donorId: payment.donor.id,
                shortId: payment.shortId,
                receiptSent: payment.applications.filter(d => d.receiptSent !== null).map(app => app.receiptSent)[0] || null,
                applications: payment.applications.map(app => ({
                    department: app.pledge.department,
                    amount: app.amount,
                    originalAmount: app.originalAmount || app.amount,
                    date: payment.date,
                    currency: app.currency,
                    campaign: app.pledge.campaign
                        ? {
                            id: app.pledge.campaign.id,
                            label: app.pledge.campaign.name
                        }
                        : null,
                    pledgeId: app.pledge.id,
                    paymentId: payment.id
                })),
                check: payment.__typename === 'CheckPayment'
                    ? {
                        ...payment.check,
                        receivedDate: payment.check.receivedDate
                            ? dateToMonthDayYear(dateScalarToDate(payment.check.receivedDate))
                            : null
                    }
                    : null,
                wireBank: payment.__typename === 'WirePayment' ? payment.wireBank : null,
                stock: payment.__typename === 'StockPayment'
                    ? {
                        ...payment.stock,
                        dateOfSold: payment.stock.dateOfSold
                            ? dateToMonthDayYear(dateScalarToDate(payment.stock.dateOfSold))
                            : null
                    }
                    : null,
                crypto: (payment.__typename === 'CryptoPayment' && payment.crypto) || null
            }
        };
    }
});
const deletePayment = mutate(DELETE_PAYMENT, {
    props(props) {
        return {
            processing: props.processing || props.loading,
            async deletePayment(paymentId, withPledges) {
                return props.mutate({
                    update(client) {
                        if (props.payment.donorId) {
                            try {
                                const donorGivings = client.readQuery({
                                    query: GET_HOUSEHOLD_GIVING_HISTORY,
                                    variables: {
                                        id: props.payment.donorId
                                    }
                                });
                                if (donorGivings &&
                                    donorGivings.householdData &&
                                    donorGivings.householdData.pledges &&
                                    props.payment.applications) {
                                    const pledges = props.payment.applications.map(app => app.pledgeId);
                                    donorGivings.householdData.pledges = donorGivings.householdData.pledges.map(p => {
                                        if (pledges.indexOf(p.id) === -1)
                                            return p;
                                        return {
                                            ...p,
                                            totalPaid: p.totalPaid - props.payment.amount,
                                            applications: p.applications.filter(app => app.payment.id !== paymentId)
                                        };
                                    });
                                    if (withPledges) {
                                        donorGivings.householdData.pledges = donorGivings.householdData.pledges.filter(p => pledges.indexOf(p.id) === -1 || p.applications.length);
                                    }
                                    client.writeQuery({
                                        query: GET_HOUSEHOLD_GIVING_HISTORY,
                                        variables: {
                                            id: props.payment.donorId
                                        },
                                        data: donorGivings
                                    });
                                }
                            }
                            catch (e) { }
                        }
                    },
                    variables: {
                        paymentId,
                        withPledges
                    }
                });
            }
        };
    }
});
const movePayment = mutate(MOVE_PAYMENT, {
    props(props) {
        return {
            processing: props.processing || props.loading,
            async movePayment(paymentId, donorId, withPledges) {
                return props.mutate({
                    update(client) {
                        if (props.payment.donorId) {
                            try {
                                const donorGivings = client.readQuery({
                                    query: GET_HOUSEHOLD_GIVING_HISTORY,
                                    variables: {
                                        id: props.payment.donorId
                                    }
                                });
                                if (donorGivings &&
                                    donorGivings.householdData &&
                                    donorGivings.householdData.pledges &&
                                    props.payment.applications) {
                                    const pledges = props.payment.applications.map(app => app.pledgeId);
                                    donorGivings.householdData.pledges = donorGivings.householdData.pledges.map(p => {
                                        if (pledges.indexOf(p.id) === -1)
                                            return p;
                                        return {
                                            ...p,
                                            totalPaid: p.totalPaid - props.payment.amount,
                                            applications: p.applications.filter(app => app.payment.id !== paymentId)
                                        };
                                    });
                                    if (withPledges) {
                                        donorGivings.householdData.pledges = donorGivings.householdData.pledges.filter(p => pledges.indexOf(p.id) === -1 || p.applications.length);
                                    }
                                    client.writeQuery({
                                        query: GET_HOUSEHOLD_GIVING_HISTORY,
                                        variables: {
                                            id: props.payment.donorId
                                        },
                                        data: donorGivings
                                    });
                                }
                            }
                            catch (e) { }
                        }
                    },
                    variables: { paymentId, donorId, withPledges }
                });
            }
        };
    }
});
export default compose(withCurrentUser, acceptProps(['paymentId', 'payment', 'pledgeCurrency']), updatePayment, updatePaymentType, voidPayment, unvoidPayment, loadPayment, deletePayment, movePayment)(EditPayment);
