import { __decorate, __metadata } from "tslib";
import PledgeUpdateAuditItem from '../pledge-update-audit-item.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import Card from '@/shared/ui/card/card.vue';
import { formatDate } from '@/shared/util';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import DateRangePicker from '@/shared/ui/forms/date-picker/date-range-picker.vue';
import UserLookup from '@/shared/components/user-lookup';
import { formatMoney } from '@/shared/util';
let SmallPledgeAuditLog = class SmallPledgeAuditLog extends Vue {
    first;
    tail;
    sliced;
    formatDate = formatDate;
    formatMoney = formatMoney;
    type = null;
    userName(item) {
        return item.user ? `${item.user.firstName} ${item.user.lastName}` : '<System>';
    }
};
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], SmallPledgeAuditLog.prototype, "first", void 0);
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], SmallPledgeAuditLog.prototype, "tail", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], SmallPledgeAuditLog.prototype, "sliced", void 0);
SmallPledgeAuditLog = __decorate([
    Component({
        components: {
            Box,
            PledgeUpdateAuditItem,
            UserLookup,
            UISelect,
            Card,
            layoutText,
            DateRangePicker
        }
    })
], SmallPledgeAuditLog);
export default SmallPledgeAuditLog;
