import { render, staticRenderFns } from "./layout-form-row.vue?vue&type=template&id=0cefef68&scoped=true"
import script from "./layout-form-row.vue?vue&type=script&lang=js"
export * from "./layout-form-row.vue?vue&type=script&lang=js"
import style0 from "./layout-form-row.vue?vue&type=style&index=0&id=0cefef68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cefef68",
  null
  
)

export default component.exports