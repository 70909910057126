<template>
	<Box v-bind="$attrs" class="notification" :class="[state, { show: isShow }]" v-if="!destroy">
		{{ text }}
		<span class="close" @click="() => (isShow = false)">
			<Icon name="times" />
		</span>
	</Box>
</template>
<script>
import Box from '@/shared/layout/box/layout-box.vue'
import Icon from '@/shared/components/icon/icon.vue'

export default {
	name: 'Toastr',
	props: {
		text: {
			type: String
		},
		state: {
			type: String,
			default: 'success'
		}
	},
	data() {
		return {
			isShow: true,
			destroy: false
		}
	},
	components: {
		Box,
		Icon
	},
	mounted() {
		setTimeout(() => {
			this.isShow = false
		}, 10000)
		setTimeout(() => {
			this.destroy = true
		}, 11000)
	}
}
</script>

<style lang="scss" scoped>
.notification {
	position: fixed;
	top: 20px;
	right: 30px;
	width: 400px;
	border-radius: 4px;
	padding: 20px 20px;
	background: #f7f7f7;
	border: 1px solid #efefef;
	box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
	opacity: 0;
	z-index: 1000;

	&.show {
		opacity: 1;
	}
	&.success {
		color: #659065;
		background: #c4f9b7;
		border: 1px solid #afdea8;
	}
	&.error {
		color: #a05656;
		background: #ffbaba;
		border: 1px solid #ecafaf;
	}

	.close {
		position: absolute;
		font-size: 10px;
		cursor: pointer;
		top: 5px;
		right: 8px;
	}
}
</style>
