import { monthDayYearToDate } from '@/shared/util';
function convertTimeStampOrDatetoTimestamp(input) {
    if (!input)
        return null;
    if (typeof input == 'number')
        return input;
    if (typeof input == 'object') {
        return monthDayYearToDate(input).valueOf();
    }
    if (isNaN(Number(input)))
        return new Date(input).valueOf();
    return Number(input);
}
export function extractContactInfoFromPerson(person, primaryAddress, primaryPhone, primaryEmail) {
    return {
        addresses: person.addresses.map(address => {
            let createdBy = address.audit.createdBy || { firstName: 'System', lastName: '' };
            return {
                ...address,
                primary: primaryAddress === address.id,
                owner: {
                    id: person.id,
                    name: `${person.firstName} ${person.lastName}`
                },
                audit: {
                    createdAt: convertTimeStampOrDatetoTimestamp(address.audit.createdAt),
                    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
                    updatedAt: convertTimeStampOrDatetoTimestamp(address.audit.updatedAt),
                    updatedBy: address.audit.updatedBy
                        ? `${address.audit.updatedBy.firstName} ${address.audit.updatedBy.lastName}`
                        : ''
                }
            };
        }),
        emails: person.emails.map(email => {
            let createdBy = email.audit.createdBy || { firstName: 'System', lastName: '' };
            return {
                ...email,
                primary: primaryEmail === email.id,
                owner: {
                    name: `${person.firstName} ${person.lastName}`,
                    id: person.id
                },
                audit: {
                    createdAt: convertTimeStampOrDatetoTimestamp(email.audit.createdAt),
                    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
                    updatedAt: convertTimeStampOrDatetoTimestamp(email.audit.updatedAt),
                    updatedBy: email.audit.updatedBy
                        ? `${email.audit.updatedBy.firstName} ${email.audit.updatedBy.lastName}`
                        : ''
                }
            };
        }),
        phones: person.phones.map(phone => {
            let createdBy = phone.audit.createdBy || { firstName: 'System', lastName: '' };
            return {
                ...phone,
                primary: primaryPhone === phone.id,
                owner: {
                    id: person.id,
                    name: `${person.firstName} ${person.lastName}`
                },
                description: phone.notes,
                phone: phone.phone,
                ext: phone.extension,
                audit: {
                    createdAt: convertTimeStampOrDatetoTimestamp(phone.audit.createdAt),
                    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
                    updatedAt: convertTimeStampOrDatetoTimestamp(phone.audit.updatedAt),
                    updatedBy: phone.audit.updatedBy
                        ? `${phone.audit.updatedBy.firstName} ${phone.audit.updatedBy.lastName}`
                        : ''
                }
            };
        })
    };
}
export function extractContactInfoFromDonor(donor) {
    return {
        addresses: donor.addresses.map(address => {
            let createdBy = address.audit.createdBy || { firstName: 'System', lastName: '' };
            return {
                ...address,
                primary: donor.primaryAddressID == address.id,
                owner: {
                    id: donor.id,
                    name: donor.name
                },
                audit: {
                    createdAt: Number(address.audit.createdAt),
                    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
                    updatedAt: Number(address.audit.updatedAt),
                    updatedBy: address.audit.updatedBy
                        ? `${address.audit.updatedBy.firstName} ${address.audit.updatedBy.lastName}`
                        : ''
                }
            };
        }),
        emails: donor.emails.map(email => {
            let createdBy = email.audit.createdBy || { firstName: 'System', lastName: '' };
            return {
                ...email,
                primary: donor.primaryEmailID == email.id,
                owner: {
                    name: donor.name,
                    id: donor.id
                },
                audit: {
                    createdAt: Number(email.audit.createdAt),
                    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
                    updatedAt: Number(email.audit.updatedAt),
                    updatedBy: email.audit.updatedBy
                        ? `${email.audit.updatedBy.firstName} ${email.audit.updatedBy.lastName}`
                        : ''
                }
            };
        }),
        phones: donor.phones.map(phone => {
            let createdBy = phone.audit.createdBy || { firstName: 'System', lastName: '' };
            return {
                ...phone,
                primary: donor.primaryPhoneID == phone.id,
                owner: {
                    id: donor.id,
                    name: donor.name
                },
                description: phone.notes,
                phone: phone.phone,
                ext: phone.extension,
                audit: {
                    createdAt: Number(phone.audit.createdAt),
                    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
                    updatedAt: Number(phone.audit.updatedAt),
                    updatedBy: phone.audit.updatedBy
                        ? `${phone.audit.updatedBy.firstName} ${phone.audit.updatedBy.lastName}`
                        : ''
                }
            };
        })
    };
}
