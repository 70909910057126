import GrantLookup from './grant-lookup.vue';
import { GrantStatus } from '@/shared/schema/globalTypes';
import GET_GRANTS_FOR_LOOKUP from './get-grants-for-lookup.graphql';
import query from '@/shared/graphql/query';
import { compose, acceptProps } from 'vue-compose';
import sumBy from 'lodash/sumBy';
const loadGrants = query(GET_GRANTS_FOR_LOOKUP, {
    options(props) {
        return {
            variables: {
                id: props.donorId
            }
        };
    },
    props(props) {
        if (!props.data || !props.data.donor || props.loading) {
            return {
                grants: [],
                loading: true
            };
        }
        if (props.data.donor.__typename !== 'Foundation') {
            return {
                loading: false,
                grants: []
            };
        }
        return {
            loading: false,
            grants: props.data.donor.grants
                .filter(x => x.status == GrantStatus.Approved &&
                (sumBy(x.pledges, x => x.totalPaid) < x.amountApproved || x.amountApproved == 0))
                .map(g => ({
                amount: g.amountApproved,
                currency: g.currency,
                description: g.projectName || 'No Project Name',
                id: g.id
            }))
        };
    }
});
export default compose(loadGrants, acceptProps(['donorId']))(GrantLookup);
