<template>
	<tr>
		<slot></slot>
	</tr>
</template>

<script>
export default {
	name: 'TableRow',
	props: ['label']
}
</script>

<style scoped>
td {
	border-bottom: 1px solid #ebedee;
	padding: 15px 15px;
	font-weight: 200;
}
.condensed td {
	padding: 8px 15px 8px 5px;
}
tr:hover td {
	background: #f7f7f7;
}
.noHover tr:hover td {
	background: transparent;
}
.zebra tr:nth-child(even) td {
	background: #f8f9fa;
}
</style>
