import GET_CAMPAIGNS from './get-campaigns.graphql';
import graphql from '@/shared/graphql';
import { compose, acceptProps } from 'vue-compose';
import CampaignLookup from './campaign-lookup.vue';
const getCampaigns = graphql(GET_CAMPAIGNS, {
    skip: props => !props.department && !props.forceLoad,
    options(props) {
        const filters = {};
        if (Array.isArray(props.department))
            filters.departments = props.department;
        else if (props.department)
            filters.department = props.department;
        if (props.includeSubCampaigns) {
            filters.includeSubCampaigns = true;
        }
        return {
            variables: {
                filters
            }
        };
    },
    props(props) {
        const valueIds = props.value
            ? typeof props.value === 'string'
                ? [props.value]
                : Array.isArray(props.value)
                    ? props.value.map(x => (typeof x === 'string' ? x : x.id))
                    : [props.value.id]
            : null;
        return {
            loading: props.loading,
            campaigns: !props.loading && props.data && props.data.campaigns
                ? props.data.campaigns.records
                    .filter(x => x.active || (valueIds && valueIds.includes(x.id)))
                    .map(x => ({
                    id: x.id,
                    label: props.department ? x.name : `${x.department} / ${x.name}`
                }))
                : []
        };
    }
});
export default compose(getCampaigns, acceptProps(['department']))(CampaignLookup);
