import { getOptions, normalizeProps, rootFieldFromDocumentNode } from '@/shared/util';
import Vue from 'vue';
export default function mutate(query, { options, props } = {}) {
    const rootField = rootFieldFromDocumentNode(query);
    return function GraphqlHOC(WrappedComponent) {
        const childProps = normalizeProps(getOptions(WrappedComponent).props);
        delete childProps[rootField];
        return Vue.extend({
            props: childProps,
            data() {
                return {
                    mutating: false
                };
            },
            name: `${getOptions(WrappedComponent).name}WithMutation`,
            render(h) {
                const ownProps = Object.assign({}, this.$props);
                const optionsFromProps = options ? options(ownProps) : {};
                const mutate = async (mutationOptions) => {
                    const options = {
                        mutation: query,
                        ...optionsFromProps,
                        ...mutationOptions
                    };
                    this.mutating = true;
                    //deliberately put any here as it is going to be eventually removed anyway
                    return this.$apollo.mutate(options).finally(() => {
                        this.mutating = false;
                    });
                };
                const propsToSend = Object.assign(ownProps, {
                    mutate,
                    loading: this.mutating || !!this.loading
                });
                return h(WrappedComponent, {
                    on: this.$listeners,
                    scopedSlots: this.$scopedSlots,
                    props: Object.assign({}, ownProps, props ? props.call(this, propsToSend) : {})
                });
            }
        });
    };
}
