export var GQLActivistActionCategory;
(function (GQLActivistActionCategory) {
    GQLActivistActionCategory["BeingBroadcaster"] = "BeingBroadcaster";
    GQLActivistActionCategory["CreateTestimonialVideos"] = "CreateTestimonialVideos";
    GQLActivistActionCategory["General"] = "General";
    GQLActivistActionCategory["HostParlorMeeting"] = "HostParlorMeeting";
    GQLActivistActionCategory["ShulSchoolLiaisons"] = "ShulSchoolLiaisons";
})(GQLActivistActionCategory || (GQLActivistActionCategory = {}));
export var GQLActivistCategory;
(function (GQLActivistCategory) {
    GQLActivistCategory["ExecutiveCommittee"] = "ExecutiveCommittee";
    GQLActivistCategory["LeadershipCircle"] = "LeadershipCircle";
    GQLActivistCategory["NationalSteeringCommittee"] = "NationalSteeringCommittee";
})(GQLActivistCategory || (GQLActivistCategory = {}));
export var GQLActivityType;
(function (GQLActivityType) {
    GQLActivityType["Deadline"] = "Deadline";
    GQLActivityType["Email"] = "Email";
    GQLActivityType["Event"] = "Event";
    GQLActivityType["Gift"] = "Gift";
    GQLActivityType["Mail"] = "Mail";
    GQLActivityType["Meeting"] = "Meeting";
    GQLActivityType["PhoneCall"] = "PhoneCall";
    GQLActivityType["Receipt"] = "Receipt";
    GQLActivityType["Reminder"] = "Reminder";
    GQLActivityType["Task"] = "Task";
    GQLActivityType["Text"] = "Text";
    GQLActivityType["ThankYou"] = "ThankYou";
})(GQLActivityType || (GQLActivityType = {}));
export var GQLAddressType;
(function (GQLAddressType) {
    GQLAddressType["Home"] = "Home";
    GQLAddressType["Other"] = "Other";
    GQLAddressType["Summer"] = "Summer";
    GQLAddressType["Work"] = "Work";
})(GQLAddressType || (GQLAddressType = {}));
export var GQLAssociatedPersonPosition;
(function (GQLAssociatedPersonPosition) {
    GQLAssociatedPersonPosition["Admin"] = "Admin";
    GQLAssociatedPersonPosition["BoardMember"] = "BoardMember";
    GQLAssociatedPersonPosition["BoardPresident"] = "BoardPresident";
    GQLAssociatedPersonPosition["DirectorOfFinance"] = "Director_of_Finance";
    GQLAssociatedPersonPosition["ExecutiveDirector"] = "ExecutiveDirector";
    GQLAssociatedPersonPosition["HeadOfSchool"] = "Head_of_School";
    GQLAssociatedPersonPosition["Parent"] = "Parent";
    GQLAssociatedPersonPosition["SchoolPresident"] = "SchoolPresident";
    GQLAssociatedPersonPosition["SupportStaff"] = "SupportStaff";
    GQLAssociatedPersonPosition["Teacher"] = "Teacher";
})(GQLAssociatedPersonPosition || (GQLAssociatedPersonPosition = {}));
export var GQLBcLevelStatus;
(function (GQLBcLevelStatus) {
    GQLBcLevelStatus["Downgraded"] = "Downgraded";
    GQLBcLevelStatus["New"] = "New";
    GQLBcLevelStatus["Retained"] = "Retained";
    GQLBcLevelStatus["Upgraded"] = "Upgraded";
})(GQLBcLevelStatus || (GQLBcLevelStatus = {}));
export var GQLBcLevelType;
(function (GQLBcLevelType) {
    GQLBcLevelType["Cy"] = "CY";
    GQLBcLevelType["Fy"] = "FY";
})(GQLBcLevelType || (GQLBcLevelType = {}));
export var GQLBillingPlanDistributionType;
(function (GQLBillingPlanDistributionType) {
    GQLBillingPlanDistributionType["ByAmount"] = "ByAmount";
    GQLBillingPlanDistributionType["ByQuantity"] = "ByQuantity";
    GQLBillingPlanDistributionType["Custom"] = "Custom";
})(GQLBillingPlanDistributionType || (GQLBillingPlanDistributionType = {}));
export var GQLBillingPlanInterval;
(function (GQLBillingPlanInterval) {
    GQLBillingPlanInterval["BiWeekly"] = "BiWeekly";
    GQLBillingPlanInterval["Monthly"] = "Monthly";
    GQLBillingPlanInterval["Weekly"] = "Weekly";
    GQLBillingPlanInterval["Yearly"] = "Yearly";
})(GQLBillingPlanInterval || (GQLBillingPlanInterval = {}));
export var GQLBoardMemberTerm;
(function (GQLBoardMemberTerm) {
    GQLBoardMemberTerm["Apr2003Jun2004"] = "Apr2003Jun2004";
    GQLBoardMemberTerm["Aug2012Mar2013"] = "Aug2012Mar2013";
    GQLBoardMemberTerm["Dec2013Jan2014"] = "Dec2013Jan2014";
    GQLBoardMemberTerm["Dec2015Dec2016"] = "Dec2015Dec2016";
    GQLBoardMemberTerm["Jan2017Dec2018"] = "Jan2017Dec2018";
    GQLBoardMemberTerm["Jan2017Jul2017"] = "Jan2017Jul2017";
    GQLBoardMemberTerm["Jan2017Mar2017"] = "Jan2017Mar2017";
    GQLBoardMemberTerm["Jan2019Dec2020"] = "Jan2019Dec2020";
    GQLBoardMemberTerm["Jan2021Dec2022"] = "Jan2021Dec2022";
    GQLBoardMemberTerm["Jan2023Dec2024"] = "Jan2023Dec2024";
    GQLBoardMemberTerm["Jul1997Aug1998"] = "Jul1997Aug1998";
    GQLBoardMemberTerm["Jul1997Jun1998"] = "Jul1997Jun1998";
    GQLBoardMemberTerm["Jul1998Jun1999"] = "Jul1998Jun1999";
    GQLBoardMemberTerm["Jul1999Jun2000"] = "Jul1999Jun2000";
    GQLBoardMemberTerm["Jul2000Jun2001"] = "Jul2000Jun2001";
    GQLBoardMemberTerm["Jul2001Jun2002"] = "Jul2001Jun2002";
    GQLBoardMemberTerm["Jul2002Jun2003"] = "Jul2002Jun2003";
    GQLBoardMemberTerm["Jul2003Jun2004"] = "Jul2003Jun2004";
    GQLBoardMemberTerm["Jul2003Jun2016"] = "Jul2003Jun2016";
    GQLBoardMemberTerm["Jul2004Jun2005"] = "Jul2004Jun2005";
    GQLBoardMemberTerm["Jul2005Jun2006"] = "Jul2005Jun2006";
    GQLBoardMemberTerm["Jul2014Jun2015"] = "Jul2014Jun2015";
    GQLBoardMemberTerm["Jul2015Dec2016"] = "Jul2015Dec2016";
    GQLBoardMemberTerm["Jul2015Feb2016"] = "Jul2015Feb2016";
    GQLBoardMemberTerm["Jul2015Mar2016"] = "Jul2015Mar2016";
    GQLBoardMemberTerm["Jul2015Sep2016"] = "Jul2015Sep2016";
    GQLBoardMemberTerm["Jul2016Jun2016"] = "Jul2016Jun2016";
    GQLBoardMemberTerm["Jun2007Jul2008"] = "Jun2007Jul2008";
    GQLBoardMemberTerm["Jun2008Jul2009"] = "Jun2008Jul2009";
    GQLBoardMemberTerm["Jun2009Jul2010"] = "Jun2009Jul2010";
    GQLBoardMemberTerm["Jun2010Jul2011"] = "Jun2010Jul2011";
    GQLBoardMemberTerm["Jun2011Jul2012"] = "Jun2011Jul2012";
    GQLBoardMemberTerm["Mar2016Dec2016"] = "Mar2016Dec2016";
    GQLBoardMemberTerm["Sep1998Jun1999"] = "Sep1998Jun1999";
})(GQLBoardMemberTerm || (GQLBoardMemberTerm = {}));
export var GQLBroadcasterStatus;
(function (GQLBroadcasterStatus) {
    GQLBroadcasterStatus["Always"] = "Always";
    GQLBroadcasterStatus["Never"] = "Never";
    GQLBroadcasterStatus["Sometimes"] = "Sometimes";
})(GQLBroadcasterStatus || (GQLBroadcasterStatus = {}));
export var GQLCommission;
(function (GQLCommission) {
    GQLCommission["CommunalResearchCenter"] = "CommunalResearchCenter";
    GQLCommission["Communications"] = "Communications";
    GQLCommission["CommunityProjectsAndPartnerships"] = "CommunityProjects_and_Partnerships";
    GQLCommission["FinancialResourceDevelopment"] = "FinancialResourceDevelopment";
    GQLCommission["ImpactAccelerator"] = "ImpactAccelerator";
    GQLCommission["IsraelFreeSpirit"] = "IsraelFreeSpirit";
    GQLCommission["JewishAction"] = "JewishAction";
    GQLCommission["OuAdvocacy"] = "OU_Advocacy";
    GQLCommission["OuIsrael"] = "OU_Israel";
    GQLCommission["OuJlic"] = "OU_JLIC";
    GQLCommission["OuKosher"] = "OU_Kosher";
    GQLCommission["OuPress"] = "OU_Press";
    GQLCommission["SynagogueInitiative"] = "SynagogueInitiative";
    GQLCommission["TeachCoalition"] = "TeachCoalition";
    GQLCommission["TorahInitiatives"] = "TorahInitiatives";
    GQLCommission["WomensInitiatives"] = "Womens_Initiatives";
    GQLCommission["Yachad"] = "Yachad";
    GQLCommission["Youth"] = "Youth";
})(GQLCommission || (GQLCommission = {}));
export var GQLCommittee;
(function (GQLCommittee) {
    GQLCommittee["Audit"] = "Audit";
    GQLCommittee["Credentials"] = "Credentials";
    GQLCommittee["Executive"] = "Executive";
    GQLCommittee["Finance"] = "Finance";
    GQLCommittee["It"] = "IT";
    GQLCommittee["Investment"] = "Investment";
    GQLCommittee["Legal"] = "Legal";
    GQLCommittee["NominatingAndBoardResource"] = "Nominating_and_BoardResource";
    GQLCommittee["YoungLeadership"] = "YoungLeadership";
})(GQLCommittee || (GQLCommittee = {}));
export var GQLContactInfoType;
(function (GQLContactInfoType) {
    GQLContactInfoType["Address"] = "Address";
    GQLContactInfoType["Email"] = "Email";
    GQLContactInfoType["Phone"] = "Phone";
})(GQLContactInfoType || (GQLContactInfoType = {}));
export var GQLContactPreference;
(function (GQLContactPreference) {
    GQLContactPreference["Email"] = "Email";
    GQLContactPreference["Other"] = "Other";
    GQLContactPreference["Phone"] = "Phone";
    GQLContactPreference["SocialMedia"] = "Social_media";
    GQLContactPreference["WeeklyShabbatShalomNewsletter"] = "Weekly_shabbat_shalom_newsletter";
})(GQLContactPreference || (GQLContactPreference = {}));
export var GQLCreditCardType;
(function (GQLCreditCardType) {
    GQLCreditCardType["AmericanExpress"] = "AmericanExpress";
    GQLCreditCardType["Discover"] = "Discover";
    GQLCreditCardType["MasterCard"] = "MasterCard";
    GQLCreditCardType["Visa"] = "Visa";
})(GQLCreditCardType || (GQLCreditCardType = {}));
export var GQLCurrencyType;
(function (GQLCurrencyType) {
    GQLCurrencyType["Cad"] = "CAD";
    GQLCurrencyType["Nis"] = "NIS";
    GQLCurrencyType["Usd"] = "USD";
})(GQLCurrencyType || (GQLCurrencyType = {}));
export var GQLDepartment;
(function (GQLDepartment) {
    GQLDepartment["Advocacy"] = "Advocacy";
    GQLDepartment["BenZakkai"] = "BenZakkai";
    GQLDepartment["BringIsraelHome"] = "BringIsraelHome";
    GQLDepartment["CommunalResearch"] = "CommunalResearch";
    GQLDepartment["CommunityProjectsAndPartnerships"] = "CommunityProjectsAndPartnerships";
    GQLDepartment["ImpactAccelerator"] = "ImpactAccelerator";
    GQLDepartment["IsraelFreeSpirit"] = "IsraelFreeSpirit";
    GQLDepartment["Jlic"] = "JLIC";
    GQLDepartment["JlicAdminGroup"] = "JLIC_AdminGroup";
    GQLDepartment["JlicAriel"] = "JLIC_Ariel";
    GQLDepartment["JlicAtlanta"] = "JLIC_Atlanta";
    GQLDepartment["JlicBarIlan"] = "JLIC_BarIlan";
    GQLDepartment["JlicBenGurion"] = "JLIC_BenGurion";
    GQLDepartment["JlicBinghamton"] = "JLIC_Binghamton";
    GQLDepartment["JlicBostonUniversity"] = "JLIC_BostonUniversity";
    GQLDepartment["JlicBrandeis"] = "JLIC_Brandeis";
    GQLDepartment["JlicBrooklynCollege"] = "JLIC_BrooklynCollege";
    GQLDepartment["JlicCsun"] = "JLIC_CSUN";
    GQLDepartment["JlicChicago"] = "JLIC_Chicago";
    GQLDepartment["JlicColumbia"] = "JLIC_Columbia";
    GQLDepartment["JlicCornell"] = "JLIC_Cornell";
    GQLDepartment["JlicDrexel"] = "JLIC_Drexel";
    GQLDepartment["JlicIdcHerzliya"] = "JLIC_IDCHerzliya";
    GQLDepartment["JlicJerusalem"] = "JLIC_Jerusalem";
    GQLDepartment["JlicJohnsHopkins"] = "JLIC_JohnsHopkins";
    GQLDepartment["JlicKahal"] = "JLIC_Kahal";
    GQLDepartment["JlicMachonLev"] = "JLIC_MachonLev";
    GQLDepartment["JlicMachonTal"] = "JLIC_MachonTal";
    GQLDepartment["JlicMcGill"] = "JLIC_McGill";
    GQLDepartment["JlicMcMaster"] = "JLIC_McMaster";
    GQLDepartment["JlicMichigan"] = "JLIC_Michigan";
    GQLDepartment["JlicNyu"] = "JLIC_NYU";
    GQLDepartment["JlicPenn"] = "JLIC_Penn";
    GQLDepartment["JlicPrinceton"] = "JLIC_Princeton";
    GQLDepartment["JlicQueens"] = "JLIC_Queens";
    GQLDepartment["JlicRutgers"] = "JLIC_Rutgers";
    GQLDepartment["JlicSmc"] = "JLIC_SMC";
    GQLDepartment["JlicTau"] = "JLIC_TAU";
    GQLDepartment["JlicTlv"] = "JLIC_TLV";
    GQLDepartment["JlicTechnion"] = "JLIC_Technion";
    GQLDepartment["JlicUcla"] = "JLIC_UCLA";
    GQLDepartment["JlicUmass"] = "JLIC_UMASS";
    GQLDepartment["JlicUmd"] = "JLIC_UMD";
    GQLDepartment["JlicUwm"] = "JLIC_UWM";
    GQLDepartment["JlicUillinois"] = "JLIC_Uillinois";
    GQLDepartment["JlicWashingtonUniversity"] = "JLIC_WashingtonUniversity";
    GQLDepartment["JlicWestern"] = "JLIC_Western";
    GQLDepartment["JlicYale"] = "JLIC_Yale";
    GQLDepartment["JlicYavneh"] = "JLIC_Yavneh";
    GQLDepartment["JlicYork"] = "JLIC_York";
    GQLDepartment["JobBoard"] = "Job_Board";
    GQLDepartment["Ncsy"] = "NCSY";
    GQLDepartment["NcsyAdminGroup"] = "NCSY_AdminGroup";
    GQLDepartment["NcsyAlumni"] = "NCSY_Alumni";
    GQLDepartment["NcsyArgentina"] = "NCSY_Argentina";
    GQLDepartment["NcsyArizona"] = "NCSY_Arizona";
    GQLDepartment["NcsyAtlanta"] = "NCSY_Atlanta";
    GQLDepartment["NcsyAtlanticSeaboard"] = "NCSY_AtlanticSeaboard";
    GQLDepartment["NcsyBoston"] = "NCSY_Boston";
    GQLDepartment["NcsyBritishColumbia"] = "NCSY_BritishColumbia";
    GQLDepartment["NcsyCanada"] = "NCSY_Canada";
    GQLDepartment["NcsyCanadaGroup"] = "NCSY_CanadaGroup";
    GQLDepartment["NcsyCentralEast"] = "NCSY_CentralEast";
    GQLDepartment["NcsyCentralEastGroup"] = "NCSY_CentralEastGroup";
    GQLDepartment["NcsyChile"] = "NCSY_Chile";
    GQLDepartment["NcsyCleveland"] = "NCSY_Cleveland";
    GQLDepartment["NcsyIsrael"] = "NCSY_Israel";
    GQLDepartment["NcsyLaCity"] = "NCSY_LACity";
    GQLDepartment["NcsyLaValley"] = "NCSY_LAValley";
    GQLDepartment["NcsyLasVegas"] = "NCSY_LasVegas";
    GQLDepartment["NcsyMexico"] = "NCSY_Mexico";
    GQLDepartment["NcsyMidwest"] = "NCSY_Midwest";
    GQLDepartment["NcsyMidwestGroup"] = "NCSY_MidwestGroup";
    GQLDepartment["NcsyMontreal"] = "NCSY_Montreal";
    GQLDepartment["NcsyNynj"] = "NCSY_NYNJ";
    GQLDepartment["NcsyNationalGroup"] = "NCSY_NationalGroup";
    GQLDepartment["NcsyNewEngland"] = "NCSY_NewEngland";
    GQLDepartment["NcsyNewJersey"] = "NCSY_NewJersey";
    GQLDepartment["NcsyNewYork"] = "NCSY_NewYork";
    GQLDepartment["NcsyNorcal"] = "NCSY_Norcal";
    GQLDepartment["NcsyOrangeCounty"] = "NCSY_OrangeCounty";
    GQLDepartment["NcsyOttawa"] = "NCSY_Ottawa";
    GQLDepartment["NcsyParkCityUtah"] = "NCSY_ParkCityUtah";
    GQLDepartment["NcsyPortland"] = "NCSY_Portland";
    GQLDepartment["NcsyReliefMissions"] = "NCSY_ReliefMissions";
    GQLDepartment["NcsyStl"] = "NCSY_STL";
    GQLDepartment["NcsySanDiego"] = "NCSY_SanDiego";
    GQLDepartment["NcsySeattle"] = "NCSY_Seattle";
    GQLDepartment["NcsySouthern"] = "NCSY_Southern";
    GQLDepartment["NcsySouthwest"] = "NCSY_Southwest";
    GQLDepartment["NcsySummer"] = "NCSY_Summer";
    GQLDepartment["NcsyTristate"] = "NCSY_Tristate";
    GQLDepartment["NcsyUpstateNewYork"] = "NCSY_UpstateNewYork";
    GQLDepartment["NcsyVancouver"] = "NCSY_Vancouver";
    GQLDepartment["NcsyWestCoast"] = "NCSY_WestCoast";
    GQLDepartment["NcsyWestCoastAreaBGroup"] = "NCSY_WestCoastAreaBGroup";
    GQLDepartment["NcsyWestCoastGroup"] = "NCSY_WestCoastGroup";
    GQLDepartment["NextDor"] = "NextDor";
    GQLDepartment["NextGen"] = "NextGen";
    GQLDepartment["Ou"] = "OU";
    GQLDepartment["OuIsrael"] = "OUIsrael";
    GQLDepartment["OuKosher"] = "OUKosher";
    GQLDepartment["OuPress"] = "OUPress";
    GQLDepartment["OuGroup"] = "OU_Group";
    GQLDepartment["OuTorah"] = "OU_Torah";
    GQLDepartment["ReliefFunds"] = "ReliefFunds";
    GQLDepartment["SynagogueInitiatives"] = "SynagogueInitiatives";
    GQLDepartment["TeachCoalition"] = "TeachCoalition";
    GQLDepartment["TeachCoalitionCa"] = "TeachCoalition_CA";
    GQLDepartment["TeachCoalitionFl"] = "TeachCoalition_FL";
    GQLDepartment["TeachCoalitionMd"] = "TeachCoalition_MD";
    GQLDepartment["TeachCoalitionNj"] = "TeachCoalition_NJ";
    GQLDepartment["TeachCoalitionNv"] = "TeachCoalition_NV";
    GQLDepartment["TeachCoalitionNys"] = "TeachCoalition_NYS";
    GQLDepartment["TeachCoalitionPa"] = "TeachCoalition_PA";
    GQLDepartment["TeachCoalitionProjectProtect"] = "TeachCoalition_ProjectProtect";
    GQLDepartment["TeachAdminGroup"] = "Teach_AdminGroup";
    GQLDepartment["WestCoast"] = "WestCoast";
    GQLDepartment["WomensInitative"] = "WomensInitative";
    GQLDepartment["Yachad"] = "Yachad";
    GQLDepartment["YachadAdminGroup"] = "Yachad_AdminGroup";
    GQLDepartment["YachadBaltimore"] = "Yachad_Baltimore";
    GQLDepartment["YachadChicago"] = "Yachad_Chicago";
    GQLDepartment["YachadCleveland"] = "Yachad_Cleveland";
    GQLDepartment["YachadDallas"] = "Yachad_Dallas";
    GQLDepartment["YachadDetroit"] = "Yachad_Detroit";
    GQLDepartment["YachadFlorida"] = "Yachad_Florida";
    GQLDepartment["YachadHouston"] = "Yachad_Houston";
    GQLDepartment["YachadIvdu"] = "Yachad_IVDU";
    GQLDepartment["YachadIsrael"] = "Yachad_Israel";
    GQLDepartment["YachadLosAngeles"] = "Yachad_LosAngeles";
    GQLDepartment["YachadMidwest"] = "Yachad_Midwest";
    GQLDepartment["YachadNewEngland"] = "Yachad_NewEngland";
    GQLDepartment["YachadNewJersey"] = "Yachad_NewJersey";
    GQLDepartment["YachadNewYork"] = "Yachad_NewYork";
    GQLDepartment["YachadOmaha"] = "Yachad_Omaha";
    GQLDepartment["YachadOurWay"] = "Yachad_OurWay";
    GQLDepartment["YachadPhiladelphia"] = "Yachad_Philadelphia";
    GQLDepartment["YachadReach"] = "Yachad_Reach";
    GQLDepartment["YachadSpecialInitiatives"] = "Yachad_Special_Initiatives";
    GQLDepartment["YachadSummer"] = "Yachad_Summer";
    GQLDepartment["YachadToronto"] = "Yachad_Toronto";
})(GQLDepartment || (GQLDepartment = {}));
export var GQLDonorCategory;
(function (GQLDonorCategory) {
    GQLDonorCategory["BzCommittee2012"] = "BZCommittee2012";
    GQLDonorCategory["BenZakkaiMember"] = "BenZakkaiMember";
    GQLDonorCategory["BenefactorCircle"] = "BenefactorCircle";
    GQLDonorCategory["BoardMember"] = "BoardMember";
    GQLDonorCategory["DoNotCall"] = "DoNotCall";
    GQLDonorCategory["DoNotContact"] = "DoNotContact";
    GQLDonorCategory["DoNotEmail"] = "DoNotEmail";
    GQLDonorCategory["DoNotMail"] = "DoNotMail";
    GQLDonorCategory["DoNotText"] = "DoNotText";
    GQLDonorCategory["GrantGiver"] = "GrantGiver";
    GQLDonorCategory["HighCapacity"] = "HighCapacity";
    GQLDonorCategory["LegacyGiftGiver"] = "LegacyGiftGiver";
    GQLDonorCategory["LowCapacity"] = "LowCapacity";
    GQLDonorCategory["MediumCapacity"] = "MediumCapacity";
    GQLDonorCategory["NcsyAlumnus"] = "NCSYAlumnus";
    GQLDonorCategory["NoExternalSolicitation"] = "NoExternalSolicitation";
    GQLDonorCategory["OuaCommittee"] = "OUACommittee";
    GQLDonorCategory["OuEmployee"] = "OUEmployee";
    GQLDonorCategory["OuMember"] = "OUMember";
    GQLDonorCategory["OuNonMember"] = "OUNonMember";
    GQLDonorCategory["OuSynagogue"] = "OUSynagogue";
    GQLDonorCategory["OurWayMember"] = "OurWayMember";
    GQLDonorCategory["Prospect"] = "Prospect";
    GQLDonorCategory["RcaMember"] = "RCAMember";
    GQLDonorCategory["Staff"] = "Staff";
    GQLDonorCategory["Volunteer"] = "Volunteer";
})(GQLDonorCategory || (GQLDonorCategory = {}));
export var GQLDonorGoalType;
(function (GQLDonorGoalType) {
    GQLDonorGoalType["Monetary"] = "Monetary";
    GQLDonorGoalType["NonMonetary"] = "NonMonetary";
})(GQLDonorGoalType || (GQLDonorGoalType = {}));
export var GQLDonorKind;
(function (GQLDonorKind) {
    GQLDonorKind["Company"] = "Company";
    GQLDonorKind["Foundation"] = "Foundation";
    GQLDonorKind["Household"] = "Household";
    GQLDonorKind["School"] = "School";
    GQLDonorKind["Shul"] = "Shul";
    GQLDonorKind["Unset"] = "Unset";
})(GQLDonorKind || (GQLDonorKind = {}));
export var GQLDonorSubGoalType;
(function (GQLDonorSubGoalType) {
    GQLDonorSubGoalType["Activist"] = "Activist";
    GQLDonorSubGoalType["AmbassadorNetwork"] = "Ambassador_Network";
    GQLDonorSubGoalType["Fundraising"] = "Fundraising";
    GQLDonorSubGoalType["Gotv"] = "GOTV";
    GQLDonorSubGoalType["GovernmentPrograms"] = "GovernmentPrograms";
    GQLDonorSubGoalType["Grassroots"] = "Grassroots";
    GQLDonorSubGoalType["ProgramInterests"] = "ProgramInterests";
    GQLDonorSubGoalType["ProspectSchool"] = "ProspectSchool";
})(GQLDonorSubGoalType || (GQLDonorSubGoalType = {}));
export var GQLDonorSubscription;
(function (GQLDonorSubscription) {
    GQLDonorSubscription["Belong"] = "Belong";
    GQLDonorSubscription["Ignite"] = "Ignite";
    GQLDonorSubscription["JewishAction"] = "JewishAction";
})(GQLDonorSubscription || (GQLDonorSubscription = {}));
export var GQLDonorsReportType;
(function (GQLDonorsReportType) {
    GQLDonorsReportType["OutdatedContact"] = "outdatedContact";
    GQLDonorsReportType["WithoutAddress"] = "withoutAddress";
    GQLDonorsReportType["WithoutSalutation"] = "withoutSalutation";
})(GQLDonorsReportType || (GQLDonorsReportType = {}));
export var GQLEmailTemplateType;
(function (GQLEmailTemplateType) {
    GQLEmailTemplateType["OuMemebership"] = "OUMemebership";
    GQLEmailTemplateType["ProjectProtect"] = "ProjectProtect";
})(GQLEmailTemplateType || (GQLEmailTemplateType = {}));
export var GQLEmailType;
(function (GQLEmailType) {
    GQLEmailType["Other"] = "Other";
    GQLEmailType["Personal"] = "Personal";
    GQLEmailType["Work"] = "Work";
})(GQLEmailType || (GQLEmailType = {}));
export var GQLEngagementReportEventType;
(function (GQLEngagementReportEventType) {
    GQLEngagementReportEventType["Anniversary"] = "Anniversary";
    GQLEngagementReportEventType["Birthday"] = "Birthday";
    GQLEngagementReportEventType["FirstGift"] = "FirstGift";
})(GQLEngagementReportEventType || (GQLEngagementReportEventType = {}));
export var GQLErrorType;
(function (GQLErrorType) {
    GQLErrorType["ImportModeSkip"] = "ImportModeSkip";
    GQLErrorType["Internal"] = "Internal";
    GQLErrorType["Projection"] = "Projection";
    GQLErrorType["WriteFailed"] = "WriteFailed";
})(GQLErrorType || (GQLErrorType = {}));
export var GQLGotvBroadcasterCategory;
(function (GQLGotvBroadcasterCategory) {
    GQLGotvBroadcasterCategory["Calls"] = "Calls";
    GQLGotvBroadcasterCategory["SocialMedia"] = "SocialMedia";
    GQLGotvBroadcasterCategory["Texting"] = "Texting";
})(GQLGotvBroadcasterCategory || (GQLGotvBroadcasterCategory = {}));
export var GQLGotvCommitteeCategory;
(function (GQLGotvCommitteeCategory) {
    GQLGotvCommitteeCategory["CommunityPrecinctTagging"] = "CommunityPrecinctTagging";
    GQLGotvCommitteeCategory["DoorKnockPhoneCall"] = "DoorKnockPhoneCall";
    GQLGotvCommitteeCategory["EmailBlastInfulencer"] = "EmailBlastInfulencer";
    GQLGotvCommitteeCategory["LawnSigns"] = "LawnSigns";
    GQLGotvCommitteeCategory["SchoolShulLiaison"] = "SchoolShulLiaison";
    GQLGotvCommitteeCategory["Tabling"] = "Tabling";
    GQLGotvCommitteeCategory["VoteBoosters"] = "VoteBoosters";
})(GQLGotvCommitteeCategory || (GQLGotvCommitteeCategory = {}));
export var GQLGender;
(function (GQLGender) {
    GQLGender["Female"] = "Female";
    GQLGender["Male"] = "Male";
})(GQLGender || (GQLGender = {}));
export var GQLGrantApplicationType;
(function (GQLGrantApplicationType) {
    GQLGrantApplicationType["Application"] = "Application";
    GQLGrantApplicationType["GeneralFunding"] = "GeneralFunding";
    GQLGrantApplicationType["Loi"] = "LOI";
    GQLGrantApplicationType["Proposal"] = "Proposal";
})(GQLGrantApplicationType || (GQLGrantApplicationType = {}));
export var GQLGrantIdeaStatus;
(function (GQLGrantIdeaStatus) {
    GQLGrantIdeaStatus["Idea"] = "Idea";
    GQLGrantIdeaStatus["NoGo"] = "NoGo";
    GQLGrantIdeaStatus["Submitted"] = "Submitted";
})(GQLGrantIdeaStatus || (GQLGrantIdeaStatus = {}));
export var GQLGrantStatus;
(function (GQLGrantStatus) {
    GQLGrantStatus["Approved"] = "Approved";
    GQLGrantStatus["Closed"] = "Closed";
    GQLGrantStatus["Denied"] = "Denied";
    GQLGrantStatus["NoGo"] = "NoGo";
    GQLGrantStatus["OnHold"] = "OnHold";
    GQLGrantStatus["Pending"] = "Pending";
    GQLGrantStatus["PreSubmission"] = "PreSubmission";
})(GQLGrantStatus || (GQLGrantStatus = {}));
export var GQLIncomingPaymentType;
(function (GQLIncomingPaymentType) {
    GQLIncomingPaymentType["Payment"] = "Payment";
    GQLIncomingPaymentType["Pledge"] = "Pledge";
})(GQLIncomingPaymentType || (GQLIncomingPaymentType = {}));
export var GQLMailMergeTemplateType;
(function (GQLMailMergeTemplateType) {
    GQLMailMergeTemplateType["Invoice"] = "Invoice";
    GQLMailMergeTemplateType["Receipt"] = "Receipt";
})(GQLMailMergeTemplateType || (GQLMailMergeTemplateType = {}));
export var GQLNetworkCommentCategory;
(function (GQLNetworkCommentCategory) {
    GQLNetworkCommentCategory["Activist"] = "Activist";
    GQLNetworkCommentCategory["AmbassadorNetwork"] = "Ambassador_Network";
    GQLNetworkCommentCategory["Fundraising"] = "Fundraising";
    GQLNetworkCommentCategory["GotvAdvisoryCommittee"] = "GOTV_AdvisoryCommittee";
    GQLNetworkCommentCategory["Schools"] = "Schools";
})(GQLNetworkCommentCategory || (GQLNetworkCommentCategory = {}));
export var GQLPpActivityStatus;
(function (GQLPpActivityStatus) {
    GQLPpActivityStatus["Canceled"] = "Canceled";
    GQLPpActivityStatus["Completed"] = "Completed";
    GQLPpActivityStatus["InProgress"] = "InProgress";
    GQLPpActivityStatus["NotStarted"] = "NotStarted";
})(GQLPpActivityStatus || (GQLPpActivityStatus = {}));
export var GQLPpActivityType;
(function (GQLPpActivityType) {
    GQLPpActivityType["GrantWriting"] = "GrantWriting";
    GQLPpActivityType["Implementation"] = "Implementation";
    GQLPpActivityType["Inquiry"] = "Inquiry";
})(GQLPpActivityType || (GQLPpActivityType = {}));
export var GQLPaymentBank;
(function (GQLPaymentBank) {
    GQLPaymentBank["Canada"] = "Canada";
    GQLPaymentBank["CreditCards"] = "Credit_Cards";
    GQLPaymentBank["Fidelity"] = "Fidelity";
    GQLPaymentBank["Israel"] = "Israel";
    GQLPaymentBank["MainOu"] = "Main_OU";
    GQLPaymentBank["NcsyAtl"] = "NCSY_ATL";
    GQLPaymentBank["NcsyAts"] = "NCSY_ATS";
    GQLPaymentBank["NcsyArgentina"] = "NCSY_Argentina";
    GQLPaymentBank["NcsyCa"] = "NCSY_CA";
    GQLPaymentBank["NcsyCe"] = "NCSY_CE";
    GQLPaymentBank["NcsyMw"] = "NCSY_MW";
    GQLPaymentBank["NcsyMx"] = "NCSY_MX";
    GQLPaymentBank["NcsyNe"] = "NCSY_NE";
    GQLPaymentBank["NcsyNj"] = "NCSY_NJ";
    GQLPaymentBank["NcsyNy"] = "NCSY_NY";
    GQLPaymentBank["NcsyOr"] = "NCSY_OR";
    GQLPaymentBank["NcsySth"] = "NCSY_STH";
    GQLPaymentBank["NcsyStl"] = "NCSY_STL";
    GQLPaymentBank["NcsySw"] = "NCSY_SW";
    GQLPaymentBank["NcsyUpstate"] = "NCSY_Upstate";
    GQLPaymentBank["NcsyWc"] = "NCSY_WC";
    GQLPaymentBank["OuWestCoast"] = "OU_West_Coast";
    GQLPaymentBank["YachadCa"] = "YACHAD_CA";
    GQLPaymentBank["Yachad"] = "Yachad";
})(GQLPaymentBank || (GQLPaymentBank = {}));
export var GQLPaymentBatchType;
(function (GQLPaymentBatchType) {
    GQLPaymentBatchType["Ach"] = "ACH";
    GQLPaymentBatchType["ApplePay"] = "ApplePay";
    GQLPaymentBatchType["Bit"] = "Bit";
    GQLPaymentBatchType["Check"] = "Check";
    GQLPaymentBatchType["CreditCard"] = "CreditCard";
    GQLPaymentBatchType["Everything"] = "Everything";
    GQLPaymentBatchType["GooglePay"] = "GooglePay";
    GQLPaymentBatchType["NonCash"] = "NonCash";
    GQLPaymentBatchType["OtherCash"] = "OtherCash";
    GQLPaymentBatchType["Paypal"] = "Paypal";
    GQLPaymentBatchType["Venmo"] = "Venmo";
})(GQLPaymentBatchType || (GQLPaymentBatchType = {}));
export var GQLPaymentCategory;
(function (GQLPaymentCategory) {
    GQLPaymentCategory["Daf"] = "DAF";
    GQLPaymentCategory["Ira"] = "IRA";
    GQLPaymentCategory["MatchingGift"] = "MatchingGift";
    GQLPaymentCategory["Payment"] = "Payment";
})(GQLPaymentCategory || (GQLPaymentCategory = {}));
export var GQLPaymentChannel;
(function (GQLPaymentChannel) {
    GQLPaymentChannel["DigitalAds"] = "DigitalAds";
    GQLPaymentChannel["Email"] = "Email";
    GQLPaymentChannel["Mail"] = "Mail";
    GQLPaymentChannel["Organic"] = "Organic";
    GQLPaymentChannel["Sms"] = "SMS";
    GQLPaymentChannel["SocialPost"] = "SocialPost";
    GQLPaymentChannel["Telemarketing"] = "Telemarketing";
})(GQLPaymentChannel || (GQLPaymentChannel = {}));
export var GQLPaymentStatus;
(function (GQLPaymentStatus) {
    GQLPaymentStatus["Confirmed"] = "Confirmed";
    GQLPaymentStatus["Unconfirmed"] = "Unconfirmed";
    GQLPaymentStatus["Voided"] = "Voided";
})(GQLPaymentStatus || (GQLPaymentStatus = {}));
export var GQLPaymentType;
(function (GQLPaymentType) {
    GQLPaymentType["Ach"] = "ACH";
    GQLPaymentType["ApplePay"] = "ApplePay";
    GQLPaymentType["Bequest"] = "Bequest";
    GQLPaymentType["Bit"] = "Bit";
    GQLPaymentType["Cash"] = "Cash";
    GQLPaymentType["ChargeCard"] = "ChargeCard";
    GQLPaymentType["Check"] = "Check";
    GQLPaymentType["CreditCard"] = "CreditCard";
    GQLPaymentType["Crypto"] = "Crypto";
    GQLPaymentType["GooglePay"] = "GooglePay";
    GQLPaymentType["InKind"] = "InKind";
    GQLPaymentType["Other"] = "Other";
    GQLPaymentType["Paypal"] = "Paypal";
    GQLPaymentType["Stock"] = "Stock";
    GQLPaymentType["Venmo"] = "Venmo";
    GQLPaymentType["Wire"] = "Wire";
})(GQLPaymentType || (GQLPaymentType = {}));
export var GQLPersonRole;
(function (GQLPersonRole) {
    GQLPersonRole["PrimaryContact"] = "PrimaryContact";
    GQLPersonRole["SecondaryContact"] = "SecondaryContact";
})(GQLPersonRole || (GQLPersonRole = {}));
export var GQLPhoneType;
(function (GQLPhoneType) {
    GQLPhoneType["Cell"] = "Cell";
    GQLPhoneType["Fax"] = "Fax";
    GQLPhoneType["Home"] = "Home";
    GQLPhoneType["Main"] = "Main";
    GQLPhoneType["Other"] = "Other";
    GQLPhoneType["Work"] = "Work";
})(GQLPhoneType || (GQLPhoneType = {}));
export var GQLPlannedGivingIntent;
(function (GQLPlannedGivingIntent) {
    GQLPlannedGivingIntent["Considerer"] = "Considerer";
    GQLPlannedGivingIntent["Enquirer"] = "Enquirer";
    GQLPlannedGivingIntent["Intender"] = "Intender";
    GQLPlannedGivingIntent["Pledger"] = "Pledger";
    GQLPlannedGivingIntent["PledgerIrrevocable"] = "PledgerIrrevocable";
})(GQLPlannedGivingIntent || (GQLPlannedGivingIntent = {}));
export var GQLPlannedGivingStatus;
(function (GQLPlannedGivingStatus) {
    GQLPlannedGivingStatus["Approved"] = "Approved";
    GQLPlannedGivingStatus["Denied"] = "Denied";
    GQLPlannedGivingStatus["Pending"] = "Pending";
})(GQLPlannedGivingStatus || (GQLPlannedGivingStatus = {}));
export var GQLPledgeApproveStatus;
(function (GQLPledgeApproveStatus) {
    GQLPledgeApproveStatus["Approved"] = "Approved";
    GQLPledgeApproveStatus["Disapproved"] = "Disapproved";
    GQLPledgeApproveStatus["Pending"] = "Pending";
})(GQLPledgeApproveStatus || (GQLPledgeApproveStatus = {}));
export var GQLPledgeCategory;
(function (GQLPledgeCategory) {
    GQLPledgeCategory["Gift"] = "Gift";
    GQLPledgeCategory["Grant"] = "Grant";
    GQLPledgeCategory["Tribute"] = "Tribute";
})(GQLPledgeCategory || (GQLPledgeCategory = {}));
export var GQLPledgePaidStatus;
(function (GQLPledgePaidStatus) {
    GQLPledgePaidStatus["NotPaid"] = "NotPaid";
    GQLPledgePaidStatus["Paid"] = "Paid";
})(GQLPledgePaidStatus || (GQLPledgePaidStatus = {}));
export var GQLRelationshipType;
(function (GQLRelationshipType) {
    GQLRelationshipType["Daughter"] = "Daughter";
    GQLRelationshipType["ExHusband"] = "ExHusband";
    GQLRelationshipType["ExWife"] = "ExWife";
    GQLRelationshipType["Husband"] = "Husband";
    GQLRelationshipType["Mother"] = "Mother";
    GQLRelationshipType["Other"] = "Other";
    GQLRelationshipType["Son"] = "Son";
    GQLRelationshipType["Wife"] = "Wife";
})(GQLRelationshipType || (GQLRelationshipType = {}));
export var GQLSalutationType;
(function (GQLSalutationType) {
    GQLSalutationType["Default"] = "Default";
    GQLSalutationType["HusbandOnlyNickname"] = "Husband_only_Nickname";
    GQLSalutationType["HusbandOnlyWithNoTitle"] = "Husband_only_with_No_Title";
    GQLSalutationType["HusbandOnlyWithTitle"] = "Husband_only_with_Title";
    GQLSalutationType["HusbundAndWifeNicknames"] = "Husbund_and_Wife_Nicknames";
    GQLSalutationType["HusbundAndWifeWithNoTitle"] = "Husbund_and_Wife_with_No_Title";
    GQLSalutationType["WifeOnlyNickname"] = "Wife_only_Nickname";
    GQLSalutationType["WifeOnlyWithNoTitle"] = "Wife_only_with_No_Title";
    GQLSalutationType["WifeOnlyWithTitle"] = "Wife_only_with_Title";
})(GQLSalutationType || (GQLSalutationType = {}));
export var GQLSchoolGovernmentProgram;
(function (GQLSchoolGovernmentProgram) {
    GQLSchoolGovernmentProgram["DualEnrollment"] = "DualEnrollment";
    GQLSchoolGovernmentProgram["Eitc"] = "EITC";
    GQLSchoolGovernmentProgram["FreeLunch"] = "FreeLunch";
    GQLSchoolGovernmentProgram["MandatedServices"] = "MandatedServices";
    GQLSchoolGovernmentProgram["Stem"] = "STEM";
    GQLSchoolGovernmentProgram["Scholarships"] = "Scholarships";
    GQLSchoolGovernmentProgram["SchoolHealthServices"] = "SchoolHealthServices";
    GQLSchoolGovernmentProgram["Security"] = "Security";
    GQLSchoolGovernmentProgram["TitlePrograms"] = "TitlePrograms";
    GQLSchoolGovernmentProgram["Transportation"] = "Transportation";
    GQLSchoolGovernmentProgram["Upk"] = "UPK";
})(GQLSchoolGovernmentProgram || (GQLSchoolGovernmentProgram = {}));
export var GQLSendVia;
(function (GQLSendVia) {
    GQLSendVia["Email"] = "Email";
    GQLSendVia["Mail"] = "Mail";
})(GQLSendVia || (GQLSendVia = {}));
export var GQLSortOrder;
(function (GQLSortOrder) {
    GQLSortOrder["Asc"] = "ASC";
    GQLSortOrder["Desc"] = "DESC";
})(GQLSortOrder || (GQLSortOrder = {}));
export var GQLSubCommittee;
(function (GQLSubCommittee) {
    GQLSubCommittee["JewishAction"] = "JewishAction";
    GQLSubCommittee["OuPress"] = "OU_Press";
    GQLSubCommittee["SummerPrograms"] = "SummerPrograms";
    GQLSubCommittee["TeachCa"] = "Teach_CA";
    GQLSubCommittee["TeachFl"] = "Teach_FL";
    GQLSubCommittee["TeachMd"] = "Teach_MD";
    GQLSubCommittee["TeachNj"] = "Teach_NJ";
    GQLSubCommittee["TeachNys"] = "Teach_NYS";
    GQLSubCommittee["TeachPa"] = "Teach_PA";
    GQLSubCommittee["Website"] = "Website";
})(GQLSubCommittee || (GQLSubCommittee = {}));
export var GQLSummaryModule;
(function (GQLSummaryModule) {
    GQLSummaryModule["Benefactors"] = "Benefactors";
    GQLSummaryModule["BudgetReport"] = "BudgetReport";
    GQLSummaryModule["CeoSummary"] = "CEOSummary";
    GQLSummaryModule["DepartmentYtd"] = "DepartmentYTD";
    GQLSummaryModule["FundraiserSummary"] = "FundraiserSummary";
    GQLSummaryModule["JlicPrograms"] = "JLICPrograms";
    GQLSummaryModule["OuIsraelQuartSummaries"] = "OUIsraelQuartSummaries";
    GQLSummaryModule["PersonalPledges"] = "PersonalPledges";
    GQLSummaryModule["QuartSummaries"] = "QuartSummaries";
    GQLSummaryModule["TeachSchools"] = "TeachSchools";
})(GQLSummaryModule || (GQLSummaryModule = {}));
export var GQLSurveyEventInterest;
(function (GQLSurveyEventInterest) {
    GQLSurveyEventInterest["DepartmentalEvents"] = "Departmental_events";
    GQLSurveyEventInterest["OuConventions"] = "OU_Conventions";
    GQLSurveyEventInterest["RegionalEvents"] = "Regional_events";
    GQLSurveyEventInterest["ScholarsInResidenceOpportunities"] = "Scholars_in_Residence_Opportunities";
    GQLSurveyEventInterest["Webinars"] = "Webinars";
})(GQLSurveyEventInterest || (GQLSurveyEventInterest = {}));
export var GQLSurveyQuizOpt;
(function (GQLSurveyQuizOpt) {
    GQLSurveyQuizOpt["No"] = "No";
    GQLSurveyQuizOpt["NotVery"] = "NotVery";
    GQLSurveyQuizOpt["NotAtAll"] = "Not_at_all";
    GQLSurveyQuizOpt["Somewhat"] = "Somewhat";
    GQLSurveyQuizOpt["VeryMuch"] = "VeryMuch";
    GQLSurveyQuizOpt["Yes"] = "Yes";
})(GQLSurveyQuizOpt || (GQLSurveyQuizOpt = {}));
export var GQLTeachSchoolStatus;
(function (GQLTeachSchoolStatus) {
    GQLTeachSchoolStatus["Member"] = "Member";
    GQLTeachSchoolStatus["Prospect"] = "Prospect";
    GQLTeachSchoolStatus["Unidentified"] = "Unidentified";
})(GQLTeachSchoolStatus || (GQLTeachSchoolStatus = {}));
export var GQLTimePeriod;
(function (GQLTimePeriod) {
    GQLTimePeriod["Month"] = "month";
    GQLTimePeriod["Quarter"] = "quarter";
    GQLTimePeriod["Week"] = "week";
    GQLTimePeriod["Year"] = "year";
})(GQLTimePeriod || (GQLTimePeriod = {}));
export var GQLTitle;
(function (GQLTitle) {
    GQLTitle["Admiral"] = "Admiral";
    GQLTitle["Ambassador"] = "Ambassador";
    GQLTitle["Assemblyman"] = "Assemblyman";
    GQLTitle["Bishop"] = "Bishop";
    GQLTitle["Brother"] = "Brother";
    GQLTitle["Cantor"] = "Cantor";
    GQLTitle["Captain"] = "Captain";
    GQLTitle["Chaplain"] = "Chaplain";
    GQLTitle["ChiefRabbi"] = "Chief_rabbi";
    GQLTitle["Child"] = "Child";
    GQLTitle["Cmdr"] = "Cmdr";
    GQLTitle["Col"] = "Col";
    GQLTitle["Commisioner"] = "Commisioner";
    GQLTitle["Congressman"] = "Congressman";
    GQLTitle["Councilman"] = "Councilman";
    GQLTitle["Dean"] = "Dean";
    GQLTitle["Dr"] = "Dr";
    GQLTitle["Drs"] = "Drs";
    GQLTitle["Esq"] = "Esq";
    GQLTitle["Father"] = "Father";
    GQLTitle["General"] = "General";
    GQLTitle["Governor"] = "Governor";
    GQLTitle["Hon"] = "Hon";
    GQLTitle["Horav"] = "Horav";
    GQLTitle["Infant"] = "Infant";
    GQLTitle["Judge"] = "Judge";
    GQLTitle["Lt"] = "Lt";
    GQLTitle["LtCmdr"] = "Lt_cmdr";
    GQLTitle["LtCol"] = "Lt_col";
    GQLTitle["Major"] = "Major";
    GQLTitle["Miss"] = "Miss";
    GQLTitle["Mr"] = "Mr";
    GQLTitle["Mrs"] = "Mrs";
    GQLTitle["Ms"] = "Ms";
    GQLTitle["Pastor"] = "Pastor";
    GQLTitle["Professor"] = "Professor";
    GQLTitle["Rabbi"] = "Rabbi";
    GQLTitle["RabbiDr"] = "Rabbi_dr";
    GQLTitle["Rebbetzin"] = "Rebbetzin";
    GQLTitle["Rep"] = "Rep";
    GQLTitle["Rev"] = "Rev";
    GQLTitle["Senator"] = "Senator";
    GQLTitle["Sgt"] = "Sgt";
})(GQLTitle || (GQLTitle = {}));
export var GQLUserRole;
(function (GQLUserRole) {
    GQLUserRole["AddCampaign"] = "AddCampaign";
    GQLUserRole["AddPledge"] = "AddPledge";
    GQLUserRole["AssignContacts"] = "AssignContacts";
    GQLUserRole["ChangeDonorKind"] = "ChangeDonorKind";
    GQLUserRole["CheckPaymentEdit"] = "CheckPaymentEdit";
    GQLUserRole["ChecksUpload"] = "ChecksUpload";
    GQLUserRole["DeleteContactInfo"] = "DeleteContactInfo";
    GQLUserRole["DeletePledgeAndPayment"] = "DeletePledgeAndPayment";
    GQLUserRole["EditFundrasingBudget"] = "EditFundrasingBudget";
    GQLUserRole["EditUsers"] = "EditUsers";
    GQLUserRole["GenerateInvoicesAndReceipts"] = "GenerateInvoicesAndReceipts";
    GQLUserRole["ManageBenefactor"] = "ManageBenefactor";
    GQLUserRole["ManageBoardMembers"] = "ManageBoardMembers";
    GQLUserRole["ManageCommissionAndCommitteeMembers"] = "ManageCommissionAndCommitteeMembers";
    GQLUserRole["ManageEmployees"] = "ManageEmployees";
    GQLUserRole["ManageGrants"] = "ManageGrants";
    GQLUserRole["ManageLibraryFiles"] = "ManageLibraryFiles";
    GQLUserRole["ManageNetworkRegions"] = "ManageNetworkRegions";
    GQLUserRole["ManageRm"] = "ManageRM";
    GQLUserRole["ManageSubscriptions"] = "ManageSubscriptions";
    GQLUserRole["MergeDuplicates"] = "MergeDuplicates";
    GQLUserRole["MovePledgeAndPayment"] = "MovePledgeAndPayment";
    GQLUserRole["ViewBatches"] = "ViewBatches";
    GQLUserRole["ViewBoardMembers"] = "ViewBoardMembers";
    GQLUserRole["ViewCheckScans"] = "ViewCheckScans";
    GQLUserRole["ViewDepartmentStaff"] = "ViewDepartmentStaff";
    GQLUserRole["ViewEmployees"] = "ViewEmployees";
    GQLUserRole["ViewGoalsReport"] = "ViewGoalsReport";
    GQLUserRole["ViewLeadershipReports"] = "ViewLeadershipReports";
    GQLUserRole["ViewNewDonors"] = "ViewNewDonors";
    GQLUserRole["ViewOuMemebers"] = "ViewOuMemebers";
    GQLUserRole["ViewPending"] = "ViewPending";
    GQLUserRole["ViewQiuckLookup"] = "ViewQiuckLookup";
    GQLUserRole["ViewRecurringPayments"] = "ViewRecurringPayments";
    GQLUserRole["ViewTelemarketing"] = "ViewTelemarketing";
    GQLUserRole["ViewTurnaroundPayments"] = "ViewTurnaroundPayments";
    GQLUserRole["ViewUsers"] = "ViewUsers";
    GQLUserRole["Write"] = "Write";
})(GQLUserRole || (GQLUserRole = {}));
export var GQLUserType;
(function (GQLUserType) {
    GQLUserType["Accounting"] = "Accounting";
    GQLUserType["Ceo"] = "CEO";
    GQLUserType["DataServices"] = "DataServices";
    GQLUserType["DepartmentManager"] = "DepartmentManager";
    GQLUserType["Developer"] = "Developer";
    GQLUserType["Fundraiser"] = "Fundraiser";
    GQLUserType["RegionManager"] = "RegionManager";
    GQLUserType["Support"] = "Support";
})(GQLUserType || (GQLUserType = {}));
export var GQLWireBank;
(function (GQLWireBank) {
    GQLWireBank["Chase"] = "Chase";
    GQLWireBank["Fidelity"] = "Fidelity";
    GQLWireBank["IdbDonations"] = "IDB_Donations";
    GQLWireBank["IdbKosher"] = "IDB_Kosher";
    GQLWireBank["IdbOtherReceipts"] = "IDB_Other_Receipts";
    GQLWireBank["Other"] = "Other";
})(GQLWireBank || (GQLWireBank = {}));
