import * as Sentry from '@sentry/browser';
import { defineComponent, h, ref } from 'vue';
import { Route } from 'vue-component-router';
import { ErrorBoundary } from 'vue-error-boundary';
export const RouteWithErrorBoundary = defineComponent({
    props: {
        path: String,
        exact: Boolean
    },
    components: {
        ErrorBoundary
    },
    setup(props, context) {
        const version = ref(0);
        return () => {
            return h(Route, {
                props: { path: props.path, exact: props.exact },
                scopedSlots: {
                    default(props) {
                        //@ts-ignore
                        if (!process.env.VUE_APP_IN_PRODUCTION)
                            return context.slots.default(props);
                        return [
                            h(ErrorBoundary, {
                                key: version.value,
                                props: {
                                    stopPropagation: true,
                                    onError: (err) => {
                                        const id = Sentry.captureException(err);
                                        console.error(id, err);
                                    }
                                },
                                scopedSlots: {
                                    boundary({ hasError, err }) {
                                        const timeoutError = err?.graphQLErrors?.[0].extensions.exception.projections;
                                        const projectionError = err?.graphQLErrors?.[0].extensions.exception.projection;
                                        return !hasError || timeoutError || projectionError
                                            ? //@ts-ignore
                                                context.slots.default(props)
                                            : h('div', {
                                                style: {
                                                    background: 'red',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    padding: '10px'
                                                }
                                            }, [
                                                h('span', 'An error has occurred: '),
                                                h('a', {
                                                    attrs: { href: '#' },
                                                    on: {
                                                        click: (e) => {
                                                            e.preventDefault();
                                                            version.value++;
                                                        }
                                                    }
                                                }, 'Click here to reload')
                                            ]);
                                    }
                                }
                            })
                        ];
                    }
                }
            });
        };
    }
});
