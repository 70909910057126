import GET_GOALS from './get-goals.graphql';
import graphql from '@/shared/graphql';
import { acceptProps, compose } from 'vue-compose';
import GoalLookup from './goal-lookup.vue';
const getGoals = graphql(GET_GOALS, {
    skip: props => !props.donorId,
    options(props) {
        return {
            variables: {
                donorId: props.donorId
            }
        };
    },
    props(props) {
        const goals = !props.loading && props.data && props.data.donor
            ? props.data.donor.goals.map(x => ({
                id: x.id,
                label: x.title
            }))
            : [];
        let value;
        value = null;
        if (props.value) {
            value =
                typeof props.value === 'string'
                    ? goals.find(g => g.id === props.value)
                    : goals.find(g => g.id === props.value.id);
        }
        return {
            loading: props.loading,
            goals,
            value
        };
    }
});
export default compose(acceptProps('donorId'), acceptProps('value'), getGoals)(GoalLookup);
