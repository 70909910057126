import { GQLCommission, GQLSubCommittee } from './gql-types';
export const commissionSubCommitteesMap = {
    [GQLCommission.Communications]: [GQLSubCommittee.OuPress, GQLSubCommittee.JewishAction, GQLSubCommittee.Website],
    [GQLCommission.TeachCoalition]: [
        GQLSubCommittee.TeachCa,
        GQLSubCommittee.TeachFl,
        GQLSubCommittee.TeachMd,
        GQLSubCommittee.TeachNj,
        GQLSubCommittee.TeachNys,
        GQLSubCommittee.TeachPa,
    ],
    [GQLCommission.Youth]: [GQLSubCommittee.SummerPrograms],
};
