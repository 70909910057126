import { __decorate, __metadata } from "tslib";
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Grid from '@/shared/ui/grid/grid.vue';
import UICheckbox from '@/shared/ui/forms/checkbox/checkbox.vue';
import DateCell from '@/shared/ui/grid/cells/DateCell.vue';
import MoneyCell from '@/shared/ui/grid/cells/MoneyCell.vue';
import uiInput from '@/shared/ui/forms/input/ui-input.vue';
import DepartmentSelector from '@/shared/components/department-selector';
import CampaignSelector from '@/shared/components/campaign-lookup';
import SubCampaignSelector from '@/shared/components/subcampaign-lookup';
import { PledgeCategory } from '../../../schema/globalTypes';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import singlePledge from './single-pledge.vue';
import { v4 as uuid } from 'uuid';
import { monthDayYearToDate } from '@/shared/util';
let PledgeSelector = class PledgeSelector extends Vue {
    pledges;
    amount;
    currency;
    errorStr;
    donorId;
    date;
    pledgesCopy = this.getPledgesCopy();
    getPledgesCopy() {
        return this.pledges
            .filter(pledge => pledge.currency === this.currency)
            .map(x => ({
            ...x,
            amount: null,
            actions: {
                setAmount: this.setAmount,
                setDepartment: this.setDepartment,
                setCampaign: this.setCampaign,
                setSubCampaign: this.setSubCampaign,
                setNotes: this.setNotes,
                setFundraisers: this.setFundraisers,
                setDate: this.setDate,
                setType: this.setType,
                setTribute: this.setTribute,
                setGrant: this.setGrant
            },
            tmpIndex: uuid()
        }));
    }
    columns = [
        { field: 'department', title: 'Department', cell: 'departmentCell', width: '180px' },
        { field: 'outstanding', title: 'Outstanding', cell: MoneyCell, width: '120px' },
        { field: 'amount', title: 'Amount', cell: 'amountCell', width: '120px' },
        { field: 'date', title: 'Date Pledged', cell: DateCell },
        { field: 'campaign', title: 'Campaign', cell: 'campaignCell' },
        { field: 'subCampaign', title: 'Sub Campaign', cell: 'subCampaignCell' },
        { field: 'notes', title: 'Notes', cell: 'notesCell' }
    ];
    setAmount(pledge, amount) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.amount = !p.outstanding
            ? amount
            : amount === 0
                ? 1
                : +amount <= Number(pledge.outstanding)
                    ? amount
                    : pledge.outstanding;
        this.pledgesCopy = pledgesCopy;
    }
    setDepartment(pledge, department) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.department = department;
        this.pledgesCopy = pledgesCopy;
    }
    setDate(pledge, date) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.date = monthDayYearToDate(date);
        this.pledgesCopy = pledgesCopy;
    }
    setCampaign(pledge, campaign) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.campaign = campaign;
        this.pledgesCopy = pledgesCopy;
    }
    setSubCampaign(pledge, campaign) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.subCampaign = campaign;
        this.pledgesCopy = pledgesCopy;
    }
    setNotes(pledge, notes) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.notes = notes;
        this.pledgesCopy = pledgesCopy;
    }
    setFundraisers(pledge, data) {
        if (!data.values)
            return;
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.fundraisers = data.values.fundraisers.map(fundraiser => ({
            fundraiserID: fundraiser.user.id,
            percent: parseInt(fundraiser.percentage, 10)
        }));
        this.pledgesCopy = pledgesCopy;
    }
    setType(pledge, type) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.type = type;
        this.pledgesCopy = pledgesCopy;
    }
    setTribute(pledge, tributeText) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.tributeText = tributeText;
        this.pledgesCopy = pledgesCopy;
    }
    setGrant(pledge, grant) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = this.pledgesCopy.find(x => x.tmpIndex === pledge.tmpIndex);
        p.grantId = grant?.id || null;
        this.pledgesCopy = pledgesCopy;
    }
    addNewPledge() {
        const amountUsed = this.amount ? this.pledgesCopy.reduce((sum, p) => sum + (p.amount ? p.amount : 0), 0) : 0;
        this.pledgesCopy.unshift({
            id: null,
            date: this.date ? monthDayYearToDate(this.date) : new Date(),
            outstanding: null,
            currency: this.currency,
            department: null,
            campaign: null,
            subCampaign: null,
            notes: null,
            fundraisers: [],
            amount: this.amount ? Math.max(this.amount - amountUsed, 0) : null,
            type: PledgeCategory.Gift,
            tributeText: null,
            grantId: null,
            actions: {
                setAmount: this.setAmount,
                setDepartment: this.setDepartment,
                setCampaign: this.setCampaign,
                setSubCampaign: this.setSubCampaign,
                setNotes: this.setNotes,
                setDate: this.setDate,
                setFundraisers: this.setFundraisers,
                setType: this.setType,
                setTribute: this.setTribute,
                setGrant: this.setGrant
            },
            tmpIndex: uuid()
        });
    }
    onCurrencyUpdated() {
        const newPledges = this.pledgesCopy
            .filter(pledge => pledge.id === null)
            .map(pledge => ({ ...pledge, currency: this.currency }));
        const existingPledges = this.getPledgesCopy();
        this.pledgesCopy = [...newPledges, ...existingPledges];
    }
    onPledgesUpdated() {
        const applications = this.pledgesCopy.map(x => x.amount
            ? x.id !== null
                ? {
                    pledgeId: x.id,
                    amount: x.amount,
                    campaign: x.campaign ? { id: x.campaign.id, name: x.campaign.label } : null,
                    subCampaign: x.subCampaign ? { id: x.subCampaign.id, name: x.subCampaign.label } : null,
                    department: x.department
                }
                : {
                    pledgeId: null,
                    amount: x.amount,
                    campaign: x.campaign ? { id: x.campaign.id, name: x.campaign.label } : null,
                    subCampaign: x.subCampaign ? { id: x.subCampaign.id, name: x.subCampaign.label } : null,
                    fundraisers: x.fundraisers,
                    notes: x.notes || '',
                    date: x.date ? x.date : new Date(),
                    department: x.department,
                    type: x.type,
                    tributeText: (x.type === PledgeCategory.Tribute && x.tributeText) || null,
                    grantId: (x.type === PledgeCategory.Grant && x.grantId) || null
                }
            : null);
        this.$emit('input', applications.filter(Boolean));
        this.$emit('blur'); // so it can be marked as "touched"
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], PledgeSelector.prototype, "pledges", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], PledgeSelector.prototype, "amount", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], PledgeSelector.prototype, "currency", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], PledgeSelector.prototype, "errorStr", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], PledgeSelector.prototype, "donorId", void 0);
__decorate([
    Prop({ type: Object, required: false }),
    __metadata("design:type", Object)
], PledgeSelector.prototype, "date", void 0);
__decorate([
    Watch('currency'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PledgeSelector.prototype, "onCurrencyUpdated", null);
__decorate([
    Watch('pledgesCopy', {
        deep: true
    }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PledgeSelector.prototype, "onPledgesUpdated", null);
PledgeSelector = __decorate([
    Component({
        components: {
            Grid,
            Box,
            singlePledge,
            layoutText,
            UIButton,
            UICheckbox,
            DateCell,
            MoneyCell,
            uiInput,
            DepartmentSelector,
            CampaignSelector,
            SubCampaignSelector
        }
    })
], PledgeSelector);
export default PledgeSelector;
