import mutate from './mutate';
import query from './query';
import { withProps } from 'vue-compose';
function getOperationType(query) {
    const definitionNode = query.definitions.filter(d => d.kind === 'OperationDefinition')[0];
    return definitionNode.operation;
}
export default function graphql(operation, options = {}) {
    const operationType = getOperationType(operation);
    if (operationType === 'mutation')
        return mutate(operation, options);
    else
        return query(operation, options);
}
export const withApollo = withProps(function () {
    return {
        client: this.$apollo.getClient()
    };
});
