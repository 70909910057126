import { __decorate, __metadata } from "tslib";
import FileUpload from '@/shared/ui/forms/file-upload/file-upload.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import gql from 'graphql-tag';
const GET_S3_URL = gql `
	query GetS3Url($fileType: String!, $fileName: String!) {
		s3UploadUrl(fileType: $fileType, key: $fileName)
	}
`;
let S3Uploader = class S3Uploader extends Vue {
    value;
    location;
    isVisible;
    accept;
    extractFileName(url) {
        if (!url)
            return '';
        return decodeURIComponent(url.substring(url.lastIndexOf('/') + 1));
    }
    async upload(files) {
        this.$emit('blur');
        if (!files[0])
            return;
        this.$emit('input', {
            file: null,
            status: 'uploading'
        });
        const fileName = `${this.location}/${files[0].name}`;
        const type = files[0].type;
        const uploadUrl = await this.$apollo.getClient().query({
            query: GET_S3_URL,
            fetchPolicy: 'network-only',
            variables: {
                fileName,
                fileType: type
            }
        });
        try {
            const result = await fetch(uploadUrl.data.s3UploadUrl, {
                method: 'PUT',
                body: files[0]
            });
            if (result.status != 200) {
                this.$emit('input', {
                    file: null,
                    status: 'failed'
                });
                return;
            }
        }
        catch (e) {
            this.$emit('input', {
                file: null,
                status: 'failed'
            });
        }
        finally {
            this.$el.querySelector('input[type=file]').value = '';
        }
        this.$emit('input', {
            file: fileName,
            status: 'uploaded'
        });
    }
};
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], S3Uploader.prototype, "value", void 0);
__decorate([
    Prop({ required: true, type: String }),
    __metadata("design:type", Object)
], S3Uploader.prototype, "location", void 0);
__decorate([
    Prop({ required: false, type: Boolean, default: true }),
    __metadata("design:type", Object)
], S3Uploader.prototype, "isVisible", void 0);
__decorate([
    Prop({ required: false, type: String }),
    __metadata("design:type", Object)
], S3Uploader.prototype, "accept", void 0);
S3Uploader = __decorate([
    Component({
        components: {
            FileUpload
        }
    })
], S3Uploader);
export default S3Uploader;
