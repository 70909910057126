import { Department, UserType } from '@/shared/schema/globalTypes';
import graphql from '@/shared/graphql';
import ADD_PLEDGE from './add-pledge.graphql';
import UPDATE_PLEDGE from './update-pledge.graphql';
import MOVE_PLEDGE from './move-pledge.graphql';
import DELETE_PLEDGE from './delete-pledge.graphql';
import PledgeTab from './pledge.vue';
import { acceptProps, compose } from 'vue-compose';
import { withCurrentUser } from '@/shared/authentication';
import GET_HOUSEHOLD_GIVING_HISTORY from '@/pages/household/pages/giving/get-household-giving-history.graphql';
import omit from 'lodash/omit';
import { monthDayYearToDate } from '@/shared/util';
import CHARGE_CARD from '@/shared/components/payment-form/charge-card.graphql';
function pledgeFormToPledge(form, donorId, withTurnaround) {
    return {
        id: form.id,
        currency: form.amount.currency,
        amount: form.amount.amount,
        campaignId: form.campaign && form.campaign.id,
        subCampaignId: form.subcampaign && form.subcampaign.id,
        department: form.department,
        notes: form.description,
        date: monthDayYearToDate(form.date).valueOf(),
        donorId: donorId,
        grantId: form.grant && form.grant.id,
        tributeText: form.tributeText || null,
        goalId: form.goal && form.goal.id,
        fundraisers: form.fundraisers.map(x => ({
            fundraiserID: x.fundraiser.id,
            percent: x.percent
        })),
        ...(form.billingPlan && form.billingPlan.installments
            ? {
                billingPlan: {
                    autoBill: form.billingPlan.autoBill,
                    cardOnFile: form.billingPlan.cardOnFile?.token,
                    installments: form.billingPlan.installments.map(x => ({
                        date: monthDayYearToDate(x.date).valueOf(),
                        amount: x.amount
                    })),
                    interval: form.billingPlan.interval,
                    type: form.billingPlan.distributionType
                }
            }
            : {}),
        calendarYear: form.calendarYear,
        fiscalYear: form.fiscalYear,
        accountingFY: form.accountingFY,
        type: form.type,
        uncollectible: form.uncollectible,
        accountingPledge: form.accountingPledge,
        uncollectibleReason: form.uncollectibleReason,
        conditionalPledge: form.conditionalPledge,
        backupFiles: form.backupFiles
            .filter(file => file.uploadedFile)
            .map(file => ({
            fileUrl: file.uploadedFile.file,
            description: file.description
        })),
        ...(withTurnaround
            ? {
                turnaround: form.hasTurnaround
                    ? {
                        department: form.taDepartment.id,
                        campaignId: form.taCampaign.id,
                        subCampaignId: form.taSubcampaign ? form.taSubcampaign.id : null
                    }
                    : null
            }
            : {}),
        sendInvoiceVia: form.shouldDSPrintAnInvoice ? form.sendInvoiceVia : null,
        shouldDSPrintAnInvoice: form.shouldDSPrintAnInvoice
    };
}
const addPledge = graphql(ADD_PLEDGE, {
    props(props) {
        return {
            processing: props.loading,
            async addPledge(form) {
                const donorId = form.donor?.id;
                if (!donorId)
                    return;
                await props.mutate({
                    variables: {
                        pledge: omit(pledgeFormToPledge(form, donorId, props.currentUser.type === UserType.DataServices), 'uncollectible', 'accountingPledge', 'uncollectibleReason', 'conditionalPledge')
                    }
                });
            }
        };
    }
});
const updatePledge = graphql(UPDATE_PLEDGE, {
    props(props) {
        return {
            processing: props.processing || props.loading,
            async updatePledge(form) {
                if (!props.donor)
                    return;
                return props.mutate({
                    variables: {
                        pledge: pledgeFormToPledge(form, props.donor.id, props.currentUser.type === UserType.DataServices)
                    },
                    refetchQueries: ['GetCampaignPledges', 'GetPledgeAudit'],
                    update(_client) { }
                });
            }
        };
    }
});
const movePledge = graphql(MOVE_PLEDGE, {
    props(props) {
        return {
            processing: props.loading || props.processing,
            async movePledge(pledgeId, donorId, withPayments) {
                return props.mutate({
                    update(client) {
                        if (props.donor) {
                            const donorGivings = client.readQuery({
                                query: GET_HOUSEHOLD_GIVING_HISTORY,
                                variables: {
                                    id: props.donor.id
                                }
                            });
                            if (donorGivings &&
                                donorGivings.householdData &&
                                donorGivings.householdData.pledges &&
                                props.pledge) {
                                donorGivings.householdData.pledges = donorGivings.householdData.pledges.filter(p => p.id !== props.pledge.id);
                                client.writeQuery({
                                    query: GET_HOUSEHOLD_GIVING_HISTORY,
                                    variables: {
                                        id: props.donor.id
                                    },
                                    data: donorGivings
                                });
                            }
                        }
                    },
                    variables: {
                        pledgeId,
                        donorId,
                        withPayments
                    }
                });
            }
        };
    }
});
const deletePledge = graphql(DELETE_PLEDGE, {
    props(props) {
        return {
            processing: props.loading || props.processing,
            async deletePledge(pledgeId) {
                return props.mutate({
                    update(client) {
                        if (props.donor) {
                            const donorGivings = client.readQuery({
                                query: GET_HOUSEHOLD_GIVING_HISTORY,
                                variables: {
                                    id: props.donor.id
                                }
                            });
                            if (donorGivings &&
                                donorGivings.householdData &&
                                donorGivings.householdData.pledges &&
                                props.pledge) {
                                donorGivings.householdData.pledges = donorGivings.householdData.pledges.filter(p => p.id !== props.pledge.id);
                                client.writeQuery({
                                    query: GET_HOUSEHOLD_GIVING_HISTORY,
                                    variables: {
                                        id: props.donor.id
                                    },
                                    data: donorGivings
                                });
                            }
                        }
                    },
                    variables: {
                        pledgeId
                    }
                });
            }
        };
    }
});
const ncsyGateways = {
    NCSY_Arizona: 'USAePay-NcsyWestcoast',
    NCSY_AtlanticSeaboard: 'USAePay-NcsyAtlanticseaboard',
    NCSY_CentralEast: 'USAePay-NcsyCentraleast',
    NCSY_Cleveland: 'USAePay-NcsyCentraleast',
    [Department.NCSY_LACity]: 'USAePay-NcsyWestcoast',
    [Department.NCSY_LAValley]: 'USAePay-NcsyWestcoast',
    NCSY_Midwest: 'USAePay-NcsyMidwest',
    NCSY_NewEngland: 'USAePay-NcsyUpstate',
    NCSY_NewJersey: 'USAePay-NcsyJersey',
    NCSY_NewYork: 'USAePay-NcsyNewyork',
    NCSY_Norcal: 'USAePay-NcsyWestcoast',
    NCSY_Portland: 'USAePay-NcsyWestcoast',
    NCSY_Southern: 'USAePay-Southern',
    NCSY_Southwest: 'USAePay-Southewest',
    NCSY_UpstateNewYork: 'USAePay-Upstate',
    NCSY_WestCoast: 'USAePay-Westcoast'
};
const chargeCard = graphql(CHARGE_CARD, {
    props(props) {
        return {
            processing: props.loading,
            async chargeCard(form) {
                const donorId = form.donor?.id;
                const installment = form.billingPlan?.installments && form.billingPlan?.installments[0];
                if (!donorId || !installment || !form.department || installment.paid)
                    return;
                const applications = [
                    {
                        pledgeId: form.id,
                        amount: installment.amount,
                        department: form.department
                    }
                ];
                const gateway = ncsyGateways[form.department] || 'USAePay-Velocity';
                await props.mutate({
                    variables: {
                        input: {
                            amount: installment.amount,
                            pledgeId: form.id,
                            campaign: form.campaign?.label,
                            subCampaign: form.subcampaign?.label,
                            department: form.department,
                            description: null,
                            token: form.billingPlan.cardOnFile.token,
                            test_mode: JSON.parse(process.env.VUE_APP_PAYMENT_TEST_MODE),
                            gateway,
                            AccountID: donorId,
                            applications
                        }
                    },
                    refetchQueries: ['LoadPledge']
                });
            }
        };
    }
});
export default compose(withCurrentUser, addPledge, updatePledge, movePledge, deletePledge, chargeCard, acceptProps(['donorId']))(PledgeTab);
