<template>
	<textarea v-bind="props" v-on="listeners" :class="classes"></textarea>
</template>
<script>
export default {
	name: 'UITextarea',
	props: ['size', 'value'],
	computed: {
		classes() {
			const classes = {}

			if (this.size) {
				classes[`size-${this.size}`] = true
			}

			return classes
		},
		props() {
			return {
				...this.$attrs,
				...this.$props
			}
		},
		listeners() {
			return {
				...this.$listeners,
				input: e => this.$emit('input', e.target.value),
				change: e => this.$emit('change', e.target.value)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
textarea {
	box-sizing: border-box;
	min-height: 40px;
	width: 100%;
	display: block;
	border: 1px solid var(--input-border);
	padding: 8px 10px;
	border-radius: 4px;
	background: var(--input-bg);
	transition: all 0.3s ease-in-out;
	color: #222;
	font-size: var(--main-font-size);
	line-height: 1.4;
	font-family: var(--font-main);
	&:hover {
		box-shadow: none;
		border-color: var(--main-blue);
	}

	&:focus {
		border-color: var(--main-blue);
		box-shadow: none;
		color: #222;
		background: white;
		box-shadow: 0 0 3px rgba(56, 93, 163, 0.5);
	}
	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
		border: 1px solid #e4e4e4;
		background: #f3f2f2;
	}
	&.white-bg {
		background: white;
	}
}

.size-small {
	height: 40px;
}

.size-default {
	height: 60px;
}

.size-large {
	height: 90px;
}

textarea::-webkit-input-placeholder {
	color: #ccc;
}
textarea::-moz-placeholder {
	color: #ccc;
}
textarea:-ms-input-placeholder {
	color: #ccc;
}
</style>
