import UISelect from './ui/forms/select/ui-select.vue';
import { startCase } from 'lodash';
import Vue from 'vue';
export function enumSelector(Enum, blankValue, omitKeys = [], formatter = startCase) {
    const types = Object.entries(Enum)
        .filter(([key]) => !omitKeys.includes(key))
        .map(([key]) => ({
        value: key,
        label: formatter(key)
    }));
    if (blankValue) {
        types.unshift({
            value: null,
            label: blankValue
        });
    }
    return Vue.extend({
        name: 'EnumSelector',
        props: ['value'],
        render(h) {
            return h(UISelect, {
                props: {
                    value: this.value
                },
                on: this.$listeners
            }, types.map(x => h('option', {
                domProps: {
                    value: x.value
                }
            }, [x.label])));
        }
    });
}
