import { __decorate, __metadata } from "tslib";
import { Prop, Vue, Component } from 'vue-property-decorator';
import UserLookup from '@/shared/components/user-lookup';
import { FormBuilder } from '@/shared/form-builder';
import LayoutDivider from '@/shared/layout/divider/layout-divider.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
const formDescription = {
    data: [
        {
            inlineLabel: false,
            rows: {
                type: 'repeatable',
                field: 'fundraisers',
                addLabel: '+ Add Fundraiser',
                default: {
                    user: {
                        id: '',
                        label: ''
                    },
                    percentage: '100'
                },
                row: [
                    {
                        type: 'single',
                        field: 'user',
                        label: 'Fundraiser',
                        component: [UserLookup, () => ({ props: { filterActive: true } })]
                    },
                    {
                        type: 'single',
                        field: 'percentage',
                        label: 'Percentage'
                    }
                ]
            }
        }
    ]
};
let Fundraisers = class Fundraisers extends Vue {
    showSubmitButton;
    buttonText;
    formDescription = formDescription;
    form = { fundraisers: [{ user: { id: '', label: '' }, percentage: '100' }] };
    validate(fields) {
        const errors = {};
        const allUsersIds = fields.fundraisers.map(fundraiser => fundraiser.user.id);
        const fundraiserErrors = fields.fundraisers.map(fundraiser => {
            const currentUserId = fundraiser.user.id;
            const otherUsers = allUsersIds.filter(id => id !== currentUserId);
            let userError = fundraiser.user.id === '' ? 'User is required' : null;
            if (allUsersIds.length - otherUsers.length > 1) {
                userError = 'This fundraiser cannot be assigned twice';
            }
            const percentage = parseInt(fundraiser.percentage, 10);
            let percentageError = null;
            if (Number.isNaN(percentage)) {
                percentageError = 'Number is invalid';
            }
            else if (percentage > 100) {
                percentageError = 'Should not be higher than 100';
            }
            else if (percentage < 0) {
                percentageError = 'Should be positive number';
            }
            const errors = {};
            if (userError) {
                errors.user = userError;
            }
            if (percentageError) {
                errors.percentage = percentageError;
            }
            return errors;
        });
        const areFundraiserErrorsPresent = fundraiserErrors.find(fundraiser => Object.keys(fundraiser).length > 0);
        if (areFundraiserErrorsPresent) {
            errors.fundraisers = fundraiserErrors;
        }
        return errors;
    }
    onChange(fields) {
        if (!fields.values)
            return;
        const errors = this.validate(fields.values);
        if (Object.keys(errors).length > 0) {
            return;
        }
        this.$emit('values', fields);
    }
};
__decorate([
    Prop({ required: false, type: Boolean, default: false }),
    __metadata("design:type", Object)
], Fundraisers.prototype, "showSubmitButton", void 0);
__decorate([
    Prop({ required: false, type: String, default: 'Save' }),
    __metadata("design:type", Object)
], Fundraisers.prototype, "buttonText", void 0);
Fundraisers = __decorate([
    Component({
        components: {
            UserLookup,
            FormBuilder,
            UIButton,
            LayoutDivider
        }
    })
], Fundraisers);
export default Fundraisers;
