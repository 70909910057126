import Vue from 'vue';
import { formatDate, getField } from '@/shared/util';
const DateCell = Vue.extend({
    props: {
        field: String,
        dataItem: Object
    },
    methods: {
        formatDatetime() {
            const date = getField(this.dataItem, this.field);
            if (!date)
                return '';
            return formatDate(date);
        }
    }
});
export default DateCell;
