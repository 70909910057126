<template>
	<thead>
		<slot></slot>
	</thead>
</template>

<script>
export default {
	name: 'TableHead',
	props: ['label']
}
</script>

<style scoped></style>
