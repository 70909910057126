var _a;
import { __decorate, __metadata } from "tslib";
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import FancySelect from '../ui/forms/fancy-select';
import CAMPAIGN from '@/shared/components/campaign-lookup/campaign.fragment.graphql';
import castArray from 'lodash/castArray';
import { compact } from 'lodash';
let SubCampaignLookup = class SubCampaignLookup extends Vue {
    value;
    campaign;
    multiple;
    resolvedCampaign = null;
    async handleCampaignChange() {
        const resolveCampaign = async () => {
            const client = this.$apollo.getClient();
            const campaigns = await Promise.all(castArray(this.campaign).map(async (campaign) => {
                try {
                    const c = client.readFragment({
                        fragment: CAMPAIGN,
                        id: `Campaign:${campaign}`,
                        fragmentName: 'CampaignWithSubcampaigns'
                    });
                    return c;
                }
                catch (error) {
                    // We might fail here if another query also returned a campaign, but not in our expected shape. So in that case return null to try again.
                    return null;
                }
            }));
            if (compact(campaigns).length != castArray(this.campaign).length) {
                // Probably the campaign hasn't loaded yet in memory
                await new Promise(res => {
                    requestAnimationFrame(res);
                });
                return resolveCampaign();
            }
            return compact(campaigns);
        };
        this.resolvedCampaign = this.campaign ? await resolveCampaign() : null;
    }
    async handleValue() {
        const value = this.value;
        if (typeof value !== 'string')
            return;
        const resolveValueFromString = () => {
            if (!this.resolvedCampaign) {
                // Maybe not loaded in memory yet
                setImmediate(() => {
                    resolveValueFromString();
                });
                return;
            }
            const subCampaign = this.resolvedCampaign
                ? this.resolvedCampaign[0].subCampaigns.find(x => x.name === value || x.id === value)
                : null;
            if (subCampaign)
                this.$emit('input', { id: subCampaign.id, label: subCampaign.name });
        };
        resolveValueFromString();
    }
    render(h) {
        const value = typeof this.value === 'string' ? null : this.value;
        const subCampaigns = this.resolvedCampaign
            ? this.resolvedCampaign
                .flatMap(x => x.subCampaigns)
                .filter(x => x.active)
                .map(x => ({
                id: x.id,
                label: x.name
            }))
            : [];
        return h(FancySelect, {
            on: this.$listeners,
            props: {
                value,
                placeholder: 'Select Subcampaign',
                options: subCampaigns,
                multiple: this.multiple
            },
            attrs: {
                disabled: subCampaigns.length === 0,
                title: this.campaign === null || this.campaign.length === 0 ? 'Select campaign first' : null
            }
        });
    }
};
__decorate([
    Prop({ type: [Object, String, Array] }),
    __metadata("design:type", Object)
], SubCampaignLookup.prototype, "value", void 0);
__decorate([
    Prop({ type: [String, Array] }),
    __metadata("design:type", Object)
], SubCampaignLookup.prototype, "campaign", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], SubCampaignLookup.prototype, "multiple", void 0);
__decorate([
    Watch('campaign', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SubCampaignLookup.prototype, "handleCampaignChange", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SubCampaignLookup.prototype, "handleValue", null);
SubCampaignLookup = __decorate([
    Component
], SubCampaignLookup);
export default SubCampaignLookup;
